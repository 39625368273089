import { NavLink, useParams } from "react-router-dom";
import Websitetemp1 from "../assests/websitetemp1.jpg";
import { BiShareAlt } from "react-icons/bi";
import { MdOutlineClose } from "react-icons/md";

// mui
import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import WhatsappIcon from "../../../img/wh.svg";
import FaceboonIcon from "../../../img/fa.svg";
import TwitterIcon from "../../../img/tw.svg";
import GmailIcon from "../../../img/gm.svg";
import CopyIcon from "../../../img/copy.svg";
import axios from "axios";
import ENDPOINT from "../config/ENDPOINT";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HelmetMetaData from "./components/core/HelmetMetaData/HelmetMetaData.js";
import CLIENT_URL from "../config/CLIENT_URL";
import { EmailShareButton, FacebookShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";

export const DesignInspirationDetail = () => {
  const CardData = [
    {
      imgLink: "/",
      img: Websitetemp1,
    },
    {
      imgLink: "/",
      img: "https://wewakedev.github.io/Maxlence-Official-Website/assests/websitetemp2.jpg",
    },
    {
      imgLink: "/",
      img: "https://wewakedev.github.io/Maxlence-Official-Website/assests/websitetemp3.jpg",
    },
  ];
  const [designDetail, setDesignDetail] = useState([]);
  const { slug } = useParams();
  const ImgLink = CLIENT_URL + "resources/design-inspiration";

  const fetchDesignInspirationByCat = async () => {
    const { data } = await axios.get(
      ENDPOINT + `design_inspiration/get_by_slug/${slug}`
    );
    setDesignDetail(data);
  };

  useEffect(() => {
    fetchDesignInspirationByCat();
  }, []);

  const newSlug = [];
  let str = slug.replace(/_|-/g, " ");
  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  newSlug.push((str = arr.join(" ")));

  const CardApp = (props) => {
    const [openShare, setOpenShare] = React.useState(false);
    const [openImgView, setOpenImgView] = React.useState(false);

    const handleOpenShare = () => setOpenShare(true);
    const handleCloseShare = () => setOpenShare(false);
    const handleOpenImgView = () => setOpenImgView(true);
    const handleCloseImgView = () => setOpenImgView(false);

    const TransitionsModal = () => {
      const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "none",
        boxShadow: 24,
        p: 4,
      };
      const ShareData = [
        {
          link: "https://www.google.com/",
          icon: WhatsappIcon,
          title: "Whatsapp",
        },
        {
          link: "https://www.google.com/",
          icon: FaceboonIcon,
          title: "Faceboon",
        },
        {
          link: "https://www.google.com/",
          icon: TwitterIcon,
          title: "Twitter",
        },
        {
          link: "https://www.google.com/",
          icon: GmailIcon,
          title: "Gmail",
        },
      ];
      return (
        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openShare}
            onClose={handleCloseShare}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={openShare}>
              <Box sx={style} className="design_inspiration_modal_div">
                <div>
                  <h3>Share</h3>
                  <div className="row g-1">
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                      <div>
                        <WhatsappShareButton
                          url={"https://tecnogex.maxlence.com.au/"}
                          title={"Tecnogex - An Australian company"}
                          separator=":: "
                        >
                          <div className="design_inspiration_share_div_inner_top">
                            <div className="design_inspiration_share_div_inner">
                              <img src={WhatsappIcon} alt="icon" />
                            </div>
                          </div>
                          <p className="design_inspiration_share_div_inner_title">
                            WhatsApp
                          </p>
                        </WhatsappShareButton>
                      </div>
                    </div>

                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                      <div>
                        <FacebookShareButton
                          url={"https://tecnogex.maxlence.com.au/"}
                          quote={"Tecnogex - An Australian company"}
                          hashtag="#tecnogex"
                        >
                          <div className="design_inspiration_share_div_inner_top">
                            <div className="design_inspiration_share_div_inner">
                              <img src={FaceboonIcon} alt="icon" />
                            </div>
                          </div>
                          <p className="design_inspiration_share_div_inner_title">
                            Facebook
                          </p>
                        </FacebookShareButton>
                      </div>
                    </div>

                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                      <div>
                        <TwitterShareButton
                          url={"https://tecnogex.maxlence.com.au/"}
                          title={"Tecnogex - An Australian company"}
                          hashtag="#tecnogex"
                        >
                          <div className="design_inspiration_share_div_inner_top">
                            <div className="design_inspiration_share_div_inner">
                              <img src={TwitterIcon} alt="icon" />
                            </div>
                          </div>
                          <p className="design_inspiration_share_div_inner_title">
                            Twitter
                          </p>
                        </TwitterShareButton>
                      </div>
                    </div>

                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                      <div>
                        <EmailShareButton
                          url={"https://tecnogex.maxlence.com.au/"}
                          subject={"Tecnogex - An Australian company"}
                          separator=":: "
                        >
                          <div className="design_inspiration_share_div_inner_top">
                            <div className="design_inspiration_share_div_inner">
                              <img src={GmailIcon} alt="icon" />
                            </div>
                          </div>
                          <p className="design_inspiration_share_div_inner_title">
                            Gmail
                          </p>
                        </EmailShareButton>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="design_inspiration_modal_copy">
                        {ImgLink}
                        <span
                          onClick={() => {
                            navigator.clipboard.writeText(ImgLink);
                            toast.success("URL copied!", {
                              position: "bottom-right",
                              autoClose: 1000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "colored",
                            });
                          }}
                        >
                          <img src={CopyIcon} alt="icon" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </Fade>
          </Modal>
        </div>
      );
    };
    const ImgViewModal = () => {
      const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        // width: 400,
        bgcolor: "background.paper",
        border: "none",
        boxShadow: 24,
        p: 4,
      };
      return (
        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openImgView}
            onClose={handleCloseImgView}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={openImgView}>
              <Box sx={style} className="img_view_modal_div">
                <div className="img_view_modal_inner_div">
                  <img src={ENDPOINT + "uploads/" + props.img} alt="sd" />
                </div>
                <span
                  onClick={handleCloseImgView}
                  className="close_img_view_btn_div"
                >
                  <MdOutlineClose />
                </span>
                <div className="delete_modal_btn_div">
                  <ul>
                    <li className="btn_one">
                      <button
                        onClick={handleCloseImgView}
                        className="outline_btn btn"
                      >
                        Cancel
                      </button>
                    </li>
                    {props.url ? (
                      <li className="btn_two">
                        <a
                          href={props.url}
                          className="btn full_btn ms-1"
                          target="_blank"
                        >
                          Website link
                        </a>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </Box>
            </Fade>
          </Modal>
        </div>
      );
    };
    return (
      <>
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
          <TransitionsModal />
          <ImgViewModal />
          <div className="design_inspiration_div">
            <div style={{ cursor: "pointer" }} onClick={handleOpenImgView}>
              <div
                className="design_inspiration_img"
                style={{
                  backgroundImage: `url(${ENDPOINT + "uploads/" + props.img})`,
                }}
              ></div>
            </div>
            <BiShareAlt onClick={handleOpenShare} />
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <div className="container designinsp_title text-center border-bottom py-3 my-3">
        {newSlug.map((slug) => (
          <h1>{slug}</h1>
        ))}
      </div>
      <div className="container websitetemp my-5">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className="row g-4">
          {designDetail.map((val, i) => {
            return <CardApp key={i} {...val} />;
          })}
        </div>
      </div>
    </>
  );
};
// export const Luno = () => {
//   <HelmetMetaData
//     title={"Luno"}
//     description={"Test description"}
//     image={"http://localhost:5000/uploads/img-1682322307311.jpg"}
//   ></HelmetMetaData>;
//   return <h1>Luno Page</h1>;
// };
