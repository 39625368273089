import React from "react";
import { NavLink } from "react-router-dom";
import { Container } from "react-bootstrap";
import Fblighticon from "../assests/fblighticon.png";
import Instalighticon from "../assests/instalighticon.png";
import Twitterlighticon from "../assests/twitterlighticon.png";
import FooterLogo from "../../../img/FooterLogo.svg";
export const Footer = () => {
  return (
    <>
      <Container fluid className="greengradient pb-0" id="footer">
        <Container>
          <div className="row g-3">
            <div className="col-xxl-3 col-xxl-3 col-lg-3 col-md-4 col-sm-12 col-12">
              <ul className="footercol">
                <p>Let Us Help You</p>
                <li>
                  <NavLink to="/your-account">Accounts</NavLink>
                </li>
                <li>
                  <NavLink to="/terms-&-conditions">Terms & Conditions</NavLink>
                </li>
                <li>
                  <NavLink to="/support">Support</NavLink>
                </li>
                <li>
                  <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                </li>
                <li>
                  <NavLink to="/refund-policy">Refund Policy</NavLink>
                </li>
                <li>
                  <NavLink to="/get-in-touch">Get In Touch</NavLink>
                </li>
              </ul>
            </div>
            <div className="col-xxl-3 col-xxl-3 col-lg-3 col-md-4 col-sm-12 col-12">
              <ul className="footercol">
                <p>Quick Links</p>
                <li>
                  <NavLink to="/creation">Creation</NavLink>
                </li>
                <li>
                  <NavLink to="/growth">Growth</NavLink>
                </li>
                <li>
                  <NavLink to="/shop">Shop</NavLink>
                </li>
                <li>
                  <NavLink to="/support">Support</NavLink>
                </li>
                <li>
                  <NavLink to="/agreement">Agreement</NavLink>
                </li>
              </ul>
            </div>
            <div className="col-xxl-3 col-xxl-3 col-lg-3 col-md-4 col-sm-12 col-12">
              <ul className="footercol">
                <p>Get To Know Us</p>
                <li>
                  <NavLink to="/about-us">About Us</NavLink>
                </li>
                <li>
                  <NavLink to="/testimonials">Testimonial</NavLink>
                </li>
                <li>
                  <NavLink to="/careers">Careers</NavLink>
                </li>
              </ul>
            </div>
            <div className="col-xxl-3 col-xxl-3 col-lg-3 col-md-6 col-sm-12 col-12 ">
              <div className="footlogo mb-3">
                <NavLink to="/">
                  <img src={FooterLogo} alt="" />
                </NavLink>
              </div>
              <div className="footabout fontrale font500">
                <p>
                  Tecnogex is an Australian-based company offering IT software
                  solutions and digital marketing services. From custom
                  development to web design, SEO, SMM, we provide tailored
                  solutions to meet unique client needs.
                </p>
              </div>
              <div className="footicons  d-flex">
                <div className="fb text-center footicons_my">
                  <a
                    href="https://www.facebook.com/people/TecnoGex/100091787680738/"
                    target="_blank"
                  >
                    <img src={Fblighticon} alt="f" />
                  </a>
                </div>
                <div className="curr text-center footicons_my">
                  <a
                    href="https://www.instagram.com/tecnogexofficial/?igshid=YmMyMTA2M2Y%3D"
                    target="_blank"
                  >
                    <img src={Instalighticon} alt="f" />
                  </a>
                </div>
                <div className="curr text-center footicons_my">
                  <a href="https://twitter.com/tecnogex_" target="_blank">
                    <img src={Twitterlighticon} alt="f" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <div className="col-12">
          <div className="footer_bottom_text">
            <hr />
            <p>Copyright © 2024. All rights reserved</p>
          </div>
        </div>
      </Container>
    </>
  );
};
