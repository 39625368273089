import { useState, useEffect, useRef } from "react";
import ENDPOINT from "../config/ENDPOINT";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const ShopForm = () => {
  const [values, setValues] = useState({
    city: "",
    area: "",
    age: "",
    occupation: "",
    income: "",
    others: "",
    project_name: "",
    project_brief: "",
  });
  const [file, setFile] = useState(null);
  const inputElement = useRef(null);
  const [industry, setIndustry] = useState([]);
  const [inputDisabled, setInputDisabled] = useState(true);
  const {
    city,
    area,
    age,
    occupation,
    income,
    others,
    project_name,
    project_brief,
  } = values;
  const overallPlanId = useSelector((state) => state.state.overallPlanId);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const City = [
    { value: "Delhi", label: "Delhi" },
    { value: "Gurgaon", label: "Gurgaon" },
  ];
  const Area = [
    { value: "Delhi/NCR", label: "Delhi/NCR" },
    { value: "Gurgaon", label: "Gurgaon" },
  ];
  const Age = [
    { value: "18-24", label: "18-24" },
    { value: "25-35", label: "25-35" },
  ];
  const Occupation = [
    { value: "Designer", label: "Designer" },
    { value: "Designer2", label: "Designer2" },
  ];
  const Income = [
    { value: "1Lakh", label: "1Lakh" },
    { value: "2Lakh", label: "2Lakh" },
  ];
  const Others = [
    { value: "Delhi/NCR", label: "Delhi/NCR" },
    { value: "Gurugram", label: "Gurugram" },
  ];

  const UploadDocument = () => {
    return (
      <>
        <input
          ref={inputElement}
          onChange={(e) => setFile(e.target.files[0])}
          type="file"
          name="img"
          style={{ display: "none" }}
        />
        <div className={inputDisabled ? "getintouch_upload_div" : "d-none"}>
          <div
            onClick={() => inputElement.current?.click()}
            className="getintouch_upload_btn"
          >
            Attach Your Document
          </div>
          <p>File size not more than 2 MB</p>
        </div>
      </>
    );
  };

  const handleChange = ({ target }) => {
    if (target.checked) {
      setIndustry((old) => [...industry, target.value]);
    } else setIndustry(industry.filter((i) => i !== target.value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newVal = {
      city,
      area,
      age,
      occupation,
      income,
      others,
      project_name,
      project_brief,
      industry,
    };
    const data = new FormData();
    data.append("img", file);
    Object.keys(newVal).forEach((key) => data.append(key, newVal[key]));

    try {
      axios.post(ENDPOINT + "productenquiry", data).then((res) => {
        if (res.status === 200) {
          try {
            axios({
              url: ENDPOINT + "cart",
              method: "POST",
              headers: { "x-access-token": token },
              data: { overall_plan_id: overallPlanId },
            }).then((res) => {
              if (res.status === 200) {
                navigate("/cart");
              } else if (res.status === 400) {
                console.log("Error while adding product to cart!");
              }
            });
          } catch (err) {
            console.log(err);
          }
        }
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Server error!",
      });
    }
  };

  const CheckboxData = [
    { value: "Food", label: "Food" },
    { value: "Entertainment", label: "Entertainment" },
    { value: "Real Estate", label: "Real Estate" },
    { value: "Events", label: "Events" },
    { value: "Fitness & Health", label: "Fitness & Health" },
    { value: "Electronics", label: "Electronics" },
  ];

  return (
    <>
      <div
        className="container-fluid py-4 questionform"
        style={{ background: "#e7f3ff" }}
      >
        <form onSubmit={handleSubmit} method="post">
          <div className="container py-4">
            <div className="row justify-content-around">
              <div className="col-2">
                <div className="numbullets">
                  <small>1</small>
                </div>
              </div>
              <div className="col-9 px-0">
                <div className="questtitle">
                  <h3>INDUSTRY</h3>
                </div>
                <div className="row my-4 flex-sm-row flex-column justify-content-around align-items-center">
                  {CheckboxData.map((checkbox, key) => (
                    <div key={key} className="col-sm-6 col-12 my-2">
                      <input
                        required={industry.length < 1}
                        type="checkbox"
                        value={checkbox.value}
                        name="industry"
                        onChange={handleChange}
                      />
                      <label htmlFor="industry" className=" ms-1">
                        {checkbox.label}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="row justify-content-around">
              <div className="col-2">
                <div className="numbullets">
                  <small>2</small>
                </div>
              </div>
              <div className="col-9 px-0">
                <div className="questtitle">
                  <h3>LOCATION</h3>
                </div>
                <div className="row my-4 justify-content-around align-items-center">
                  <div className="col-sm-6 col-12 my-2">
                    <div className="location_dropdown">
                      <Select
                        // menuIsOpen={true}
                        required
                        options={City}
                        placeholder="City"
                        name="city"
                        onChange={(e) =>
                          setValues({ ...values, city: e.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12 my-2">
                    <div className="location_dropdown">
                      <Select
                        // menuIsOpen={true}
                        required
                        options={Area}
                        placeholder="Area"
                        name="others"
                        onChange={(e) =>
                          setValues({ ...values, area: e.value })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-around">
              <div className="col-2">
                <div className="numbullets">
                  <small>3</small>
                </div>
              </div>
              <div className="col-9 px-0">
                <div className="questtitle">
                  <h3>Target Audience</h3>
                </div>
                <div className="row my-4 justify-content-around align-items-center">
                  <div className="col-sm-6 col-12 my-2">
                    <div className="location_dropdown">
                      <Select
                        // menuIsOpen={true}
                        required
                        options={Age}
                        placeholder="Age"
                        name="age"
                        onChange={(e) => setValues({ ...values, age: e.value })}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12 my-2">
                    <div className="location_dropdown">
                      <Select
                        // menuIsOpen={true}
                        required
                        options={Occupation}
                        placeholder="Occupation"
                        name="occupation"
                        onChange={(e) =>
                          setValues({ ...values, occupation: e.value })
                        }
                      />
                    </div>
                  </div>

                  <div className="col-sm-6 col-12 my-2">
                    <div className="location_dropdown">
                      <Select
                        // menuIsOpen={true}
                        required
                        options={Income}
                        placeholder="Income"
                        name="income"
                        onChange={(e) =>
                          setValues({ ...values, income: e.value })
                        }
                      />
                    </div>
                  </div>

                  <div className="col-sm-6 col-12 my-2">
                    <div className="location_dropdown">
                      <Select
                        // menuIsOpen={true}
                        options={Others}
                        placeholder="Others"
                        name="others"
                        onChange={(e) =>
                          setValues({ ...values, others: e.value })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-around">
              <div className="col-2">
                <div className="numbullets">
                  <small>4</small>
                </div>
              </div>
              <div className="col-9 px-0">
                <div className="questtitle">
                  <h3>PROJECT DETAILS</h3>
                </div>
                <div className="row my-4 justify-content-around align-items-center">
                  <div className="col-sm-12 col-12 my-2">
                    <div className="form-group">
                      <div className="t-dropdown-block">
                        <div className="t-dropdown-select">
                          <input
                            required
                            type="text"
                            className="t-dropdown-input"
                            placeholder="Project Name (This name will be referenced in your invoice.)"
                            name="project_name"
                            value={values.project_name}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="questtitle">
                  <h3>PROJECT BRIEF</h3>
                </div>
                <div className="row my-4 justify-content-around align-items-center">
                  <div className="col-sm-12 col-12 my-2">
                    <div className="form-group">
                      <textarea
                        id="text-area"
                        style={{ padding: "2rem" }}
                        className="t-dropdown-input"
                        placeholder="Kindly share detailed information regarding standards of this Logo attach files in pDF/PNG/JPEG/ZIP format."
                        rows="5"
                        form="usrform"
                        name="project_brief"
                        value={values.project_brief}
                        onChange={handleInputChange}
                        // disabled={inputDisabled}
                      ></textarea>

                      <div className="my-3">
                        <input
                          type="checkbox"
                          onClick={() => setInputDisabled(!inputDisabled)}
                          id="cbox"
                        />{" "}
                        <label htmlFor="cbox">
                          I don’t want to submit any file.
                        </label>
                      </div>
                      <div>
                        <UploadDocument />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-12">
              <div className="getstarteds text-center wow animate__animated animate__fadeInUp">
                <button type="submit" className="whitebgbtn fontrale">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
