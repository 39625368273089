import Slider from "react-slick";
import React, { useEffect, useState } from "react";
import youtubecamp from "../assests/youtubecamp.jpg";
import pageseo from "../assests/pageseo.jpg";
import socialmediamarketing from "../assests/socialmediamarketing.jpg";
import emailmarketing from "../assests/emailmarketing.jpg";
import DeleteIcon from "../../../img/del.svg";
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { BsFillInfoCircleFill } from "react-icons/bs";
import ENDPOINT from "../config/ENDPOINT";
import { Box, Fade, Modal } from "@mui/material";
import { EmptyUi } from "../components/ThankYou";
import cart from "../../../img/cart.svg";
import { useSelector } from "react-redux";

export const Cart = () => {
  const [cartData, setCartData] = useState([]);
  const [total, setTotal] = useState(0);
  const [currency, setCurrency] = useState(null);
  const token = localStorage.getItem("token");
  const [openPopup, setOpenPopup] = useState(false);
  const [dltId, setDltId] = useState(0);
  const [gst, setGst] = useState(0);
  const [gstAmount, setGstAmount] = useState(0);
  const geoInfo = useSelector((state) => state.state.geoInfo);

  const getCartdata = async () => {
    const { data } = await axios.get(
      ENDPOINT + `cart/user_cart?country=${geoInfo?.country_code}`,
      {
        headers: { "x-access-token": token },
      }
    );
    setCartData(data.products);
    setGst(data.gst);

    let total = data.products?.reduce(
      (acc, curr) => acc + Number(curr.final_price),
      0
    );
    setTotal(total);
    setGstAmount(total + (total * data.gst) / 100);
  };
  useEffect(() => {
    cartData.forEach((item) =>
      setCurrency(
        item.plan_price?.itf_country?.currency ||
          item.shop_plan_price?.itf_country?.currency
      )
    );
  }, [cartData]);

  useEffect(() => {
    if (geoInfo?.country_code) getCartdata();
  }, [geoInfo, total]);

  const CartList = () => {
    const handleCardDelete = async () => {
      try {
        let isItemDlted = axios.delete(ENDPOINT + `cart/${dltId}`, {
          headers: { "x-access-token": token },
        });
        if (isItemDlted) {
          toast.success("Cart item has been deleted!", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        getCartdata();
        setOpenPopup(false);
      } catch (err) {
        toast.error("Server error!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log(err);
      }
    };

    // delete
    const DeleteModal = () => {
      const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
      };
      return (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openPopup}
          onClose={() => setOpenPopup(false)}
          closeAfterTransition
        >
          <Fade in={openPopup}>
            <Box sx={style} className="delete_modal_my">
              <div className="delete_modal">
                <ul>
                  <li>
                    <BsFillInfoCircleFill />
                  </li>
                  <li>
                    <h3>Delete ?</h3>
                    <p>Are you sure you want to delete?</p>
                  </li>
                </ul>
                <div className="delete_modal_btn_div">
                  <ul>
                    <li className="btn_one">
                      <button
                        onClick={() => setOpenPopup(false)}
                        className="outline_btn btn"
                      >
                        No
                      </button>
                    </li>
                    <li className="btn_two">
                      <button
                        onClick={handleCardDelete}
                        className="btn full_btn ms-1"
                      >
                        Yes
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      );
    };
    // delete end

    const dummyImage =
      "https://wewakedev.github.io/Maxlence-Official-Website/assests/pageseo.jpg";

    const ListApp = (props) => {
      return (
        <>
          <DeleteModal />
          <tr>
            <td>
              <div>
                <ul>
                  <li>
                    <img
                      className="list_icon_cart"
                      src={
                        props.plan_price?.package_plan?.package
                          ?.question_cat_master?.img
                          ? ENDPOINT +
                            "uploads/" +
                            props.plan_price?.package_plan?.package
                              ?.question_cat_master?.img
                          : props.shop_plan_price?.shop_package_plan
                              ?.shop_package?.product?.category_master?.img
                          ? ENDPOINT +
                            "uploads/" +
                            props.shop_plan_price?.shop_package_plan
                              ?.shop_package?.product?.category_master?.img
                          : dummyImage
                      }
                      alt="icon"
                    />
                  </li>
                  <li className="cart_title_div">
                    {props.plan_price?.package_plan?.package
                      ?.question_cat_master?.name ||
                      props.shop_plan_price?.shop_package_plan?.shop_package
                        ?.product?.category_master?.name}
                  </li>
                </ul>
              </div>
            </td>
            <td className="cart_title_div_td cart_title_div_Type">
              {props.plan_price?.package_plan?.package?.title ||
                props.shop_plan_price?.shop_package_plan?.shop_package?.title}
            </td>
            <td className="cart_title_div_td cart_title_div_monthly">
              {props.plan_price?.package_plan?.title ||
                props.shop_plan_price?.shop_package_plan?.title}
            </td>
            <td className="cart_title_div_td cart_title_div_price">
              {props.plan_price?.itf_country?.currency ||
                props.shop_plan_price?.itf_country?.currency}
              {props.final_price}
            </td>
            <td className="cart_title_div_td cart_title_div_delete_icon">
              <img
                src={DeleteIcon}
                alt="delete icon"
                onClick={() => {
                  setOpenPopup(true);
                  setDltId(props.id);
                }}
              />
            </td>
          </tr>
          <div className="td_body_cart_div_mb"></div>
        </>
      );
    };
    return (
      <>
        <table class="table cart_tabel">
          <thead>
            <tr className="cart_title_div_top_tr">
              <th scope="col">Product/Service Title</th>
              <th scope="col">Type</th>
              <th scope="col">Frequency</th>
              <th scope="col">Price</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody className="td_body_cart_div">
            {cartData.map((val, i) => {
              return <ListApp key={i} {...val} />;
            })}
          </tbody>
        </table>
      </>
    );
  };
  const CartTotal = () => {
    return (
      <>
        <div className="cart_total_top">
          <div className="row">
            <div className="col-6">
              <p>Sub Total</p>
            </div>
            <div className="col-6 text-end">
              <p>Amount</p>
            </div>
          </div>
        </div>
        <div className="cart_total_top_inner">
          <div className="row">
            <div className="col-6">
              <p>Sub Total</p>
            </div>
            <div className="col-6 cart_total_top_inner_color">
              <p>
                {currency}
                {total}
              </p>
            </div>
            <div className="col-6">
              <p>GST {gst}%</p>
            </div>
            <div className="col-6 cart_total_top_inner_color">
              <p>{(total * gst) / 100}</p>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-6">
              <p>Total</p>
            </div>
            <div className="col-6 cart_total_top_inner_color">
              <p>
                {currency}
                {gstAmount}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 cart_total_top_btn ">
              <NavLink to="/checkout" className="btn full_btn">
                Checkout
              </NavLink>
            </div>
          </div>
        </div>
        <div className="cart_total_top_call">
          <p>Need Help?</p>
          <a href="tel:+ 877-685-2222">
            <span>Call</span> 877-685-2222
          </a>
        </div>
      </>
    );
  };
  const SliderBottom = () => {
    const settings = {
      dots: true,
      arrows: true,
      infinite: false,
      // autoplay: true,
      // speed: 4000,
      // autoplaySpeed: 4000,
      slidesToShow: 5,
      slidesToScroll: 5,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
          },
        },
      ],
    };
    return (
      <>
        <div className="my_slick_slider_title">
          <h2 className="py-2">Frequently purchased</h2>
        </div>
        <Slider className="slidebluearrows my_slick_slider py-2" {...settings}>
          <div className="productCards col-md-3 col-6">
            <div className="productImageContainer">
              <img
                className="productimg w-80 m-auto"
                alt="Products"
                src={youtubecamp}
              />
            </div>
            <div className="productDetailsContainer ms-4 my-3">
              <p className="productTitle mb-1">Youtube Campaign</p>
              <h5 className="productPrice">$ &nbsp;100</h5>
            </div>
          </div>
          <div className="productCards col-md-3 col-6">
            <div className="productImageContainer">
              <img
                className="productimg w-80 m-auto"
                alt="Products"
                src={pageseo}
              />
            </div>
            <div className="productDetailsContainer ms-4 my-3">
              <p className="productTitle mb-1">Youtube Campaign</p>
              <h5 className="productPrice">$ &nbsp;100</h5>
            </div>
          </div>
          <div className="productCards col-md-3 col-6">
            <div className="productImageContainer">
              <img
                className="productimg w-80 m-auto"
                alt="Products"
                src={socialmediamarketing}
              />
            </div>
            <div className="productDetailsContainer ms-4 my-3">
              <p className="productTitle mb-1">Youtube Campaign</p>
              <h5 className="productPrice">$ &nbsp;100</h5>
            </div>
          </div>
          <div className="productCards col-md-3 col-6">
            <div className="productImageContainer">
              <img
                className="productimg w-80 m-auto"
                alt="Products"
                src={emailmarketing}
              />
            </div>
            <div className="productDetailsContainer ms-4 my-3">
              <p className="productTitle mb-1">Youtube Campaign</p>
              <h5 className="productPrice">$ &nbsp;100</h5>
            </div>
          </div>
          <div className="productCards col-md-3 col-6">
            <div className="productImageContainer">
              <img
                className="productimg w-80 m-auto"
                alt="Products"
                src={youtubecamp}
              />
            </div>
            <div className="productDetailsContainer ms-4 my-3">
              <p className="productTitle mb-1">Youtube Campaign</p>
              <h5 className="productPrice">$ &nbsp;100</h5>
            </div>
          </div>
          <div className="productCards col-md-3 col-6">
            <div className="productImageContainer">
              <img
                className="productimg w-80 m-auto"
                alt="Products"
                src={emailmarketing}
              />
            </div>
            <div className="productDetailsContainer ms-4 my-3">
              <p className="productTitle mb-1">Youtube Campaign</p>
              <h5 className="productPrice">$ &nbsp;100</h5>
            </div>
          </div>
        </Slider>
      </>
    );
  };

  return (
    <>
      <div className=" container mt-5 mb-5">
        {cartData.length > 0 ? (
          <div className="row g-3">
            <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
              <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
              />
              <CartList />
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
              <CartTotal />
            </div>
          </div>
        ) : (
          <EmptyUi
            title="Your Cart is Empty"
            img={cart}
            text="Add something to cart. Lorem ipsum dolor sit amet consectetur."
            btnName="Continue Shopping"
            btnLink="/"
          />
        )}
      </div>
      <div className="row">
        <div className="col-12">
          <div className="cart_slider_top_div">
            <div className="container">
              <SliderBottom />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
