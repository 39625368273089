import {useEffect, useState} from 'react';
import Photo from '../../../img/th.svg';
import axios from 'axios';
import {Navigate, useLocation, useNavigate} from 'react-router-dom';
import {ToastContainer, toast} from 'react-toastify';

import {BlueLinkBtn} from './Btn';
import orderFailedBg from '../../../img/orderFailedBg.png';
import orderFailedBgPhone from '../../../img/orderFailedBgPhone.png';
import './orderFailed.scss';
import ENDPOINT from '../config/ENDPOINT';

export const RepaymentThankYou = () => {
  const location = useLocation();
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState('');
  const [amount, setAmount] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [repaymentId, setRepaymentId] = useState('');

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');
    setRepaymentId(urlParams.get('re'));
    try {
      axios
        .get(ENDPOINT + `repayment/stripe-session-status?session_id=${sessionId}`)
        .then(({data}) => {
          setRepaymentId(data?.repaymentToken);
          setCustomerEmail(data?.customer_email);
          setStatus(data?.status);
          setMessage(data?.message);
          setAmount(data?.amount);
        });
    } catch (err) {
      toast.error('Payment failed! Please try again!', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  }, []);

  if (status === 'open') {
    toast.error('Payment failed! Please try again!', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
    return <OrderFailed repaymentId={repaymentId} message={message} amount={amount} />;
  }

  if (status === 'complete')
    return (
      <>
        <section className=" container py-5">
          <div className="maintenance_provide_title_top_div">
            <div className="maintenance_provide_title_div mb-0">
              <h3>Thank You!</h3>
            </div>
          </div>
          <div className=" text-center">
            <img className="maintenance_Satisfaction_img" src={Photo} alt="photo" />
          </div>
          <div className="maintenance_provide_title_top_div">
            {location?.state?.mode == 'bank-transfer' ? (
              <div className="maintenance_provide_title_div">
                {location?.state ? (
                  <p className=" text-capitalize">
                    Thank you for placing your order. We would like to inform you that your order is
                    currently in pending mode.
                    <br />
                    To complete the payment process, kindly transfer the amount of
                    <strong> {location?.state?.Amount}</strong> to the following bank details:
                    <ul style={{fontWeight: 600, color: 'rgb(26, 115, 232)'}}>
                      <li>Maxlence Consulting Pty Ltd </li>
                      <li>BSB - 063010 </li>
                      <li>Account No - 13459602 </li>
                      <li>Bank Name - Commonwealth Bank </li>
                    </ul>
                    Once the transfer is done, please send us the screenshot of the transaction to{' '}
                    <span style={{textTransform: 'lowercase'}}>support@maxlence.com.au</span>
                    for verification.
                  </p>
                ) : (
                  <p className=" text-capitalize">
                    Thank you for repayment, your subscription will be renewed shortly.
                    <br />{' '}
                    {/* <ul style={{ fontWeight: "600", color: "#1a73e8" }}>
                  <li>Maxlence Consulting Pty Ltd </li>
                  <li>BSB - 063010 </li>
                  <li>Account No - 13459602 </li>
                  <li>Bank Name - Commonwealth Bank </li>
                </ul>{" "}
                <br />
                Once the transfer is done, please send us the screenshot of the
                transaction to{" "}
                <span style={{ textTransform: "lowercase" }}>
                  support@maxlence.com.au
                </span>{" "}
                for verification. */}
                  </p>
                )}
              </div>
            ) : (
              <div className="maintenance_provide_title_div">
                {location?.state ? (
                  <p className=" text-capitalize">
                    Payment received, <strong>{location?.state?.UserName}</strong>
                    <br />
                    We've received your payment of <strong>{location?.state?.Amount}</strong> (Order
                    ID: {location?.state?.OrderId})
                  </p>
                ) : (
                  <p className=" text-capitalize">
                    Thank you for repayment, your subscription will be renewed shortly.
                    <br />{' '}
                    {/* <ul style={{ fontWeight: "600", color: "#1a73e8" }}>
                  <li>Maxlence Consulting Pty Ltd </li>
                  <li>BSB - 063010 </li>
                  <li>Account No - 13459602 </li>
                  <li>Bank Name - Commonwealth Bank </li>
                </ul>{" "}
                <br />
                Once the transfer is done, please send us the screenshot of the
                transaction to{" "}
                <span style={{ textTransform: "lowercase" }}>
                  support@maxlence.com.au
                </span>{" "}
                for verification. */}
                  </p>
                )}
              </div>
            )}
          </div>
          <div className=" mt-4 text-center d-flex justify-content-center align-items-center">
            <div style={{width: '20%'}}>
              <BlueLinkBtn title="Go to home" link="/" />
            </div>
          </div>
        </section>
      </>
    );

  return null;
};
const OrderFailed = ({repaymentId, message, amount}) => {
  const [isPhone, setIsPhone] = useState(window.innerWidth < 455);
  const navigate = useNavigate(); // Hook for navigation
  const [redirectTime, setRedirectTime] = useState(10); // seconds

  useEffect(() => {
    const handleResize = () => {
      setIsPhone(window.innerWidth < 455);
    };

    window.addEventListener('resize', handleResize);

    const countdownInterval = setInterval(() => {
      setRedirectTime(prevTime => {
        if (prevTime <= 1) {
          clearInterval(countdownInterval);
          navigate(`/r/${repaymentId}`); // Redirect to profile page
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => {
      window.removeEventListener('resize', handleResize);
      clearInterval(countdownInterval); // Cleanup the interval on component unmount
    };
  }, [navigate]);

  return (
    <div className="orderFailed">
      <img
        src={isPhone ? orderFailedBgPhone : orderFailedBg}
        alt=""
        className="orderFailed__image"
      />

      <div className="orderFailed__content">
        <h2 className="orderFailed__price">{amount}</h2>
        <div className="orderFailed__message">Payment Failed!</div>
        <div className="orderFailed__subtext">
          Payment failed, if amount deducted, <br /> it will be reverted back.
        </div>

        <div className="orderFailed__details">{message}</div>

        <div className="orderFailed__redirect">
          You will be redirecting to the checkout page in{' '}
          <span className="orderFailed__redirect-time">{redirectTime} sec</span>
        </div>
      </div>
    </div>
  );
};
