import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import { useDispatch } from "react-redux";
import ENDPOINT from "../config/ENDPOINT";
import axios from "axios";
import { setShowLoginModal } from "../redux/modalStateSlice";
import StartedIcon from "../../../img/start.svg";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";

export const QuestionarePage = () => {
  const [showStartPage, setShowStartPage] = useState(true);
  const QuestionarePageStart = () => {
    const dispatch = useDispatch();

    const handleClick = () => {
      let token = localStorage.getItem("token");
      if (!token) dispatch(setShowLoginModal());
      else setShowStartPage(false);
    };
    return (
      <>
        <div className="questionare_page_start">
          <div className="questionare_page_start_inner">
            <p>
              Okay thanks for considering Maxlence a good fit for helping you
              grow your business
            </p>
            <p>
              We’ve got few questions . It won’t take much time. Pinky promise
              <img src={StartedIcon} alt="icon" />
            </p>
            <button onClick={handleClick} className="previous  action-button">
              Start Now <HiOutlineArrowNarrowRight />
            </button>
          </div>
        </div>
      </>
    );
  };
  return <>{showStartPage ? <QuestionarePageStart /> : <Questionare />}</>;
};

const Questionare = () => {
  const [questionare, setQuestionare] = useState([]);
  const [cat, setCat] = useState([]);
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [showErrors, setShowErrors] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { page } = useParams();
  const { service } = useParams();

  const fetchQueCatBySlug = async () => {
    let res = await fetch(ENDPOINT + `question-cat/singleCat/${service}`);
    let data = await res.json();
    setCat(data);
  };

  useEffect(() => {
    fetchQueCatBySlug();
  }, []);

  const fetchQuestionare = async () => {
    let res = await fetch(ENDPOINT + `questionare/${cat.id}`);
    let data = await res.json();
    setQuestionare(data);
  };

  useEffect(() => {
    fetchQuestionare();
  }, [cat]);

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    setSelectedIdx(selectedIdx - 1);
  };

  const updateAnswersState = (ans, idx) => {
    setQuestionare((old) => {
      let new_state = old.slice();
      new_state[idx]["answer"] = ans;
      return new_state;
    });
  };

  const updateMultiAnswersState = (ans, idx) => {
    ans = Number(ans);
    let new_state = questionare.slice();
    let answers = new_state[idx]["answer"];
    let isAnswerArray = Array.isArray(answers);

    if (isAnswerArray) {
      let index = answers.findIndex((a) => a == ans);
      if (index === -1) answers.push(ans);
      else answers.splice(index, 1);
    } else new_state[idx]["answer"] = [ans];

    setQuestionare(new_state);
    // setQuestionare((old) => {
    //   let new_state = old.slice();
    //   let answers = new_state[idx]["answer"];
    //   let isAnswerArray = Array.isArray(answers);

    //   if (isAnswerArray) {
    //     let index = answers.findIndex((a) => a === ans);
    //     if (index === -1) answers.push(ans);
    //     else answers.splice(index, 1);
    //   } else new_state[idx]["answer"] = [ans];
    //   return new_state;
    // });
  };

  const submitData = () => {
    let token = localStorage.getItem("token");
    // if (!token) dispatch(setShowLoginModal());
    // else {
    try {
      axios
        .post(
          ENDPOINT + "quest",
          { questionare },
          { headers: { "x-access-token": token } }
        )
        .then((res) => {
          if (res.status === 200) {
            navigate(`/${page}/${service}/buy-plans`);
          } else console.log("Error while posting");
        });
    } catch (err) {
      console.log(err);
    }
    // }
  };

  const QuestionOptions = ({ ques, idx }) => {
    let answer_options = ques?.AnswerOptions;

    const CheckboxAnswers = () => {
      return (
        <div className="row  justify-content-center flex-column">
          {answer_options.map((answer, key) => {
            return (
              <label
                key={key}
                className="col-md-4 mx-auto my-2"
                htmlFor={`checkbox_${idx}_${key}`}
              >
                <div className="defultbox">
                  <input
                    multiple
                    id={`checkbox_${idx}_${key}`}
                    name="checkbox"
                    type="checkbox"
                    value={answer.id}
                    checked={questionare[idx]?.answer?.includes(answer.id)}
                    onChange={(e) => {
                      updateMultiAnswersState(e.target.value, idx);
                    }}
                  />
                  <div className="checkbox_title">
                    <h6>{answer.title}</h6>
                  </div>
                </div>
              </label>
            );
          })}
        </div>
      );
    };
    const RadioAnswers = () => {
      return (
        <div className="row justify-content-center">
          {answer_options.map((ans, key) => {
            return (
              <label
                key={key}
                className=" col-md-4 m-2"
                htmlFor={`radio_${idx}_${key}`}
              >
                <div className="defultbox">
                  <input
                    id={`radio_${idx}_${key}`}
                    name="radio"
                    value={ans.id}
                    checked={ans.id == questionare[idx]?.answer}
                    type="radio"
                    onChange={(e) => updateAnswersState(e.target.value, idx)}
                  />
                  <div className="radiobox_title">
                    <h6>{ans.title}</h6>
                  </div>
                </div>
              </label>
            );
          })}
        </div>
      );
    };

    const TextboxAnswers = () => {
      return (
        <div className="Step6_inner_div">
          <input
            type="text"
            className="form-control"
            name="textbox"
            defaultValue={questionare[idx]?.answer}
            onBlur={(e) => updateAnswersState(e.target.value, idx)}
          />
        </div>
      );
    };
    const TextareaAnswers = () => {
      return (
        <div className="Step6_inner_div">
          <textarea
            name="textarea"
            className="form-control"
            rows="4"
            cols="50"
            defaultValue={questionare[idx]?.answer}
            onBlur={(e) => updateAnswersState(e.target.value, idx)}
          />
        </div>
      );
    };

    const DropdownAnswers = () => {
      return (
        <div className="row justify-content-center">
          <div id="selector" className="col-md-4 text-center">
            <select
              className="form-select"
              aria-label="Default select example"
              name="dropdown"
              id="dropdown"
              value={questionare[idx]?.answer}
              onChange={(e) => updateAnswersState(e.target.value, idx)}
            >
              <option value="">Select</option>
              {answer_options?.map((ans, key) => {
                return (
                  <option key={key} value={ans.id}>
                    {ans?.title}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      );
    };

    switch (ques.que_type) {
      case "Checkbox":
        return <CheckboxAnswers />;
      case "Radiobox":
        return <RadioAnswers />;
      case "Textbox":
        return <TextboxAnswers />;
      case "Textarea":
        return <TextareaAnswers />;
      case "Dropdown":
        return <DropdownAnswers />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="step_inner_main_top_div">
        <div className="stepper_inner_div">
          <div className="stepper_inner_title_div">
            <h3>Great! You are almost there</h3>
          </div>
          <Stepper alternativeLabel activeStep={activeStep}>
            {questionare?.map((step, index) => {
              const labelProps = {};
              const stepProps = {};
              return (
                <Step {...stepProps} key={index}>
                  <StepLabel {...labelProps}></StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </div>
      </div>

      {questionare?.map((ques, idx) => {
        return selectedIdx === idx ? (
          <div key={idx} className="container">
            <div className="step_title_div">
              <div className="step_title_div_inner">
                <h3 className="fs-title">{ques?.que}</h3>
              </div>
            </div>
            <QuestionOptions ques={ques} idx={idx} />
            <span className="row text-center">
              {showErrors && !questionare[selectedIdx]?.answer ? (
                <span style={{ color: "red" }}>This Field is required</span>
              ) : null}
            </span>
          </div>
        ) : null;
      })}

      <div className=" step_btn_div">
        <button
          style={{ width: "15%" }}
          className="previous  brn out_line_btn"
          disabled={activeStep === 0}
          hidden={activeStep === 0}
          onClick={handleBack}
        >
          Previous
        </button>
        <button
          className="next action-button"
          onClick={() => {
            if (!questionare[selectedIdx]?.answer) {
              setShowErrors(true);
            } else if (selectedIdx < questionare.length - 1) {
              setShowErrors(false);
              setActiveStep((old) => old + 1);
              setSelectedIdx(selectedIdx + 1);
            } else submitData();
          }}
        >
          {questionare.length === activeStep + 1 ? "Submit" : "Next ➙"}
        </button>
      </div>
    </div>
  );
};
