import { NavLink, useNavigate } from "react-router-dom";
import YourServicesicon from "../../../img/serv.svg";
// mui
import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ENDPOINT from "../config/ENDPOINT";

export const YourServices = () => {
  const [services, setServices] = useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const fetchOrder = async () => {
    let res = await fetch(ENDPOINT + "order/getUserOrders", {
      headers: { "x-access-token": token },
    });
    let data = await res.json();
    setServices(data);
  };

  useEffect(() => {
    fetchOrder();
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  
  const CardApp = (props) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const MyModal = () => {
      return (
        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Text in a modal
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  Duis mollis, est non commodo luctus, nisi erat porttitor
                  ligula.
                </Typography>
              </Box>
            </Fade>
          </Modal>
        </div>
      );
    };

    const dummyImage =
      "https://wewakedev.github.io/Maxlence-Official-Website/assests/pageseo.jpg";

    return (
      <>
        <div className="col-12">
          <div className="YourServices_new_top">
            <div className="YourServices_new_inner">
              <p>Service Purchased</p>
              <p className="YourServices_new_inner_inner">
                {props.createdAt.split("T")[0]}
              </p>
            </div>
            <div className="YourServices_new_inner YourServices_new_inner2">
              <p>Billing address</p>
              <p className="YourServices_new_inner_inner">
                {props.user.companyName}
              </p>
            </div>
            <div className="YourServices_new_inner YourServices_new_inner3">
              <p>Order # {props.id}</p>
              <p className="YourServices_new_inner_inner">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate("/your-services/your-services-details", {
                      state: { id: props.id },
                    })
                  }
                >
                  {" "}
                  View Details
                </div>
              </p>
            </div>
          </div>
          {props.subscriptions?.map((subs, i) => {
            return (
              <div key={i} className="YourServices_new_img_div">
                <ul>
                  <li>
                    <img
                      src={
                        subs.package?.question_cat_master?.img
                          ? ENDPOINT +
                            "uploads/" +
                            subs.package?.question_cat_master?.img
                          : subs.overall_plan?.category_master?.img
                          ? ENDPOINT +
                            "uploads/" +
                            subs.overall_plan?.category_master?.img
                          : dummyImage
                      }
                      alt="icon"
                    />
                  </li>
                  <li>
                    <p className="YourServices_new_img_text">
                      {subs.package?.question_cat_master?.name ||
                        subs.overall_plan?.category_master?.name}
                    </p>
                    <p className="YourServices_new_img_text2">${subs.amount}</p>
                    <p className="YourServices_new_img_text3">
                      {subs.package?.title || subs.overall_plan?.title}
                    </p>
                  </li>
                </ul>
                <button
                  onClick={handleOpen}
                  className="btn full_btn YourServices_new_img_text_btn"
                >
                  Track Service
                </button>
              </div>
            );
          })}
        </div>
        <MyModal />
      </>
    );
  };
  return (
    <>
      <section className="container mb-4 mt-4">
        <p>Your Service Orders</p>
        <div className="row g-4">
          {services.map((val, i) => {
            return <CardApp key={i} {...val} />;
          })}
        </div>
      </section>
    </>
  );
};
