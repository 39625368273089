import QuoteIcon from "../../../img/quote.svg";
import { FooterContact } from "../components/FooterContact";
import ENDPOINT from "../config/ENDPOINT";
import HTMLReactParser from "html-react-parser";
import { VscStarFull, VscStarHalf } from "react-icons/vsc";
import { useEffect, useState } from "react";
import { BlueBtn } from "../components/Btn";

export const Testimonial = () => {
  const [testimonial, setTestimonial] = useState([]);

  const [limit, setLimit] = useState(4);
  const fetchMoreItems = () => {
    setLimit((old) => old + 4);
  };

  async function getTestimonial() {
    let res = await fetch(ENDPOINT + `testimonial?limit=${limit}`);
    const data = await res.json();
    setTestimonial(data);
  }

  useEffect(() => {
    getTestimonial();
  }, [limit]);

  const dummyImage =
    "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg";

  const SliderApp = (props) => {
    return (
      <>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
          <div>
            <div className="seo_service_rating_div">
              <img className="QuoteIcon_img" src={QuoteIcon} alt="icon" />
              <div className="seo_service_rating_inner_div">
                <img
                  src={
                    props.pic ? ENDPOINT + "uploads/" + props.pic : dummyImage
                  }
                  alt="img"
                />
                <p>{props.client_name}</p>
              </div>
              <h6>{HTMLReactParser(props.desc)}</h6>
              <div className="seo_service_rating_star_div">
                {props.rating === "1" ? (
                  <VscStarFull />
                ) : props.rating === "2" ? (
                  <>
                    <VscStarFull /> <VscStarHalf />
                  </>
                ) : props.rating === "3" ? (
                  <>
                    <VscStarFull /> <VscStarFull /> <VscStarHalf />
                  </>
                ) : props.rating === "4" ? (
                  <>
                    <VscStarFull /> <VscStarFull /> <VscStarFull />{" "}
                    <VscStarHalf />
                  </>
                ) : (
                  <>
                    <VscStarFull /> <VscStarFull /> <VscStarFull />{" "}
                    <VscStarFull />
                    <VscStarHalf />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <div className="testimonial_top_div">
        <div className="testimonial_top_inner_div">
          <h3>
            Hear it traight from <br /> our esteemed client
          </h3>
          <p>
            Andrew, founder of Luno Electrical, on how we <br /> took his idea
            to launch and scale.
          </p>
          <div>
            <iframe
              className="video"
              src="https://www.youtube.com/embed/-NO-AYiNsG0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
      <section className=" container mt-5">
        <div class="text-center">
          <h1>
            Read What Our <span>Customers Say</span>
          </h1>
        </div>
        <div className="testimonial_riv_div">
          <div className="row g-5 ">
            {testimonial.map((val, i) => {
              return <SliderApp key={i} {...val} />;
            })}
          </div>
        </div>
        <div className="col-md-3 col-7 m-auto">
          <BlueBtn title="View More" onClick={fetchMoreItems} />
        </div>
      </section>
      <FooterContact />
    </>
  );
};
