import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
const ProtectedRoute = ({ children }) => {
  const [isLoggedIn, setisLoggedIn] = useState(null);
  const userAuth = () => {
    const token = localStorage.getItem("token");
    token ? setisLoggedIn(true) : setisLoggedIn(false);
  };
  useEffect(() => {
    userAuth();
  }, [isLoggedIn]);

  if (isLoggedIn === false) {
    return <Navigate to="/" replace />;
  }
  return children;
};
export default ProtectedRoute;
