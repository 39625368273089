import { NavLink } from "react-router-dom";
import Websitetemp1 from "../assests/websitetemp1.jpg";
import { BiShareAlt } from "react-icons/bi";
// mui
import React, { useState, useEffect } from "react";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import WhatsappIcon from "../../../img/wh.svg";
import FaceboonIcon from "../../../img/fa.svg";
import TwitterIcon from "../../../img/tw.svg";
import GmailIcon from "../../../img/gm.svg";
import CopyIcon from "../../../img/copy.svg";
import WebPage from "../../../img/web.png";
import { MdOutlineClose } from "react-icons/md";
import ENDPOINT from "../config/ENDPOINT";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share";
import CLIENT_URL from "../config/CLIENT_URL";

export const DesignInspiration = () => {
  const [designData, setDesignData] = useState([]);
  const ImgLink = CLIENT_URL + "resources/design-inspiration";

  const fetchDesignInspirationByGroup = async () => {
    const { data } = await axios.get(
      ENDPOINT + `design_inspiration?limit=${3}`
    );
    setDesignData(data);
  };

  useEffect(() => {
    fetchDesignInspirationByGroup();
  }, []);

  const CardApp = (props) => {
    const ImageCard = (val) => {
      const [openShare, setOpenShare] = React.useState(false);
      const [openImgView, setOpenImgView] = React.useState(false);
      const handleOpenShare = () => setOpenShare(true);
      const handleCloseShare = () => setOpenShare(false);

      const handleOpenImgView = () => setOpenImgView(true);
      const handleCloseImgView = () => setOpenImgView(false);

      const ImgViewModal = () => {
        const style = {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // width: 400,
          bgcolor: "background.paper",
          border: "none",
          boxShadow: 24,
          p: 4,
        };
        return (
          <div>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={openImgView}
              onClose={handleCloseImgView}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={openImgView}>
                <Box sx={style} className="img_view_modal_div">
                  <div className="img_view_modal_inner_div">
                    <img src={ENDPOINT + "uploads/" + val.img} alt="sd" />
                  </div>
                  <span
                    onClick={handleCloseImgView}
                    className="close_img_view_btn_div"
                  >
                    <MdOutlineClose />
                  </span>
                  <div className="delete_modal_btn_div">
                    <ul>
                      <li className="btn_one">
                        <button
                          onClick={handleCloseImgView}
                          className="outline_btn btn"
                        >
                          Cancel
                        </button>
                      </li>
                      {val.url ? (
                        <li className="btn_two">
                          <a
                            href={val.url}
                            className="btn full_btn ms-1"
                            target="_blank"
                          >
                            Website link
                          </a>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </Box>
              </Fade>
            </Modal>
          </div>
        );
      };
      return (
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
          <TransitionsModal
            {...val}
            openShare={openShare}
            handleCloseShare={handleCloseShare}
          />
          <ImgViewModal {...val} />
          <div className="design_inspiration_div">
            <NavLink onClick={handleOpenImgView}>
              <div
                className="design_inspiration_img"
                style={{
                  backgroundImage: `url(${ENDPOINT + "uploads/" + val.img})`,
                }}
              ></div>
            </NavLink>
            <BiShareAlt onClick={handleOpenShare} />
          </div>
        </div>
      );
    };
    const TransitionsModal = (props) => {
      const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "none",
        boxShadow: 24,
        p: 4,
      };
      const ShareData = [
        {
          link: "https://www.google.com/",
          icon: WhatsappIcon,
          title: "WhatsApp",
        },
        {
          link: "https://www.google.com/",
          icon: FacebookIcon,
          title: "Facebook",
        },
        {
          link: "https://www.google.com/",
          icon: TwitterIcon,
          title: "Twitter",
        },
        {
          link: "https://www.google.com/",
          icon: GmailIcon,
          title: "Gmail",
        },
      ];
      return (
        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={props.openShare}
            onClose={props.handleCloseShare}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={props.openShare}>
              <Box sx={style} className="design_inspiration_modal_div">
                <div>
                  <h3>Share</h3>
                  <div className="row g-1">
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                      <div>
                        <WhatsappShareButton
                          url={"https://tecnogex.maxlence.com.au/"}
                          title={"Tecnogex - An Australian company"}
                          separator=":: "
                        >
                          <div className="design_inspiration_share_div_inner_top">
                            <div className="design_inspiration_share_div_inner">
                              <img src={WhatsappIcon} alt="icon" />
                            </div>
                          </div>
                          <p className="design_inspiration_share_div_inner_title">
                            WhatsApp
                          </p>
                        </WhatsappShareButton>
                      </div>
                    </div>

                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                      <div>
                        <FacebookShareButton
                          url={"https://tecnogex.maxlence.com.au/"}
                          quote={"Tecnogex - An Australian company"}
                          hashtag="#tecnogex"
                        >
                          <div className="design_inspiration_share_div_inner_top">
                            <div className="design_inspiration_share_div_inner">
                              <img src={FaceboonIcon} alt="icon" />
                            </div>
                          </div>
                          <p className="design_inspiration_share_div_inner_title">
                            Facebook
                          </p>
                        </FacebookShareButton>
                      </div>
                    </div>

                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                      <div>
                        <TwitterShareButton
                          url={"https://tecnogex.maxlence.com.au/"}
                          title={"Tecnogex - An Australian company"}
                          hashtag="#tecnogex"
                        >
                          <div className="design_inspiration_share_div_inner_top">
                            <div className="design_inspiration_share_div_inner">
                              <img src={TwitterIcon} alt="icon" />
                            </div>
                          </div>
                          <p className="design_inspiration_share_div_inner_title">
                            Twitter
                          </p>
                        </TwitterShareButton>
                      </div>
                    </div>

                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                      <div>
                        <EmailShareButton
                          url={"https://tecnogex.maxlence.com.au/"}
                          subject={"Tecnogex - An Australian company"}
                          separator=":: "
                        >
                          <div className="design_inspiration_share_div_inner_top">
                            <div className="design_inspiration_share_div_inner">
                              <img src={GmailIcon} alt="icon" />
                            </div>
                          </div>
                          <p className="design_inspiration_share_div_inner_title">
                            Gmail
                          </p>
                        </EmailShareButton>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="design_inspiration_modal_copy">
                        {ImgLink}
                        <span
                          onClick={() => {
                            navigator.clipboard.writeText(ImgLink);
                            toast.success("URL copied!", {
                              position: "bottom-right",
                              autoClose: 1000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "colored",
                            });
                          }}
                        >
                          <img src={CopyIcon} alt="icon" />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </Fade>
          </Modal>
        </div>
      );
    };
    return (
      <>
        <div className="container websitetemp my-5">
          <div className="row justify-content-between mb-3">
            <div className="col-4">
              <h5>
                <b>{props.title}</b>
              </h5>
            </div>
            <div className="col-2 text-end">
              <p className=" me-4">
                <b>
                  <NavLink to={`/resources/design-inspiration/${props.slug}`}>
                    More
                  </NavLink>
                </b>
              </p>
            </div>
          </div>
          <div className="row g-4">
            {props.data.map((val, i) => (
              <ImageCard key={i} {...val} />
            ))}
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <div className="container designinsp_title text-center border-bottom py-3 my-3">
        <h1 className="all_h1_title">
          Lorem ipsum
          <span> dolor </span>
          sit amet consectetur
          <span> adipisicing </span> elit. Possimus.
        </h1>
      </div>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      {Object.keys(designData).map((key) => {
        let cat_data = designData[key];
        return (
          <CardApp
            key={key}
            title={cat_data.title}
            slug={cat_data.slug}
            cat_id={key}
            data={cat_data.data}
          />
        );
      })}
    </>
  );
};
