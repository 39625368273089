import Wifi from "../../../img/wifi.png";
import Sim from "../../../img/sim.png";
import { MdOutlineModeEdit } from "react-icons/md";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { HiArrowNarrowLeft } from "react-icons/hi";
import React, { useState, useEffect, useRef } from "react";
import Master from "../../../img/master.png";
import Visa from "../../../img/visa.png";
import Amex from "../../../img/amex.png";
import Jcb from "../../../img/jcb.png";
import Diners from "../../../img/diners.png";
import UnionPay from "../../../img/union-pay.png";
import Discover from "../../../img/discover.png";
import Default from "../../../img/default-card.png";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import {
  PayPalScriptProvider,
  PayPalButtons,
  PayPalHostedFieldsProvider,
  PayPalHostedField,
  usePayPalHostedFields,
} from "@paypal/react-paypal-js";

import {
  formatCreditCardNumber,
  formatCVV,
  formatExpirationDate,
} from "./utils";

import "react-credit-cards/es/styles-compiled.css";
import ENDPOINT from "../config/ENDPOINT";
import axios from "axios";
import { BlueBtn } from "../components/Btn";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Checkout = () => {
  const [card, setCard] = React.useState([]);
  const [cartData, setCartData] = useState([]);
  // const [clientToken, setClientToken] = useState(null);
  const [total, setTotal] = useState(0);
  const [currency, setCurrency] = useState(null);
  const token = localStorage.getItem("token");
  const [cardChecked, setCardChecked] = useState(false);
  const [checked, setChecked] = useState(true);
  const [cardValue, setCardValue] = useState({});
  const navigate = useNavigate();
  const [showCVV, setShowCVV] = useState(false);
  const [cardCVV, setCardCVV] = useState("");
  const user = useSelector((state) => state.state.user);
  const geoInfo = useSelector((state) => state.state.geoInfo);
  const [gst, setGst] = useState(0);
  const [gstAmount, setGstAmount] = useState(0);
  const [expanded, setExpanded] = React.useState("panel1");

  const fetchCards = async () => {
    const { data } = await axios.get(ENDPOINT + `payment-card/user_cards`, {
      headers: { "x-access-token": token },
    });
    setCard(data);
  };

  const getCartdata = async () => {
    const { data } = await axios.get(
      ENDPOINT + `cart/user_cart?country=${geoInfo?.country_code}`,
      {
        headers: { "x-access-token": token },
      }
    );
    setCartData(data.products);
    setGst(data.gst);
    let total = data.products.reduce(
      (acc, curr) => acc + Number(curr.final_price),
      0
    );
    setTotal(total);
    setGstAmount(total + (total * data.gst) / 100);
  };

  useEffect(() => {
    fetchCards();
    if (geoInfo?.country_code) getCartdata();
  }, [geoInfo]);

  useEffect(() => {
    cartData.forEach((item) =>
      setCurrency(
        item.plan_price?.itf_country?.currency ||
          item.shop_plan_price?.itf_country?.currency
      )
    );
  }, [cartData]);

  const Address = () => {
    return (
      <div className="col-12">
        <div>
          <div className="payment_main_title">
            <h3>Billing Address</h3>
          </div>
          <div className="payment_card checkout_address">
            <Link to="/your-account">
              <MdOutlineModeEdit />
            </Link>
            <h3>{user.companyName}</h3>
            <p>{user.workingAddress}</p>
            <p>Contact No - {user.phoneNumber}</p>
            <p>Email - {user.companyEmail}</p>
          </div>
        </div>
      </div>
    );
  };

  const SavedCards = () => {
    const CardApp = (props) => {
      const savedCard = props.number
        .toString()
        .match(/.{1,4}/g)
        .join(" ");
      return (
        <>
          <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="saved_cards_div ">
              <div className="saved_cards_wifi">
                <img src={Wifi} alt="wifi" />
                <p>{props.type}</p>
              </div>
              <h1>{props.issuer}</h1>
              <div className="saved_cards_number">
                <p>
                  <span>XXXX</span>
                  <span>XXXX</span>
                  <span>XXXX</span>
                  <span>{savedCard.split(" ")[3]}</span>
                </p>
                <img src={Sim} alt="sim" />
              </div>
              <p className="saved_cards_name">{props.name}</p>
              <p className="saved_cards_date">{props.expiry}</p>
              <div>
                <div className="form-check saved_cards_input">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id={props.id}
                    checked={props.index === cardChecked}
                    onChange={(e) => setCardChecked(props.index)}
                    onClick={() => {
                      setCardValue(props);
                      setShowCVV(true);
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={props.id}
                  ></label>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    };
    return (
      <>
        <div className="col-12">
          <div className="payment_main_title mt-5">
            <h3>Saved Cards</h3>
          </div>
          <div className="row g-3">
            {card.map((val, i) => {
              return <CardApp key={i} index={i + 1} {...val} />;
            })}
          </div>
        </div>
      </>
    );
  };

  const OrderSummary = () => {
    const PriceListApp = (props) => {
      return (
        <>
          <div className="order_summary_details_div">
            <p style={{ fontWeight: "660" }}>
              {user.companyName || user.fullname} -{" "}
              {props.plan_price?.package_plan?.package?.question_cat_master
                ?.name ||
                props.shop_plan_price?.shop_package_plan?.shop_package?.product
                  ?.category_master?.name}
            </p>
            <p style={{ color: "#212529" }}>
              {props.plan_price?.package_plan?.package?.title ||
                props.shop_plan_price?.shop_package_plan?.shop_package?.title}
            </p>
            <p style={{ color: "#6D6D6D" }}>
              {props.plan_price?.package_plan?.title ||
                props.shop_plan_price?.shop_package_plan?.title}
            </p>
            <div className="order_summary_price_div">
              <b>
                {props.plan_price?.itf_country?.currency ||
                  props.shop_plan_price?.itf_country?.currency}
                {props.final_price}
              </b>
            </div>
            <hr />
          </div>
        </>
      );
    };
    return (
      <>
        <div className="order_summary_div">
          <div className="order_summary_div_title">
            Order summary
            <span>{cartData.length}</span>
            <hr />
          </div>
          {cartData.map((val, i) => {
            return <PriceListApp key={i} {...val} />;
          })}

          <div className="order_summary_voucher_div">
            Have a voucher?
            <hr />
          </div>
          <div>
            <div className="row g-2">
              <div className="col-12">
                <div className="row">
                  <div className="col-6 order_summary_total_title">
                    Sub Total
                  </div>
                  <div className="col-6 order_summary_total_price">
                    {currency}
                    {total}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-6 order_summary_total_title">
                    GST {gst}%
                  </div>
                  <div className="col-6 order_summary_total_price">
                    {(total * gst) / 100}
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
          <div>
            <div className="row g-2">
              <div className="col-12">
                <div className="row">
                  <div className="col-6 order_summary_total_title">Total</div>
                  <div className="col-6 order_summary_total_price">
                    {currency}
                    {gstAmount}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* payment button */}
          <div className="row">
            {cardChecked ? null : (
              <div className="col-12 cart_total_top_btn">
                <button form="my-form" type="submit" className=" btn full_btn">
                  Make Payment
                </button>
              </div>
            )}
            <div className="checkout_back_btn" onClick={() => navigate(-1)}>
              <span>
                <HiArrowNarrowLeft />
              </span>
              Back to cart
            </div>
          </div>

          <div>
            <div className="row g-2">
              <div className="col-12">
                <div className="row mt-3">
                  <div style={{ fontSize: "12px" }}>
                    By purchasing, you accept the{" "}
                    <NavLink to="/terms_&_conditions">Terms of Use</NavLink> and
                    acknowledge reading the{" "}
                    <NavLink to="/privacy_policy">Privacy Policy</NavLink> . You
                    also agree to auto renewal of your yearly subscription for
                    ₹6,000.00, which can be disabled at any time through your
                    account. In accordance with RBI guidelines, your card
                    details will be saved securely for future purchases and
                    subscription renewals. An e-mandate will be created for a
                    maximum amount of ₹15,000, but you’ll only be charged the
                    amount of your purchase.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  // const SubmitPayment = ({ customStyle }) => {
  //   const [paying, setPaying] = useState(false);
  //   const cardHolderName = useRef(null);
  //   const hostedField = usePayPalHostedFields();

  //   const handleClick = () => {
  //     if (!hostedField?.cardFields) {
  //       const childErrorMessage =
  //         "Unable to find any child components in the <PayPalHostedFieldsProvider />";

  //       alert(childErrorMessage);
  //       throw new Error(childErrorMessage);
  //     }
  //     const isFormInvalid =
  //       Object.values(hostedField.cardFields.getState().fields).some(
  //         (field) => !field.isValid
  //       ) || !cardHolderName?.current?.value;

  //     if (isFormInvalid) {
  //       return alert("The payment form is invalid");
  //     }
  //     setPaying(true);
  //     hostedField.cardFields
  //       .submit({
  //         cardholderName: cardHolderName?.current?.value,
  //       })
  //       .then((data) => {
  //         // Your logic to capture the transaction
  //         fetch("url_to_capture_transaction", {
  //           method: "POST",
  //         })
  //           .then((response) => response.json())
  //           .then((data) => {
  //             // Here use the captured info
  //           })
  //           .catch((err) => {
  //             // Here handle error
  //           })
  //           .finally(() => {
  //             setPaying(false);
  //           });
  //       })
  //       .catch((err) => {
  //         // Here handle error
  //         setPaying(false);
  //       });
  //   };

  //   return (
  //     <>
  //       <label title="This represents the full name as shown in the card">
  //         Card Holder Name
  //         <input
  //           id="card-holder"
  //           ref={cardHolderName}
  //           className="card-field"
  //           style={{ ...customStyle, outline: "none" }}
  //           type="text"
  //           placeholder="Full name"
  //         />
  //       </label>
  //       <button
  //         className={`btn${paying ? "" : " btn-primary"}`}
  //         style={{ float: "right" }}
  //         onClick={handleClick}
  //       >
  //         {paying ? <div className="spinner tiny" /> : "Pay"}
  //       </button>
  //     </>
  //   );
  // };

  // const AddNewCard = () => {
  //   return (
  //     <>
  //       <div className="col-12">
  //         <div className="payment_main_title mt-5">
  //           <h3>Other Payment Modes</h3>
  //         </div>
  //         <div>
  //           <Tab.Container id="left-tabs-example" defaultActiveKey="first">
  //             <div className="row">
  //               <div className="col-12">
  //                 <div className="payment_modes_tab_div">
  //                   <Nav variant="pills">
  //                     <Nav.Item>
  //                       <Nav.Link eventKey="first">
  //                         Credit / Debit Card
  //                       </Nav.Link>
  //                     </Nav.Item>
  //                     {/* <Nav.Item> */}
  //                     {/* <Nav.Link eventKey="second"> */}
  //                     <button onClick={displayRazorpay}>
  //                       Buy with Razorpay
  //                     </button>
  //                     {/* </Nav.Link> */}
  //                     {/* </Nav.Item> */}
  //                     {/* <Nav.Item>
  //                       <Nav.Link eventKey="upi" className="gpay_link">
  //                         Pay with <img src={GpayImg} alt="gpay" />
  //                       </Nav.Link>
  //                     </Nav.Item> */}
  //                     {/* {clientToken ? ( */}
  //                     <PayPalScriptProvider
  //                       options={{
  //                         "client-id":
  //                           "AXK5pbzXY9J8pcOApb91gmxeE3FMpGdHSQdCiwJDCyIQvgMD0dSNk6lvnstzDQ7gpOci0UG1UorQ01oU",
  //                         // components: "buttons,hosted-fields",
  //                         // "data-client-token": clientToken,
  //                         // intent: "capture",
  //                         // vault: false,
  //                       }}
  //                     >
  //                       <PayPalButtons
  //                         style={{ layout: "vertical" }}
  //                         disabled={false}
  //                         forceReRender={[cartData]}
  //                         createOrder={() => {
  //                           return handleCreateOrder();
  //                         }}
  //                         onApprove={(data, actions) => {
  //                           return actions.order.capture().then((details) => {
  //                             // console.log(details);
  //                             Swal.fire({
  //                               position: "center",
  //                               icon: "success",
  //                               title: "success",
  //                               text: "Payment successful",
  //                               showConfirmButton: false,
  //                               timer: 1500,
  //                             });
  //                             navigate("/");
  //                           });
  //                         }}
  //                       />
  //                       {/* <PayPalHostedFieldsProvider
  //                           createOrder={() => {
  //                             return handleCreateOrder();
  //                           }}
  //                         >
  //                           <label htmlFor="card-number">
  //                             Card Number
  //                             <span style={{ color: 'red' }}>*</span>
  //                           </label>
  //                           <PayPalHostedField
  //                             id="card-number"
  //                             className="card-field"
  //                             style={{ color: "black", fontSize: 20 }}
  //                             hostedFieldType="number"
  //                             options={{
  //                               selector: "#card-number",
  //                               placeholder: "4111 1111 1111 1111",
  //                             }}
  //                           />
  //                           <label htmlFor="cvv">
  //                             CVV<span style={{ color: 'red' }}>*</span>
  //                           </label>
  //                           <PayPalHostedField
  //                             id="cvv"
  //                             className="card-field"
  //                             style={{ color: "black", fontSize: 20 }}
  //                             hostedFieldType="cvv"
  //                             options={{
  //                               selector: "#cvv",
  //                               placeholder: "123",
  //                               maskInput: true,
  //                             }}
  //                           />
  //                           <label htmlFor="expiration-date">
  //                             Expiration Date
  //                             <span style={{ color: 'red' }}>*</span>
  //                           </label>
  //                           <PayPalHostedField
  //                             id="expiration-date"
  //                             className="card-field"
  //                             style={{ color: "black", fontSize: 20 }}
  //                             hostedFieldType="expirationDate"
  //                             options={{
  //                               selector: "#expiration-date",
  //                               placeholder: "MM/YYYY",
  //                             }}
  //                           />
  //                           <SubmitPayment
  //                             customStyle={{
  //                               border: "1px solid #606060",
  //                               boxShadow: "2px 2px 10px 2px rgba(0,0,0,0.1)",
  //                             }}
  //                           />
  //                         </PayPalHostedFieldsProvider> */}
  //                     </PayPalScriptProvider>
  //                     {/* ) : (
  //                       <h1>Loading token...</h1>
  //                     )} */}
  //                   </Nav>
  //                 </div>
  //               </div>
  //               <div className="col-12">
  //                 <Tab.Content className="mt-3">
  //                   <Tab.Pane eventKey="first">
  //                     <CardForm />
  //                   </Tab.Pane>
  //                   <Tab.Pane eventKey="second"> </Tab.Pane>
  //                   <Tab.Pane eventKey="upi">3</Tab.Pane>
  //                 </Tab.Content>
  //               </div>
  //             </div>
  //           </Tab.Container>
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  const AddNewCard = () => {
    return (
      <>
        <div className="col-12">
          <div className="payment_main_title mt-5">
            <h3>Other Payment Modes</h3>
          </div>
          <div>
            <MyAccordion />
          </div>
        </div>
      </>
    );
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    if (!user.workingAddress || !user.companyName)
      return toast.info("Please provide billing information first!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    if (total === 0) {
      return toast.info("Please add some item to cart first!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    const { data } = await axios.get(
      ENDPOINT + `subscription/order?country=${geoInfo?.country_code}`,
      {
        headers: { "x-access-token": token },
      }
    );

    const options = {
      key: data.key,
      name: "Maxlence Digital Pvt Ltd",
      description: "Test Transaction",
      // image: { logo },
      order_id: data.order_id,
      handler: async function (response) {
        // toast.success("Payment successfull!", {
        //   position: "bottom-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "colored",
        // });
        navigate("/package-thankyou");
      },
      prefill: {
        name: "Test user",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const handleCreateOrder = async () => {
    if (!user.workingAddress || !user.companyName)
      return toast.info("Please provide billing information first!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    if (total === 0) {
      return toast.info("Please add some item to cart first!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    try {
      const { data } = await axios.get(
        ENDPOINT +
          `subscription/paypal-order?currency=${geoInfo?.currency}&country=${geoInfo?.country_code}`,
        {
          headers: { "x-access-token": token },
        }
      );
      return data.order_id;
    } catch (err) {
      console.log(err);
    }
  };

  const CardForm = () => {
    const [state, setState] = React.useState({
      number: "",
      name: "",
      expiry: "",
      cvv: "",
      type: "",
    });
    const [issuer, setIssuer] = React.useState("");
    const { number, name, expiry, cvv, type } = state;
    const num = number.replaceAll(" ", "");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorExpiryMessage, setErrorExpiryMessage] = React.useState("");

    const handleInputChange = ({ target }) => {
      if (target.name === "number") {
        const { value, issuer, validateCard } = formatCreditCardNumber(
          target.value
        );
        target.value = value === undefined ? "" : value;
        validateCard
          ? setErrorMessage("")
          : setErrorMessage("Invalid card number");
        setIssuer(issuer);
      } else if (target.name === "expiry") {
        const { value, validateExpiry } = formatExpirationDate(target.value);
        target.value = value === undefined ? "" : value;
        validateExpiry
          ? setErrorExpiryMessage("")
          : setErrorExpiryMessage("Invalid expiry date");
      } else if (target.name === "cvv") {
        target.value = formatCVV(target.value);
      }

      setState((old) => {
        return { ...old, [target.name]: target.value };
      });
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      const values = { number: num, name, expiry, cvv, type, issuer };
      if (!user.workingAddress || !user.companyName) {
        return toast.info(
          "Please provide billing information before purchase",
          {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      }
      if (errorMessage !== "" || errorExpiryMessage !== "") {
        return;
      }
      if (total === 0) {
        return toast.info("Please add some item to cart first", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      const payload = {
        ...values,
        checked,
        country: geoInfo?.country_code,
        number: String(values.number),
      };

      try {
        axios({
          url: ENDPOINT + "subscription/eway-form_order",
          method: "POST",
          headers: { "x-access-token": token },
          data: payload,
        })
          .then((res) => {
            if (res.status === 200) {
              // toast.success("Payment successfull!", {
              //   position: "bottom-right",
              //   autoClose: 5000,
              //   hideProgressBar: false,
              //   closeOnClick: true,
              //   pauseOnHover: true,
              //   draggable: true,
              //   progress: undefined,
              //   theme: "colored",
              // });
              navigate("/package-thankyou");
            }
          })
          .catch((err) => {
            if (err.response.status === 403) {
              toast.error("Error while payment!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else if (err.response.status === 401) {
              toast.error("Card already exists!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          });
      } catch (err) {
        toast.error("Server error!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    };

    const Android12Switch = styled(Switch)(({ theme }) => ({
      padding: 8,
      "& .MuiSwitch-track": {
        borderRadius: 22 / 2,
        "&:before, &:after": {
          content: '""',
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          width: 16,
          height: 16,
        },
        "&:before": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
            theme.palette.getContrastText(theme.palette.primary.main)
          )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
          left: 12,
        },
        "&:after": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
            theme.palette.getContrastText(theme.palette.primary.main)
          )}" d="M19,13H5V11H19V13Z" /></svg>')`,
          right: 12,
        },
      },
      "& .MuiSwitch-thumb": {
        boxShadow: "none",
        width: 16,
        height: 16,
        margin: 2,
      },
    }));
    return (
      <div key="Payment">
        <div className="App-payment">
          <form id="my-form" onSubmit={handleSubmit} method="post">
            <div className="row g-3">
              <div className="col-12">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  name="type"
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Card Type</option>
                  <option value="Credit Card">Credit Card</option>
                  <option value="Debit Card">Debit Card</option>
                </select>
              </div>
              <div className="col-12">
                <div className="row g-3">
                  <div className="col-6">
                    <div className="form-group card_input_div">
                      <small>Card Number</small>
                      <div className="card_input_div_input">
                        <img
                          src={
                            issuer === "mastercard"
                              ? Master
                              : issuer === "visa"
                              ? Visa
                              : issuer === "amex"
                              ? Amex
                              : issuer === "jcb"
                              ? Jcb
                              : issuer === "dinersclub"
                              ? Diners
                              : issuer === "discover"
                              ? Discover
                              : issuer === "unionpay"
                              ? UnionPay
                              : Default
                          }
                          alt="img"
                        />
                        <input
                          type="tel"
                          id="card-number"
                          name="number"
                          className="form-control"
                          placeholder="Card Number"
                          pattern="[\d| ]{16,22}"
                          maxLength="19"
                          required
                          value={state.number}
                          onChange={handleInputChange}
                        />
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {errorMessage}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group card_input_div">
                      <small>Name on card</small>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        pattern="[a-z A-Z-]+"
                        required
                        value={state.name}
                        autoComplete="off"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row g-3">
                  <div className="col-6">
                    <div className="form-group card_input_div">
                      <small>Expiration Date</small>
                      <input
                        type="tel"
                        name="expiry"
                        className="form-control"
                        placeholder="Valid Thru"
                        pattern="\d\d/\d\d"
                        required
                        value={state.expiry}
                        onChange={handleInputChange}
                      />
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        {errorExpiryMessage}
                      </span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group card_input_div">
                      <small>CVV</small>
                      <input
                        type="password"
                        name="cvv"
                        className="form-control"
                        placeholder="CVV"
                        pattern="\d{3}"
                        required
                        value={state.cvv}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="my_switch">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Android12Switch
                          checked={checked}
                          onChange={(e) => setChecked(e.target.checked)}
                        />
                      }
                      label="Save this card"
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const handleCardSubmit = (e) => {
    e.preventDefault();
    try {
      if (!user.workingAddress || !user.companyName)
        return toast.info("Please provide billing information first!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      if (total === 0) {
        return toast.info("Please add some item to cart first!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      const payload = {
        ...cardValue,
        cvv: cardCVV,
        checked: false,
        country: geoInfo?.country_code,
        number: String(cardValue.number),
      };

      axios({
        url: ENDPOINT + "subscription/eway-order",
        method: "POST",
        headers: {
          "x-access-token": token,
        },
        data: payload,
      })
        .then((res) => {
          if (res.status === 200) {
            // toast.success("Payment successfull!", {
            //   position: "bottom-right",
            //   autoClose: 5000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            //   theme: "colored",
            // });
            navigate("/package-thankyou");
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Error while payment!", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        });
    } catch (err) {
      console.log(err);
      toast.error("Server error!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const MyAccordion = () => {
    // const [expanded, setExpanded] = React.useState("panel1");

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    const SupportedCurrencies = [
      "AUD",
      "BRL",
      "CAD",
      "CNY",
      "CZK",
      "DKK",
      "EUR",
      "HKD",
      "HUF",
      "ILS",
      "JPY",
      "MYR",
      "MXN",
      "TWD",
      "NZD",
      "NOK",
      "PHP",
      "PLN",
      "GBP",
      "SGD",
      "SEK",
      "CHF",
      "THB",
      "USD",
    ];

    return (
      <>
        <div className="checkout_div">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className={
              expanded === "panel1" ? "checkout_inner_bg" : "checkout_inner_bg1"
            }
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel1" ? (
                  <RadioButtonCheckedIcon className="checkout_inner_icon" />
                ) : (
                  <RadioButtonUncheckedIcon />
                )
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ pt: 1, pb: 1 }}
            >
              <Typography
                component={"span"}
                sx={{ width: "100%", flexShrink: 3, pl: 3 }}
              >
                Credit / Debit Card
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component={"span"}>
                <CardForm />
              </Typography>
            </AccordionDetails>
          </Accordion>
          {geoInfo?.country_code === "IN" ? (
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              className={
                expanded === "panel2"
                  ? "checkout_inner_bg"
                  : "checkout_inner_bg1"
              }
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel2" ? (
                    <RadioButtonCheckedIcon className="checkout_inner_icon" />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )
                }
                aria-controls="panel2bh-content"
                id="panel2bh-header"
                sx={{ pt: 1, pb: 1 }}
              >
                <Typography
                  component={"span"}
                  sx={{ width: "100%", flexShrink: 3, pl: 3 }}
                >
                  Pay with Razorpay
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  component={"span"}
                  sx={{
                    width: "40%",
                    textAlign: "center",
                    flexShrink: 3,
                    pl: 3,
                  }}
                >
                  {/* <CardForm /> */}
                  <BlueBtn
                    onClick={displayRazorpay}
                    title="Buy with Razorpay"
                  />
                </Typography>
              </AccordionDetails>
            </Accordion>
          ) : null}

          {SupportedCurrencies.includes(geoInfo?.currency) ? (
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              className={
                expanded === "panel3"
                  ? "checkout_inner_bg"
                  : "checkout_inner_bg1"
              }
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel3" ? (
                    <RadioButtonCheckedIcon className="checkout_inner_icon" />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )
                }
                aria-controls="panel3bh-content"
                id="panel3bh-header"
                sx={{ pt: 1, pb: 1 }}
              >
                <Typography
                  component={"span"}
                  sx={{ width: "100%", flexShrink: 3, pl: 3 }}
                >
                  Pay with Paypal
                  {/* <img
                  className="my_new_gpay_img"
                  src={GpayImg}
                  alt="GPay icon"
                /> */}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  component={"span"}
                  sx={{
                    width: "40%",
                    textAlign: "center",
                    flexShrink: 3,
                    pl: 3,
                  }}
                >
                  <PayPalScriptProvider
                    options={{
                      "client-id":
                        "AVubD8-ZKW--xJNd1_DEqjNEUN9DOFVwIByo_PIb3At4NH-MX8J3d4zqIqsMYzbYzWhPjuIiSJK6rbCH",
                      // components: "buttons,hosted-fields",
                      // "data-client-token": clientToken,
                      // intent: "capture",
                      // vault: false,
                      currency: geoInfo?.currency,
                    }}
                  >
                    <PayPalButtons
                      style={{ layout: "vertical" }}
                      disabled={false}
                      forceReRender={[cartData]}
                      createOrder={() => {
                        return handleCreateOrder();
                      }}
                      onApprove={(data, actions) => {
                        return actions.order.capture().then((details) => {
                          // toast.success("Payment successfull!", {
                          //   position: "bottom-right",
                          //   autoClose: 5000,
                          //   hideProgressBar: false,
                          //   closeOnClick: true,
                          //   pauseOnHover: true,
                          //   draggable: true,
                          //   progress: undefined,
                          //   theme: "colored",
                          // });
                          navigate("/package-thankyou");
                        });
                      }}
                    />
                  </PayPalScriptProvider>
                </Typography>
              </AccordionDetails>
            </Accordion>
          ) : null}

          {geoInfo?.country_code === "IN" ? null : (
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
              className={
                expanded === "panel4"
                  ? "checkout_inner_bg"
                  : "checkout_inner_bg1"
              }
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel4" ? (
                    <RadioButtonCheckedIcon className="checkout_inner_icon" />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )
                }
                aria-controls="panel4bh-content"
                id="panel4bh-header"
                sx={{ pt: 1, pb: 1 }}
              >
                <Typography
                  component={"span"}
                  sx={{ width: "100%", flexShrink: 3, pl: 3 }}
                >
                  Pay through Bank transfer
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  component={"span"}
                  sx={{
                    width: "40%",
                    textAlign: "center",
                    flexShrink: 3,
                    pl: 3,
                  }}
                >
                  <ul>
                    <li>Maxlence Consulting Pty Ltd </li>
                    <li>BSB - 063010 </li>
                    <li>Account No - 13459602 </li>
                    <li>Bank Name - Commonwealth Bank </li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>
          )}
        </div>
      </>
    );
  };

  const handleBankTransferSubmit = (e) => {
    e.preventDefault();
    try {
      if (!user.workingAddress || !user.companyName)
        return toast.info("Please provide billing information first!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      if (total === 0) {
        return toast.info("Please add some item to cart first!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }

      axios({
        url: ENDPOINT + "subscription/bank-transfer",
        method: "POST",
        headers: {
          "x-access-token": token,
        },
        data: geoInfo?.country_code,
      })
        .then((res) => {
          if (res.status === 200) {
            navigate("/package-thankyou");
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            toast.error("Error while submitting!", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        });
    } catch (err) {
      toast.error("Server error!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log(err);
    }
  };

  return (
    <>
      <section className=" container mt-5 mb-5">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className="row g-4">
          <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-12 col-sm-12 col-12">
            <div className="row">
              <Address />
              {card.length > 0 && <SavedCards />}

              {showCVV ? (
                <>
                  <form onSubmit={handleCardSubmit} id="submit-form">
                    <label>Enter CVV</label>
                    <input
                      type="password"
                      placeholder="CVV"
                      pattern="\d{3}"
                      value={cardCVV}
                      required={!cardCVV}
                      onChange={(e) => setCardCVV(formatCVV(e.target.value))}
                    />
                  </form>
                </>
              ) : null}
            </div>
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-12 col-sm-12 col-12">
            <OrderSummary />
          </div>
          <div className="col-xxl-9 col-xl-9 col-lg-8 col-md-12 col-sm-12 col-12">
            {cardChecked !== false ? null : <AddNewCard />}
            {cardChecked ? (
              <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 checkout_btn">
                <div>
                  <div className="form-actions">
                    <button
                      type="submit"
                      form="submit-form"
                      className=" brn full_btn checkout_btn"
                      // onClick={handleCardSubmit}
                    >
                      Make Payment via Card
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
            {expanded === "panel4" ? (
              <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 checkout_btn">
                <div>
                  <div className="form-actions">
                    <button
                      // type="submit"
                      onClick={(e) => handleBankTransferSubmit(e)}
                      className="brn full_btn checkout_btn"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </section>
    </>
  );
};
