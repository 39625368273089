import { Faqs } from "../components/Faqs";
import { DrawerLeft } from "../components/DrawerLeft";
import { FooterContact } from "../components/FooterContact";
import { NavLink } from "react-router-dom";
import Homehero3 from "../assests/homehero3.png";
import Melblogo from "../assests/partners/melblogo.png";
import Plumbing_gas from "../assests/plumbing_gas.png";
import Tylerlogo from "../assests/partners/tylerlogo.png";
import Velaqualogo from "../assests/partners/velaqualogo.png";
import Saasdash from "../assests/saas_dash.png";
import Saasmobres from "../assests/saasmobres.png";
import Saasdeskres from "../assests/saasdeskres.png";
import Saasflow from "../assests/saasflow.jpg";
import Saasmmdashr from "../assests/saas_mmdashr.png";
import Saasproductdesign from "../assests/saas_productdesign.jpg";

export const Saas = () => {
  return (
    <>
      <div className="container services_modal_page_div">
        <div className="row  justify-content-around align-items-center">
          <div className="col-12 col-md-12 col-lg-7 my-3">
            <p className=" mb-1 fw-bold">SaaS Product Creation</p>
            <h1 className="my-4 all_h1_title">
              SaaS Application- It's time to run your business with real-time
              tracking{" "}
            </h1>
            <div className="py-4">
              <NavLink
                to="/enterprise/saas/questionnaire"
                className="getstartbtn  fontrale my-5"
              >
                Get Started
              </NavLink>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-5 my-2 my-sm-0 my-3 rounded text-center ">
            <img className="w-100" src={Homehero3} alt="img" />
          </div>
        </div>
      </div>
      <div className="container my-5 homepartners">
        <div className="row text-center align-items-center ">
          <div className="col-sm-3 col-6 my-sm-3 wow animate__animated animate__fadeInUp">
            <img src={Melblogo} alt="icon" />
          </div>
          <div className="col-sm-3 col-6 my-sm-3 wow animate__animated animate__fadeInUp">
            <img src={Plumbing_gas} alt="icon" />
          </div>
          <div className="col-sm-3 col-6 my-sm-3 wow animate__animated animate__fadeInUp ">
            <img src={Tylerlogo} alt="icon" />
          </div>
          <div className="col-sm-3 col-6 my-sm-3 wow animate__animated animate__fadeInUp ">
            <img src={Velaqualogo} alt="icon" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row  justify-content-around align-items-center">
          <div className="col-12 col-md-12 col-lg-5 my-3  ">
            <h2 className="my-4 all_h1_title">
              We Build SaaS Products That Scale with Your Company.
            </h2>
            <p className="all_h1_title_p">
              If you’re looking to outsource your SaaS application design &
              development, we can work together to create a roadmap that meets
              your needs. With our experience, we will translate your ideas into
              market-ready software products, taking scalability, robustness,
              and customizability into account.
            </p>
            <p className="all_h1_title_p">
              We build innovative SaaS applications that help businesses
              streamline operations, empower employees, and delight customers.
              Our products are designed to allow you to scale your company,
              keeping your customers at the heart of our product development so
              they enjoy a superior experience.
            </p>
          </div>
          <div className="col-12 col-md-12 col-lg-7 d-flex align-content-end my-2 my-sm-0 my-3 rounded text-center ">
            <img className="w-100" src={Saasdash} alt="icon" />
          </div>
        </div>
      </div>
      <div
        className="container-fluid py-3 latestprojects "
        style={{ backgroundColor: "#0A76E5" }}
      >
        <div className="row justify-content-around align-items-center ">
          <div className="col-md-4 position-relative rounded text-center wow animate__animated animate__fadeInUp ">
            <img className="w-50" src={Saasmobres} alt="img" />
            <div className="sidetext sidetextpos1">
              <p className="">
                <span className="text-white">MOBILE RESPONSIVE</span>{" "}
              </p>
            </div>
          </div>

          <div className="col-md-6 mb-3 mt-0 text-md-right text-center wow animate__animated animate__fadeInUp">
            <h2 className="font-weight-bold text-white my-4 all_h1_title">
              <b>Design & build products that solve real problems </b>
            </h2>
            <p
              style={{ color: "rgb(255, 255, 255, 0.7) " }}
              className="text-start all_h1_title_p"
            >
              Sketch is an iterative process. We always start with creating an
              attractive visual concept, which is then refined until we're ready
              to start Sketching. But it doesn't stop there: we'll show you lots
              of design directions and the reasons behind them so that you can
              make informed decisions about your product.
            </p>
            <p
              style={{ color: "rgb(255, 255, 255, 0.7) " }}
              className="text-start all_h1_title_p"
            >
              Thus, with SaaS application, you're not only getting an entire
              software suite at your fingertips but also the ability to stay
              connected. All this is because SaaS is a cloud-based solution that
              works across a variety of devices, making it accessible anywhere.
            </p>

            <div className="my-5  py-3 position-relative">
              <div className="sidetext sidetextpos2">
                <p>
                  <span className="text-white">DESKTOP</span>
                </p>
              </div>
              <img
                className="w-100"
                style={{ marginRight: "-5rem" }}
                src={Saasdeskres}
                alt="img"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container py-3 my-5">
        <div className="row  justify-content-around align-items-center">
          <div className="col-12 col-md-12 col-lg-7 my-3">
            <p className=" mb-1 fw-bold">Design should speak for itself </p>
            <h1 className="my-4 all_h1_title">
              With the Right people on our platform!{" "}
            </h1>
            <p className="all_h1_title_p" style={{ color: "#212529B2" }}>
              When you’re trying to convert a prospective customer into a real,
              paying customer, design can be the difference between a sale and
              no sale. As soon as we understand what people need and want, we
              know how best to present them with an experience that makes it
              easy for them to understand and appreciate the value of your
              product.
              <br />
              <br />
              Our team of skilled designers and developers understands the
              importance of a seamless user experience, and we use our knowledge
              and experience to create products that convert prospects into
              paying customers. Trust us to deliver an exceptional design that
              showcases the value of your product and drives sales for your
              business.
            </p>
          </div>
          <div className="col-12 col-md-12 col-lg-5 my-2 my-sm-0 my-3 rounded text-center ">
            <img className="w-100" src={Saasflow} alt="img" />
          </div>
        </div>
      </div>
      <div
        className="container-fluid mt-1 py-3"
        style={{ background: "#E2F0FF" }}
      >
        <div className="row justify-content-center py-5">
          <div className="col-12 text-center wow animate__animated animate__fadeInDown">
            <h2 className="text-dark all_h1_title">
              Our workflow for creating SaaS applications{" "}
            </h2>
          </div>
          <div className="my-3 text-center col-10">
            <p className="all_h1_title_p">
              We take pride in our methodical SaaS development workflow that
              delivers on schedule, with a robust architecture, and strong
              frameworks. Let us help take your business to new heights with our
              top-notch SaaS application design and development services.
            </p>
          </div>
        </div>
      </div>
      <div className="container mmdash">
        <div className="row my-3 py-4">
          <div className="col-lg-5">
            <img className="w-100" src={Saasmmdashr} alt="img" />
          </div>
          <div className="col-lg-6">
            <div className="mmdashbullet my-3">
              <div className="row my-3 justify-content-around align-content-start">
                <div className="col-2">
                  <div>
                    <h2>1</h2>
                  </div>
                </div>
                <div className="col-10">
                  <h3>
                    <b>Discover </b>
                  </h3>
                  <p>
                    Our team of researchers and business strategists will work
                    with you to investigate your current gaps, develop a
                    business value proposition and operational core that align
                    with users' needs, and define the steps required to get
                    there.
                  </p>
                </div>
              </div>
            </div>
            <div className="mmdashbullet my-3">
              <div className="row my-3 justify-content-around align-content-start">
                <div className="col-2">
                  <div>
                    <h2>2</h2>
                  </div>
                </div>
                <div className="col-10">
                  <h3>
                    <b>Design</b>
                  </h3>
                  <p>
                    We design beautiful user interfaces that are easy to
                    navigate and aesthetically appealing. Our designers use
                    proven UI trends in order to create a consistent user flow
                    across your app.
                  </p>
                </div>
              </div>
            </div>
            <div className="mmdashbullet my-3">
              <div className="row my-3 justify-content-around align-content-start">
                <div className="col-2">
                  <div>
                    <h2>3</h2>
                  </div>
                </div>
                <div className="col-10">
                  <h3>
                    <b>Build</b>
                  </h3>
                  <p>
                    We know that building a successful SaaS solution is a
                    labor-intensive process, so we aim to create a durable and
                    flexible system that can grow along with your business. Our
                    team of technical professionals has the knowledge and
                    experience required to build your custom SaaS application.
                  </p>
                </div>
              </div>
            </div>
            <div className="mmdashbullet my-3">
              <div className="row my-3 justify-content-around align-content-start">
                <div className="col-2">
                  <div>
                    <h2>4</h2>
                  </div>
                </div>
                <div className="col-10">
                  <h3>
                    <b>Deliver</b>
                  </h3>
                  <p>
                    Our developers rigorously test the whole platform to ensure
                    that it provides a pleasant customer experience and meets
                    the demands of our clients. Our customers have peace of mind
                    because, when we say we are reliable and secure, we are!
                  </p>

                  <div className="py-4">
                    <NavLink
                      to="/enterprise/saas/questionnaire"
                      className="getstartbtn  fontrale my-5"
                    >
                      Get Started
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-3 py-3 saas_prodesign">
        <div className="row">
          <div className="text-center">
            <div>
              <h2 className="all_h1_title">
                Full Product Re-Design with real-time Insights{" "}
              </h2>
            </div>
            <div className="my-3">
              <p className="all_h1_title_p">
                Take your SaaS product to the next level and take control of
                your data with our customizable performance dashboard solution.
                We could help an e-commerce platform incorporate new features
                like virtual try-ons and personalized recommendations, resulting
                in increased sales and customer loyalty.
              </p>
            </div>
          </div>
        </div>
        <div
          className="row justify-content-around my-4 p-5"
          style={{ backgroundColor: "#000" }}
        >
          <div className="col-lg-5 my-3">
            <h2 className="text-white all_h1_title">
              Discuss the Saas Product!{" "}
            </h2>
            <p
              className="my-4 all_h1_title_p"
              style={{ color: "rgba(255, 255, 255, 0.704) " }}
            >
              Your customers are your product. We’ll help you discover the key
              experience improvements that will increase loyalty and engagement
              with your software. We do this through wireframes and
              high-fidelity visual design mockups, as well as user testing, to
              identify usability issues at each stage of development or
              redesign.
            </p>
          </div>
          <div className="col-lg-5 my-3">
            <img
              className="w-100"
              src={Saasproductdesign}
              alt="saas_productdesign"
            />
          </div>
        </div>
      </div>
      <Faqs catId={20} />
      <FooterContact title="We’d love to be your partner"/>
    </>
  );
};
