import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Header } from "../src/Modules/Maxlence/components/Navbar";
import { Footer } from "../src/Modules/Maxlence/components/Footer";
// import HelmetMetaData from "./Modules/Maxlence/pages/components/core/HelmetMetaData/HelmetMetaData.js";
import ScrollToTop from "react-scroll-to-top";
import { ActiveNav } from "./Modules/Maxlence/components/ActiveNav";
import { Wrapper } from "./Wrapper";
import { FaChevronUp } from "react-icons/fa";
import RoutesList from "./routes.js";

export const App = () => {
  return (
    <>
      {/* <HelmetMetaData></HelmetMetaData> */}
      <Router>
        <Wrapper>
          <Header />
          <ActiveNav />
          <div className="scroll_to_top">
            <ScrollToTop component={<FaChevronUp />} smooth />
          </div>
          <Routes>
            {RoutesList.map((route, i) => (
              <Route key={i} {...route} />
            ))}
          </Routes>
          <Footer />
        </Wrapper>
      </Router>
    </>
  );
};
