import {styled} from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import React, {useState, useCallback} from 'react';
import Master from '../../../img/master.png';
import Visa from '../../../img/visa.png';
import Amex from '../../../img/amex.png';
import Jcb from '../../../img/jcb.png';
import Diners from '../../../img/diners.png';
import UnionPay from '../../../img/union-pay.png';
import Discover from '../../../img/discover.png';
import Default from '../../../img/default-card.png';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {loadStripe} from '@stripe/stripe-js';
import {EmbeddedCheckoutProvider, EmbeddedCheckout} from '@stripe/react-stripe-js';

import {PayPalScriptProvider} from '@paypal/react-paypal-js';

import {formatCreditCardNumber, formatCVV, formatExpirationDate} from './utils';

import 'react-credit-cards/es/styles-compiled.css';
import ENDPOINT from '../config/ENDPOINT';
import axios from 'axios';
import {BlueBtn} from '../components/Btn';
import {useSelector} from 'react-redux';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);


export const SubscriptionPayment = () => {
  const {payment_token} = useParams();
  const [card, setCard] = React.useState([]);
  const [repaymentData, setRepaymentData] = useState({});
  // const [clientToken, setClientToken] = useState(null);
  const [token, setToken] = useState('');
  const [cardChecked, setCardChecked] = useState(false);
  const [checked, setChecked] = useState(true);
  const [cardValue, setCardValue] = useState({});
  const navigate = useNavigate();
  const [showCVV, setShowCVV] = useState(false);
  const [cardCVV, setCardCVV] = useState('');
  const user = useSelector(state => state.state.user);
  const geoInfo = useSelector(state => state.state.geoInfo);
  const [gst, setGst] = useState(0);
  const [gstAmount, setGstAmount] = useState(0);
  const [expanded, setExpanded] = React.useState('panel1');

  const fetchClientSecret = useCallback(() => {
    const URL =
      ENDPOINT + `repayment/subscription/${payment_token}?country=${geoInfo?.country_code}`;
    // Create a Checkout Session
    return axios.get(URL).then(({data}) => {
      setRepaymentData(data?.sub_data);
      return data.clientSecret;
    });
  }, []);

  const options = {fetchClientSecret};

  const OrderSummary = () => {
    return (
      <>
        <div className="order_summary_div">
          <div className="order_summary_div_title">
            Order summary
            <span>{1}</span>
            <hr />
          </div>
          <div className="order_summary_details_div">
            <p style={{color: '#212529', fontWeight: '660'}}>{repaymentData?.UserName}</p>
            <p>Services: {repaymentData?.ServicesNames}</p>
            <div className="order_summary_price_div">
              <b>{repaymentData?.TotalAmount}</b>
            </div>
            <hr />
          </div>
          {/* payment button */}
          <div>
            <div className="row g-2">
              <div className="col-12">
                <div className="row mt-3">
                  <div
                    style={{
                      fontSize: '12px',
                    }}>
                    <input
                      type="checkbox"
                      name="isAgree"
                      // checked={repaymentData?.isAgree}
                      checked={true}
                      style={{marginRight: 5}}
                      onClick={e =>
                        setRepaymentData(old => ({
                          ...old,
                          isAgree: e.target.checked,
                        }))
                      }
                    />
                    By continue, you accept the{' '}
                    <NavLink to="/terms_&_conditions">Terms of Use</NavLink> and acknowledge reading
                    the <NavLink to="/privacy_policy">Privacy Policy</NavLink> . You also agree to
                    auto renewal of your yearly subscription for ₹6,000.00, which can be disabled at
                    any time through your account. In accordance with RBI guidelines, your card
                    details will be saved securely for future purchases and subscription renewals.
                    An e-mandate will be created for a maximum amount of ₹15,000, but you’ll only be
                    charged the amount of your purchase.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const AddNewCard = () => {
    return (
      <>
        <div className="col-12">
          <div className="payment_main_title mt-5">
            <h3>Other Payment Modes</h3>
          </div>
          <div>
            <MyAccordion />
          </div>
        </div>
      </>
    );
  };

  function loadScript(src) {
    return new Promise(resolve => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
    if (!res) return alert('Razorpay SDK failed to load. Are you online?');
    const URL = ENDPOINT + `repayment/ra_order?country=${geoInfo?.country_code}`;

    axios
      .post(URL, {
        payment_token,
      })
      .then(({data}) => {
        if (data.status == 'OK') {
          const options = {
            key: data.key,
            name: 'Maxlence Digital Pvt Ltd',
            description: 'Repayment Transaction',
            // image: { logo },
            order_id: data.order_id,
            handler: async function (response) {
              console.log(response);
              toast.success('Payment successfull!', {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
              });
              navigate('/repayment-thankyou', {
                // state: response.data,
              });
            },
            prefill: {
              // name: "Test user",
            },
            theme: {
              color: '#61dafb',
            },
          };

          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        }
      });
  }

  const handleCreateOrder = async () => {
    try {
      const {data} = await axios.get(
        ENDPOINT +
          `repayment/paypal-order?currency=${geoInfo?.currency}&country=${geoInfo?.country_code}`,
        {
          headers: {'x-access-token': token},
        },
      );
      return data.order_id;
    } catch (err) {
      console.log(err);
    }
  };

  const CardForm = () => {
    const [state, setState] = React.useState({
      number: '',
      name: '',
      expiry: '',
      cvv: '',
      type: '',
    });
    const [issuer, setIssuer] = React.useState('');
    const {number, name, expiry, cvv, type} = state;
    const num = number.replaceAll(' ', '');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [errorExpiryMessage, setErrorExpiryMessage] = React.useState('');

    const handleInputChange = ({target}) => {
      if (target.name === 'number') {
        const {value, issuer, validateCard} = formatCreditCardNumber(target.value);
        target.value = value === undefined ? '' : value;
        validateCard ? setErrorMessage('') : setErrorMessage('Invalid card number');
        setIssuer(issuer);
      } else if (target.name === 'expiry') {
        const {value, validateExpiry} = formatExpirationDate(target.value);
        target.value = value === undefined ? '' : value;
        validateExpiry ? setErrorExpiryMessage('') : setErrorExpiryMessage('Invalid expiry date');
      } else if (target.name === 'cvv') {
        target.value = formatCVV(target.value);
      }

      setState(old => {
        return {...old, [target.name]: target.value};
      });
    };

    const handleSubmit = e => {
      //   return console.log(payment_token);
      e.preventDefault();
      const values = {number: num, name, expiry, cvv, type, issuer};
      if (errorMessage !== '' || errorExpiryMessage !== '') {
        return;
      }

      const payload = {
        ...values,
        checked,
        country: geoInfo?.country_code,
        number: String(values.number),
        payment_token,
      };

      try {
        axios({
          url: ENDPOINT + 'repayment/eway-order',
          method: 'POST',
          data: payload,
        })
          .then(res => {
            if (res.status === 200) {
              toast.success('Payment successfull!', {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
              });
              navigate('/repayment-thankyou', {
                state: res.data,
              });
            }
          })
          .catch(err => {
            if (err.response.status === 403) {
              toast.error('Error while payment!', {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
              });
            } else if (err.response.status === 401) {
              toast.error('Card already exists!', {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
              });
            } else
              toast.error('Error while payment, Please try again with another card!', {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
              });
          });
      } catch (err) {
        toast.error('Server error!', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    };

    const Android12Switch = styled(Switch)(({theme}) => ({
      padding: 8,
      '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&:before, &:after': {
          content: '""',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          width: 16,
          height: 16,
        },
        '&:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
            theme.palette.getContrastText(theme.palette.primary.main),
          )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
          left: 12,
        },
        '&:after': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
            theme.palette.getContrastText(theme.palette.primary.main),
          )}" d="M19,13H5V11H19V13Z" /></svg>')`,
          right: 12,
        },
      },
      '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
      },
    }));
    return (
      <div key="Payment">
        <div className="App-payment">
          <form id="my-form" onSubmit={handleSubmit} method="post">
            <div className="row g-3">
              <div className="col-12">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  name="type"
                  onChange={handleInputChange}
                  required>
                  <option value="">Select Card Type</option>
                  <option value="Credit Card">Credit Card</option>
                  <option value="Debit Card">Debit Card</option>
                </select>
              </div>
              <div className="col-12">
                <div className="row g-3">
                  <div className="col-6">
                    <div className="form-group card_input_div">
                      <small>Card Number</small>
                      <div className="card_input_div_input">
                        <img
                          src={
                            issuer === 'mastercard'
                              ? Master
                              : issuer === 'visa'
                              ? Visa
                              : issuer === 'amex'
                              ? Amex
                              : issuer === 'jcb'
                              ? Jcb
                              : issuer === 'dinersclub'
                              ? Diners
                              : issuer === 'discover'
                              ? Discover
                              : issuer === 'unionpay'
                              ? UnionPay
                              : Default
                          }
                          alt="img"
                        />
                        <input
                          type="tel"
                          id="card-number"
                          name="number"
                          className="form-control"
                          placeholder="Card Number"
                          pattern="[\d| ]{16,22}"
                          maxLength="19"
                          required
                          value={state.number}
                          onChange={handleInputChange}
                        />
                        <span
                          style={{
                            fontWeight: 'bold',
                            color: 'red',
                          }}>
                          {errorMessage}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group card_input_div">
                      <small>Name on card</small>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        pattern="[a-z A-Z-]+"
                        required
                        value={state.name}
                        autoComplete="off"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row g-3">
                  <div className="col-6">
                    <div className="form-group card_input_div">
                      <small>Expiration Date</small>
                      <input
                        type="tel"
                        name="expiry"
                        className="form-control"
                        placeholder="Valid Thru"
                        pattern="\d\d/\d\d"
                        required
                        value={state.expiry}
                        onChange={handleInputChange}
                      />
                      <span
                        style={{
                          fontWeight: 'bold',
                          color: 'red',
                        }}>
                        {errorExpiryMessage}
                      </span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group card_input_div">
                      <small>CVV</small>
                      <input
                        type="password"
                        name="cvv"
                        className="form-control"
                        placeholder="CVV"
                        pattern="\d{3}"
                        required
                        value={state.cvv}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="my_switch">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Android12Switch
                          checked={checked}
                          onChange={e => setChecked(e.target.checked)}
                        />
                      }
                      label="Save this card"
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const handleCardSubmit = e => {
    e.preventDefault();
    try {
      const payload = {
        ...cardValue,
        cvv: cardCVV,
        checked: false,
        country: geoInfo?.country_code,
        number: String(cardValue.number),
        payment_token,
      };

      axios({
        url: ENDPOINT + 'repayment/eway-order',
        method: 'POST',
        data: payload,
      })
        .then(res => {
          if (res.status === 200) {
            toast.success('Payment successfull!', {
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            });
            navigate('/repayment-thankyou', {
              state: res.data,
            });
          }
        })
        .catch(err => {
          if (err.response.status === 401) {
            toast.error('Error while payment!', {
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            });
          } else
            toast.error('Error while payment, Please try again with another card!', {
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            });
        });
    } catch (err) {
      console.log(err);
      toast.error('Server error!', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const MyAccordion = () => {
    // const [expanded, setExpanded] = React.useState("panel1");

    const handleChange = panel => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    const SupportedCurrencies = [
      'AUD',
      'BRL',
      'CAD',
      'CNY',
      'CZK',
      'DKK',
      'EUR',
      'HKD',
      'HUF',
      'ILS',
      'JPY',
      'MYR',
      'MXN',
      'TWD',
      'NZD',
      'NOK',
      'PHP',
      'PLN',
      'GBP',
      'SGD',
      'SEK',
      'CHF',
      'THB',
      'USD',
    ];

    return (
      <>
        <div className="checkout_div">
          <Accordion
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
            className={expanded === 'panel1' ? 'checkout_inner_bg' : 'checkout_inner_bg1'}>
            <AccordionSummary
              expandIcon={
                expanded === 'panel1' ? (
                  <RadioButtonCheckedIcon className="checkout_inner_icon" />
                ) : (
                  <RadioButtonUncheckedIcon />
                )
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{pt: 1, pb: 1}}>
              <Typography component={'span'} sx={{width: '100%', flexShrink: 3, pl: 3}}>
                Credit / Debit Card
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component={'span'}>
                <CardForm />
              </Typography>
            </AccordionDetails>
          </Accordion>
          {geoInfo?.country_code === 'IN' ? (
            <Accordion
              expanded={expanded === 'panel2'}
              onChange={handleChange('panel2')}
              className={expanded === 'panel2' ? 'checkout_inner_bg' : 'checkout_inner_bg1'}>
              <AccordionSummary
                expandIcon={
                  expanded === 'panel2' ? (
                    <RadioButtonCheckedIcon className="checkout_inner_icon" />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )
                }
                aria-controls="panel2bh-content"
                id="panel2bh-header"
                sx={{pt: 1, pb: 1}}>
                <Typography component={'span'} sx={{width: '100%', flexShrink: 3, pl: 3}}>
                  Pay with Razorpay
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  component={'span'}
                  sx={{
                    width: '40%',
                    textAlign: 'center',
                    flexShrink: 3,
                    pl: 3,
                  }}>
                  {/* <CardForm /> */}
                  <BlueBtn
                    disabled={!repaymentData?.isAgree}
                    style={{
                      cursor: repaymentData?.isAgree ? 'pointer' : 'not-allowed',
                    }}
                    onClick={displayRazorpay}
                    title="Pay with Razorpay"
                  />
                </Typography>
              </AccordionDetails>
            </Accordion>
          ) : null}

          {SupportedCurrencies.includes(geoInfo?.currency) && false ? (
            <Accordion
              expanded={expanded === 'panel3'}
              onChange={handleChange('panel3')}
              className={expanded === 'panel3' ? 'checkout_inner_bg' : 'checkout_inner_bg1'}>
              <AccordionSummary
                expandIcon={
                  expanded === 'panel3' ? (
                    <RadioButtonCheckedIcon className="checkout_inner_icon" />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )
                }
                aria-controls="panel3bh-content"
                id="panel3bh-header"
                sx={{pt: 1, pb: 1}}>
                <Typography component={'span'} sx={{width: '100%', flexShrink: 3, pl: 3}}>
                  Pay with Paypal
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  component={'span'}
                  sx={{
                    width: '40%',
                    textAlign: 'center',
                    flexShrink: 3,
                    pl: 3,
                  }}>
                  <PayPalScriptProvider
                    options={{
                      'client-id':
                        'AVubD8-ZKW--xJNd1_DEqjNEUN9DOFVwIByo_PIb3At4NH-MX8J3d4zqIqsMYzbYzWhPjuIiSJK6rbCH',
                      // components: "buttons,hosted-fields",
                      // "data-client-token": clientToken,
                      // intent: "capture",
                      // vault: false,
                      currency: geoInfo?.currency,
                    }}>
                    {/* <PayPalButtons
                      style={{ layout: "vertical" }}
                      disabled={false}
                      forceReRender={[cartData]}
                      createOrder={() => {
                        return handleCreateOrder();
                      }}
                      onApprove={(data, actions) => {
                        return actions.order.capture().then((details) => {
                          // toast.success("Payment successfull!", {
                          //   position: "bottom-right",
                          //   autoClose: 5000,
                          //   hideProgressBar: false,
                          //   closeOnClick: true,
                          //   pauseOnHover: true,
                          //   draggable: true,
                          //   progress: undefined,
                          //   theme: "colored",
                          // });
                          navigate("/repayment-thankyou");
                        });
                      }}
                    /> */}
                  </PayPalScriptProvider>
                </Typography>
              </AccordionDetails>
            </Accordion>
          ) : null}

          {geoInfo?.country_code === 'IN' ? null : (
            <Accordion
              expanded={expanded === 'panel4'}
              onChange={handleChange('panel4')}
              className={expanded === 'panel4' ? 'checkout_inner_bg' : 'checkout_inner_bg1'}>
              <AccordionSummary
                expandIcon={
                  expanded === 'panel4' ? (
                    <RadioButtonCheckedIcon className="checkout_inner_icon" />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )
                }
                aria-controls="panel4bh-content"
                id="panel4bh-header"
                sx={{pt: 1, pb: 1}}>
                <Typography component={'span'} sx={{width: '100%', flexShrink: 3, pl: 3}}>
                  Pay through Bank transfer
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  component={'span'}
                  sx={{
                    width: '40%',
                    textAlign: 'center',
                    flexShrink: 3,
                    pl: 3,
                  }}>
                  <ul>
                    <li>Maxlence Consulting Pty Ltd </li>
                    <li>BSB - 063010 </li>
                    <li>Account No - 13459602 </li>
                    <li>Bank Name - Commonwealth Bank </li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>
          )}
        </div>
      </>
    );
  };

  const handleBankTransferSubmit = e => {
    e.preventDefault();
    return navigate('/repayment-thankyou', {
      state: {
        mode: 'bank-transfer',
        Amount: repaymentData?.TotalAmount,
      },
    });
    try {
      axios({
        url: ENDPOINT + 'repayment/bank-transfer',
        method: 'POST',
        headers: {
          'x-access-token': token,
        },
        data: geoInfo?.country_code,
      })
        .then(res => {
          if (res.status === 200) {
            navigate('/repayment-thankyou', {
              state: res.data,
            });
          }
        })
        .catch(err => {
          if (err.response.status === 404) {
            toast.error('Error while submitting!', {
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            });
          }
        });
    } catch (err) {
      toast.error('Server error!', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      console.log(err);
    }
  };

  return (
    <>
      <section className=" container mt-5 mb-5">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className="row g-4" style={{justifyContent: 'center'}}>
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div id="checkout">
              <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
