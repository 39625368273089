import Doubleclicks from "../assests/doubleclicks.png";
import Seosersearch from "../assests/seosersearch.png";
import Seoserdisplay from "../assests/seoserdisplay.png";
import Seoservideo from "../assests/seoservideo.png";
import Seosershop from "../assests/seosershop.png";
import Gadspaidsearch from "../assests/gadspaidsearch.png";
import { NavLink } from "react-router-dom";
import Gadsdisplayad from "../assests/gadsdisplayad.jpg";
import Gadsvideoad from "../assests/gadsvideoad.jpg";
import Gadsshop from "../assests/gadsshop.jpg";
import Gadsclient from "../assests/gadsclient.png";
import Bottomfadestroke from "../assests/bottomfadestroke.png";
import { FooterContact } from "../components/FooterContact";

import GoogleAdsIcon from "../../../img/GoogleAdsIcon.svg";
import EmailMarketingIcon from "../../../img/EmailMarketingIcon.svg";
import VideoMarketingIcon from "../../../img/VideoMarketingIcon.svg";
import GMBIcon from "../../../img/GMBIcon.svg";
import SEOIcon from "../../../img/SEOIcon.svg";
import SocialMediaIcon from "../../../img/SocialMediaIcon.svg";
import CROIcon from "../../../img/CROIcon.svg";
import { Faqs } from "../components/Faqs";

const TopLinkData = [
  {
    linkName: "Google Ads",
    linkIcon: GoogleAdsIcon,
    link: "/growth/google-ads",
    activeNav: "block",
    linkNameColor: "black",
  },
  {
    linkName: "CRO",
    linkIcon: CROIcon,
    link: "/growth/cro",
    activeNav: "none",
    linkNameColor: "",
  },
  {
    linkName: "Social Media",
    linkIcon: SocialMediaIcon,
    link: "/growth/social-media-marketing",
    activeNav: "none",
    linkNameColor: "",
  },
  {
    linkName: "SEO",
    linkIcon: SEOIcon,
    link: "/growth/seo",
    activeNav: "none",
    linkNameColor: "",
  },
  {
    linkName: "GMB",
    linkIcon: GMBIcon,
    link: "/growth/gmb",
    activeNav: "none",
    linkNameColor: "",
  },
  {
    linkName: "Video Marketing",
    linkIcon: VideoMarketingIcon,
    link: "/growth/video-marketing",
    activeNav: "none",
    linkNameColor: "",
  },
  {
    linkName: "Email Marketing",
    linkIcon: EmailMarketingIcon,
    link: "/growth/email-marketing",
    activeNav: "none",
    linkNameColor: "",
  },
];

export const GoogleAds = () => {
  return (
    <>
      <div className=" creation_and_growth_top_div mb-2">
        <div className=" container">
          <div className="growth_top_div">
            {TopLinkData.map((val, i) => (
              <div key={i} className=" creation_and_growth_col_div">
                <NavLink to={val.link}>
                  <img src={val.linkIcon} alt="icon" />
                  <br />
                  <span style={{ color: val.linkNameColor }}>
                    {val.linkName}
                  </span>
                </NavLink>
                <div
                  style={{ display: val.activeNav }}
                  className="creation_and_growth_activ"
                ></div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="container-fluid py-sm-5 py-3 mb-5 grleads bgproperty services_modal_page_div">
        <div className="container py-sm-5">
          <div
            className="row py-sm-3 my-2 justify-content-end align-items-center"
            style={{ position: "relative" }}
          >
            <div className="col-sm-6 col-12 my-3">
              <h1 className="all_h1_title font-weight-bold my-4 text-md-right text-center wow animate__animated animate__fadeInDown">
                Unlock Explosive Growth: <br /> Get <span>10x more leads</span>{" "}
                and <span>double</span> the conversion rates
              </h1>
              <div className="pt-4 text-right wow animate__animated animate__fadeInUp">
                <div className="d-flex justify-content-between">
                  <div>
                    <img src={Doubleclicks} alt="icon" />
                  </div>
                  <div>
                    <p className="font-weight-bold">
                      Choose a{" "}
                      <span style={{ color: "#088b43" }}>monthly budget</span>{" "}
                      as big or as small as you like
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div>
                    <img src={Doubleclicks} alt="icon" />
                  </div>
                  <div>
                    <p className="font-weight-bold">
                      Smash your{" "}
                      <span style={{ color: "rgb(202, 199, 3" }}>KPI’s</span>{" "}
                      across all{" "}
                      <span style={{ color: "rgb(235, 1, 1" }}>
                        Google platforms
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-4 ml-n4 d-none d-md-block position-absolute"
              style={{ bottom: "-45%", right: "15%" }}
            >
              <div
                className="py-sm-5 py-3 px-1 px-sm-2 text-center"
                style={{
                  backgroundColor: "#f5f5f5",
                  border: "5px solid white",
                }}
              >
                <h2>Specialised In</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container seoservices my-5 py-sm-5">
        <div className="row justify-content-center text-center wow animate__animated animate__fadeIn animate__slow">
          <div className="col-sm-3 col-6 my-2">
            <img src={Seosersearch} className="m-2" alt="icon" />
            <h6>
              <b>Search Campaigns</b>
            </h6>
          </div>
          <div className="col-sm-3 col-6 my-2">
            <img src={Seoserdisplay} className="m-2" alt="icon" />
            <h6>
              <b>Display Campaigns</b>
            </h6>
          </div>
          <div className="col-sm-3 col-6 my-2">
            <img src={Seoservideo} className="m-2" alt="icon" />
            <h6>
              <b>Video Campaigns</b>
            </h6>
          </div>
          <div className="col-sm-3 col-6 my-2">
            <img src={Seosershop} className="m-2" alt="icon" />
            <h6>
              <b>Shopping</b>
            </h6>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row justify-content-around align-items-center">
          <div className="col-12 my-3 text-center">
            <h2 className="my-4 all_h1_title">
              Stop Wasting Ad Spend: Our Expert Team Can Optimize Your Ad
              Placements
            </h2>
            <p className="all_h1_title_p" style={{ color: "#212529b2" }}>
              Say goodbye to wasted ad spend and hello to maximise your ROI with
              our proven advertising strategies. But our google paid ads
              services don't end there. We also provide a personalised client
              dashboard at your fingertips that let you track your progress and
              make data-driven decisions based on real-time campaign
              performance. Our team of experts can help you make every dollar
              count. Get in touch with us now!
            </p>
          </div>
        </div>
      </div>
      <div
        className="container-fluid py-3 latestprojects"
        style={{ backgroundColor: "#0a76e5" }}
      >
        <div className="container py-4">
          <div className="row justify-content-around align-items-center">
            <div className="col-md-6 text-white">
              <h2 className="mb-5 all_h1_title">Paid Search Advertising </h2>
              <p
                className="all_h1_title_p"
                style={{ color: "rgba(255, 255, 255, 0.7)" }}
              >
                Imagine you're a dental clinic in Sydney, struggling to attract
                new patients. You know your services are top-notch, but how do
                you get the word out to potential patients in your area? This is
                where our agency comes in. By partnering with us, we'll work
                closely with your team to use advanced targeting techniques to
                reach the right people at the right time.
                <br />
                <br />
                Our personalized dashboard allows you to track your calls and
                growth in real-time, ensuring full transparency and data-driven
                decision making. Don't wait - let's get started on your targeted
                search advertising campaign today.
              </p>
              <div className="getstarted my-3 pt-4 border-0 wow animate__animated animate__fadeInUp">
                <NavLink
                  to="/get-in-touch"
                  className="whitebgbtn bg-white fontrale"
                >
                  Get In Touch ⟶
                </NavLink>
              </div>
            </div>
            <div className="col-md-6">
              <img className="w-100" src={Gadspaidsearch} alt="icon" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row my-md-5 py-md-5 my-2 py-2 justify-content-between">
          <div className="col-md-6 order-md-1 order-sm-2 order-2 my-2">
            <img className="w-100 mb-4" src={Gadsdisplayad} alt="i" />
          </div>
          <div className="col-md-6 text-right order-md-2 order-sm-1 order-1 my-2">
            <h2 className="mb-4 all_h1_title">Display Advertising</h2>
            <p className="all_h1_title_p">
              The construction industry is highly competitive, but our display
              advertising services can help you cut through the noise and reach
              your target audience. By placing targeted ads on websites and
              blogs, our team will increase your brand awareness and drive more
              traffic to your site.
            </p>
            <p className="all_h1_title_p">
              And with our personalized dashboard, you can track your campaign's
              success and make data-driven decisions to optimize your ad spend
              and maximize your ROI. Don't miss out on potential customers – let
              us help you reach them with our expert display advertising
              services.
            </p>
          </div>
        </div>

        <div className="row my-md-5 py-md-5 my-2 py-2 justify-content-between">
          <div className="col-md-6 text-left">
            <h2 className="mb-4 all_h1_title">Video Ads</h2>
            <p className="all_h1_title_p">
              Video ads are the rock stars of the advertising world! They can
              captivate an Aussie audience, make a genuine connection with
              potential customers, and leave a lasting impression. Whether
              you're in e-commerce or the fitness and wellness industry, with
              Aussie becoming increasingly health-conscious, there's never been
              a better time to promote your classes, equipment, or services.
            </p>
            <p className="all_h1_title_p">
              And what better way to do that than with a dynamic video ad that
              inspires, motivates, and leaves viewers wanting more? With our
              unparalleled ability to connect with audiences and create brand
              recall, we’re the ultimate advertising asset that can help you
              stand out from the crowd.
            </p>
          </div>

          <div className="col-md-5">
            <img className="w-100 mb-4" src={Gadsvideoad} alt="i" />
          </div>
        </div>

        <div className="row my-md-5 py-md-5 my-2 py-2 justify-content-between">
          <div className="col-md-5 order-md-1 order-sm-2 order-2">
            <img className="w-100 mb-4" src={Gadsshop} alt="i" />
          </div>
          <div className="col-md-6 text-right order-md-2 order-sm-1 order-1 my-2">
            <h2 className="mb-4 all_h1_title">Google Shopping Ads</h2>
            <p className="all_h1_title_p">
              Attention! Attention! Online businesses in Australia! Are you
              struggling to turn website visitors into paying customers? Look no
              further than shopping ads! Our team will help you set up, manage,
              and optimise your shopping campaigns so that you can increase
              sales by promoting your inventory. We do this by optimising and
              organising product stock listings, organising product listing ads,
              and targeting the correct keywords.
            </p>

            <div className="text-right py-4">
              <NavLink
                className="getstartbtn fontrale my-2"
                to="/growth/google-ads/questionnaire"
              >
                Get Started
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-3 py-3 gads_clientquote">
        <div
          className="row justify-content-around my-4 p-5"
          style={{ backgroundColor: "#000" }}
        >
          <div className="row">
            <div className="text-center">
              <div>
                <h2 className="text-white">Our Clients Love The Results</h2>
              </div>
              <div className="my-3">
                <img src={Gadsclient} alt="i" />
              </div>
              <div className="client_compname">
                <p className="text-white mb-0">John Doe, Lorem Pvt Ltd</p>
                <img src={Bottomfadestroke} alt="i" />
              </div>
              <div>
                <h3 className="my-4" style={{ color: "#0a76e5" }}>
                  217% Increase in Targeted Traffic
                </h3>
              </div>
              <div>
                <p
                  className="all_h1_title_p"
                  style={{ color: "rgba(255, 255, 255, 0.7) " }}
                >
                  It is very clear the impact your team is making. You guys are
                  doing a great job on targeted leads. In fact, we closed a lead
                  directly through your marketing efforts last year that has
                  resulted in over half a million dollars in revenue!”
                </p>
                <div className="text-center py-4">
                  <NavLink
                    to="/growth/google-ads/questionnaire"
                    className="getstartbtn fontrale my-2"
                  >
                    Get Started
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterContact title="We’d love to be your partner"/>
      <Faqs catId={6} />
    </>
  );
};
