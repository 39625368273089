import { NavLink, useLocation } from "react-router-dom";
import Glass from "../../../img/glass.png";
import CartImg from "../../../img/cart.png";
import { Badge } from "react-bootstrap";
import MailIcon from "@mui/icons-material/Mail";
import { useEffect, useRef, useState } from "react";
export const ActiveNav = () => {
  const [query, setQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const ref = useRef(null);
  const [open, setOpen] = useState(true);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
      setQuery("");
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
      setOpen(true);
    };
  });

  const handleSearch = (e) => {
    const results = pagesArray.filter((item) => {
      return item.title.toLowerCase().includes(e.target.value.toLowerCase());
    });
    setQuery(e.target.value);
    setSearchResults(results);
  };

  const pagesArray = [
    {
      url: "/creation/website",
      title: "Website",
    },
    {
      url: "/creation/website-redesign",
      title: "Website Redesign",
    },
    {
      url: "/mobile-app",
      title: "Mobile App",
    },
    {
      url: "/saas",
      title: "SaaS",
    },
    {
      url: "/creation/hosting",
      title: "Hosting",
    },
    {
      url: "/creation/maintenance",
      title: "Maintenance",
    },
    {
      url: "/growth/google-ads",
      title: "Google Ads",
    },
    {
      url: "/growth/social-media-marketing",
      title: "Social Media Marketing",
    },
    {
      url: "/growth/seo",
      title: "Seo",
    },
    {
      url: "/growth/email-marketing",
      title: "Email Marketing",
    },
    {
      url: "/growth/video-marketing",
      title: "Video Marketing",
    },
    {
      url: "/growth/cro",
      title: "CRO",
    },
    {
      url: "/growth/Gmb",
      title: "GMB",
    },
    {
      url: "/growth",
      title: "Growth",
    },
    {
      url: "/design",
      title: "Design",
    },
    {
      url: "/shop",
      title: "Shop",
    },
    {
      url: "/about-us",
      title: "About us",
    },
    {
      url: "/get-in-touch",
      title: "Contact us",
    },
    {
      url: "/business/business1",
      title: "Business 1",
    },
    {
      url: "/business/business2",
      title: "Business 2",
    },
    {
      url: "/resources/design-inspiration",
      title: "Design Inspiration",
    },
    {
      url: "/resources/brochure",
      title: "Brochure",
    },
    {
      url: "/resources/case-study",
      title: "Case Study",
    },
    {
      url: "/resources/blogs",
      title: "Blogs",
    },
    {
      url: "/enterprise/seo-service",
      title: "Seo Service",
    },
    {
      url: "/enterprise/google-ads-service",
      title: "Google Ads Service",
    },
    {
      url: "/enterprise/social-media-service",
      title: "Social media Service",
    },
    {
      url: "/solutions/api-integration",
      title: "API integration",
    },
    {
      url: "/solutions/form-tracker",
      title: "Form Tracker",
    },
    {
      url: "/solutions/review-tracker",
      title: "Review Tracker",
    },
    {
      url: "/solutions/agencies",
      title: "Agencies",
    },
    {
      url: "/support",
      title: "Support",
    },
    {
      url: "/privacy-policy",
      title: "Privacy Policy",
    },
    {
      url: "/refund-policy",
      title: "Refund Policy",
    },
    {
      url: "/terms-&-conditions",
      title: "Terms and Conditions",
    },
    {
      url: "/cart",
      title: "Cart",
    },
    {
      url: "/careers",
      title: "Carreers",
    },
  ];
  const location = useLocation();
  const paths = location.pathname.split("/").filter((x) => x);

  const breadcrumbs = [];
  paths.map((p, index) => {
    let str = p.replace(/_|-/g, " ");
    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    breadcrumbs.push({
      title: (str = arr.join(" ")),
      link: `/${paths.slice(0, index + 1).join("/")}`,
    });
  });

  return (
    <>
      <div id="breadcrumbscontent">
        <div
          className="container-fluid p-2"
          style={{ backgroundColor: "#E2F0FF" }}
        >
          <div className="container pr-0">
            <div className="row justify-content-between align-items-center">
              <div className="breadcrumbs text-center col-5 d-sm-flex d-none">
                <small className="breadcrumbroot">
                  <NavLink to="/">Home</NavLink>
                </small>
                {breadcrumbs &&
                  breadcrumbs.map((b, index) =>
                    index !== breadcrumbs.length - 1 ? (
                      <small key={index} className="breadcrumbsec breadactive">
                        <NavLink to={b.link}>{b.title}</NavLink>
                      </small>
                    ) : (
                      <small key={index} className="breadcrumbsec breadactive">
                        {b.title}
                      </small>
                    )
                  )}
              </div>
              <div className="icons col-sm-5 col-12 d-flex justify-content-sm-end justify-content-center align-items-center">
                <div style={{ position: "relative" }}>
                  <div className="input-group justify-content-around align-items-center">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <input
                        ref={ref}
                        type="search"
                        value={query}
                        onChange={handleSearch}
                        placeholder="Search Here"
                        aria-describedby="button-addon1"
                        className="form-control navsearch rounded-pill  fontrale border-0 bg-light "
                      />
                      {query.length && open ? (
                        <div
                          style={{
                            background: "white",
                            borderRadius: "5px",
                            padding: "5px",
                            position: "absolute",
                            top: "37px",
                            left: 0,
                            zIndex: 5,
                            width: "80%",
                          }}
                        >
                          <ul>
                            {searchResults.length ? (
                              searchResults.map((res) => {
                                return (
                                  <NavLink to={res.url}>
                                    <li key={res.title}>{res.title}</li>
                                  </NavLink>
                                );
                              })
                            ) : (
                              <li>No Result found</li>
                            )}
                          </ul>
                        </div>
                      ) : null}
                    </div>
                    <br />
                    <div className="input-form-append">
                      <button type="submit" className="active_nav_my">
                        <img className="searchnavicon" src={Glass} alt="" />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="carticon mx-2">
                  <NavLink to="/cart">
                    <img src={CartImg} alt="Glass" />
                  </NavLink>
                  {/* <Badge badgeContent={4} >
                    <MailIcon color="action" />
                  </Badge> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
