import Youtubecamp from "../assests/youtubecamp.jpg";
import Planscheckicon from "../assests/planscheckicon.png";
import Pageseo from "../assests/pageseo.jpg";
import socialmediamarketing from "../assests/socialmediamarketing.jpg";
import Emailmarketing from "../assests/emailmarketing.jpg";
import Videocamptool1 from "../assests/videocamptool1.png";
import Videocamptool2 from "../assests/videocamptool2.png";
import Videocamptool3 from "../assests/videocamptool3.png";
import Videocamptool4 from "../assests/videocamptool4.png";
import Videocamptool5 from "../assests/videocamptool5.png";
import Videocamptool6 from "../assests/videocamptool6.png";
import Downloadicon from "../assests/downloadicon.png";
import Spreadcamp from "../assests/spreadcamp.jpg";
import { Faqs } from "../components/Faqs";
import { useState, useEffect } from "react";
import ENDPOINT from "../config/ENDPOINT";
import axios from "axios";
import { NavLink, useParams } from "react-router-dom";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { setOverallPlanId, setRecentlyViewed } from "../redux/modalStateSlice";
import { ShopPackageCard } from "../components/ShopPackageCard";

export const ProductDetails = () => {
  const [product, setProduct] = useState({ desc: "" });
  const [packages, setPackages] = useState([]);
  const { slug } = useParams();
  const dispatch = useDispatch();
  const geoInfo = useSelector((state) => state.state.geoInfo);

  useEffect(() => {
    fetchSingleProductBySlug();
  }, []);

  useEffect(() => {
    fetchPackagesByCat();
  }, [product, geoInfo]);

  const fetchSingleProductBySlug = async () => {
    const { data } = await axios.get(ENDPOINT + `product/single/${slug}`);
    setProduct(data);
  };
  // const fetchPackagesByCat = async () => {
  //   let { data } = await axios.get(ENDPOINT + `overall-plan/all-plan/${slug}`);
  //   setPackages(data);
  // };

  const fetchPackagesByCat = async () => {
    let res = await fetch(
      ENDPOINT + `shop-package/${product?.id}?country=${geoInfo?.country_code}`
    );
    if (res.ok) {
      let data = await res.json();
      setPackages(data);
    }
  };

  // useEffect(() => {
  //   if (product?.img) dispatch(setRecentlyViewed(product));
  // }, [product]);

  const YoutubecampApp = () => {
    return (
      <>
        <div className="container  my-sm-4 py-sm-5 py-0 my-0  videocamphero">
          <div className="row justify-content-around align-items-start">
            <div className="col-sm-4 my-3">
              <img
                className="w-100"
                src={ENDPOINT + "uploads/" + product?.img}
                alt="img"
              />
            </div>
            <div className="col-sm-6 my-3">
              <h1>{product?.title}</h1>
              {parse(product?.desc)}
            </div>
          </div>
        </div>
      </>
    );
  };
  const Flexible = () => {
    return (
      <>
        <div
          className="container-fluid py-4 py-sm-1"
          style={{ background: "#f5f5f5" }}
        >
          <h1 className="text-center pt-sm-5 p-3">
            <span>Flexible</span> Plans
          </h1>
          <div className="container">
            <div className="row py-4 d-flex justify-content-around">
              {packages.map((plan, key) => {
                return (
                  <div
                    key={key}
                    className="shadow-lg col-sm-3 my-3 py-3 bg-white"
                  >
                    <div className="d-flex justify-content-around align-items-center text-center">
                      <h3>{plan.title}</h3>
                      <h1>
                        <span>${plan.price}</span>
                      </h1>
                    </div>
                    <div className="logosubcard py-4 bg-white text-dark text-center">
                      <ul
                        className="list-unstyled"
                        style={{ textAlign: "left" }}
                      >
                        {JSON.parse(plan.features).map((val, i) => {
                          return (
                            <li key={i} className="lead">
                              <img
                                src={Planscheckicon}
                                alt=""
                                className="mx-3"
                              />
                              {val}
                            </li>
                          );
                        })}
                      </ul>
                      <div className="getstarted mt-5 wow animate__animated animate__fadeInUp">
                        <NavLink
                          className="whitebgbtn fontrale wow animate__animated animate__delay-1s animate__slow animate__bounceIn"
                          to="/shop-form"
                          onClick={() => dispatch(setOverallPlanId(plan.id))}
                        >
                          Choose Plan
                        </NavLink>
                      </div>
                    </div>
                  </div>
                );
              })}

              {/* <div className="shadow-lg col-sm-3 my-3 py-3 bg-white">
                <div className="d-flex justify-content-around align-items-center text-center">
                  <h3>Monthly</h3>
                  <h1>
                    <span>$40</span>
                  </h1>
                </div>
                <div className="logosubcard py-4 bg-white text-dark text-center">
                  <ul className="list-unstyled " style={{ textAlign: "left" }}>
                    <li className="lead">
                      <img src={Planscheckicon} alt="" className="mx-3" />
                      10 Marketing Posts
                    </li>
                    <li className="lead">
                      <img src={Planscheckicon} alt="" className="mx-3" />
                      Free Mockups
                    </li>
                    <li className="lead">
                      <img src={Planscheckicon} alt="" className="mx-3" />
                      Final Formats (PNG, JPEG)
                    </li>
                    <li className="lead">
                      <img src={Planscheckicon} alt="" className="mx-3" />
                      Lorem ipsum dolor sit amet
                    </li>
                    <li className="lead">
                      <img src={Planscheckicon} alt="" className="mx-3" />
                      Lorem, ipsum dolor.
                    </li>
                  </ul>
                  <div className="getstarted mt-5 wow animate__animated animate__fadeInUp">
                    <a
                      className="whitebgbtn fontrale wow animate__animated animate__delay-1s animate__slow animate__bounceIn"
                      href="#"
                    >
                      Choose Plan
                    </a>
                  </div>
                </div>
              </div>
  
              <div className="shadow-lg col-sm-3 my-3 py-3 bg-white">
                <div className="d-flex justify-content-around align-items-center text-center">
                  <h3>Monthly</h3>
                  <h1>
                    <span>$40</span>
                  </h1>
                </div>
                <div className="logosubcard py-4 bg-white text-dark text-center">
                  <ul className="list-unstyled " style={{ textAlign: "left" }}>
                    <li className="lead">
                      <img src={Planscheckicon} alt="" className="mx-3" />
                      10 Marketing Posts
                    </li>
                    <li className="lead">
                      <img src={Planscheckicon} alt="" className="mx-3" />
                      Free Mockups
                    </li>
                    <li className="lead">
                      <img src={Planscheckicon} alt="" className="mx-3" />
                      Final Formats (PNG, JPEG)
                    </li>
                    <li className="lead">
                      <img src={Planscheckicon} alt="" className="mx-3" />
                      Lorem ipsum dolor sit amet
                    </li>
                    <li className="lead">
                      <img src={Planscheckicon} alt="" className="mx-3" />
                      Lorem, ipsum dolor.
                    </li>
                  </ul>
                  <div className="getstarted mt-5 wow animate__animated animate__fadeInUp">
                    <a
                      className="whitebgbtn fontrale wow animate__animated animate__delay-1s animate__slow animate__bounceIn"
                      href="#"
                    >
                      Choose Plan
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
  };
  const VestibulumPretium = () => {
    return (
      <>
        <div className="container my-5 py-3">
          <h3>{product?.section_one_title}</h3>

          <p>{parse(product?.section_one_content || "")}</p>
        </div>
      </>
    );
  };
  const RecentPurchase = () => {
    return (
      <>
        <div
          className="container-fluid py-sm-4 py-3"
          style={{ background: "#f5f5f5" }}
        >
          <div className="container homeshops my-sm-4 py-sm-3 py-3 my-3 videocampintro">
            <h3
              className="font-weight-bold mb-4 wow animate__ animate__fadeInDown animated"
              style={{ visibility: "visible", animationName: "fadeInDown" }}
            >
              Frequently Purchased
            </h3>

            <div className="row py-3">
              <div className="div featcamp col-sm-3 col-6">
                <div className="seoshop shopfeat wow animate__animated animate__fadeIn animate__slow">
                  <img className="w-100" src={Pageseo} alt="" />
                </div>
                <p className="mt-2 mt-sm-3 mb-1">
                  <b>On Page SEO</b>
                </p>
                <p>
                  <span>
                    <b>$123</b>
                  </span>
                </p>
              </div>

              <div className="div featcamp col-sm-3 col-6">
                <div className="seoshop shopfeat wow animate__animated animate__fadeIn animate__slow">
                  <img className="w-100" src={Youtubecamp} alt="" />
                </div>
                <p className="mt-2 mt-sm-3 mb-1">
                  <b>Video Campaign</b>
                </p>
                <p>
                  <span>
                    <b>$123</b>
                  </span>
                </p>
              </div>

              <div className="div featcamp col-sm-3 col-6">
                <div className="seoshop shopfeat wow animate__animated animate__fadeIn animate__slow">
                  <img className="w-100" src={socialmediamarketing} alt="" />
                </div>
                <p className="mt-2 mt-sm-3 mb-1">
                  <b>Organic Instagram Marketing</b>
                </p>
                <p>
                  <span>
                    <b>$123</b>
                  </span>
                </p>
              </div>

              <div className="div featcamp col-sm-3 col-6">
                <div className="seoshop shopfeat wow animate__animated animate__fadeIn animate__slow">
                  <img className="w-100" src={Emailmarketing} alt="" />
                </div>
                <p className="mt-2 mt-sm-3 mb-1">
                  <b>Email Marketing</b>
                </p>
                <p>
                  <span>
                    <b>$123</b>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const CampaignWork = () => {
    return (
      <>
        <div className="container my-sm-4 py-sm-3 py-3 my-3">
          <div className="campworkhead py-3 my-3 text-center">
            <h3>Why Our Campaigns Work</h3>
            <p>We use the best tools</p>
          </div>

          <div className="row justify-content-around">
            <div className="col-sm-3 m-3 text-center campaignwork wow animate__animated animate__fadeInUp animate__delay-1s">
              <div className="videocamptoolicon campworkicon">
                <img src={Videocamptool1} alt="Videocamptool1" />
              </div>
              <div className="videocamptooltitle campworktitle my-3">
                <h6>
                  <b>Seo</b>
                </h6>
              </div>
              <div className="videocamptooltext campworktext">
                <small>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Corrupti expedita dolor quam laborum nostrum placeat!.
                </small>
              </div>
            </div>

            <div className="col-sm-3 m-3 text-center campaignwork wow animate__animated animate__fadeInUp animate__delay-1s">
              <div className="videocamptoolicon campworkicon">
                <img src={Videocamptool2} alt="videocamptool2" />
              </div>
              <div className="videocamptooltitle campworktitle my-3">
                <h6>
                  <b>Competitive Research</b>
                </h6>
              </div>
              <div className="videocamptooltext campworktext">
                <small>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Corrupti expedita dolor quam laborum nostrum placeat!.
                </small>
              </div>
            </div>

            <div className="col-sm-3 m-3 text-center campaignwork wow animate__animated animate__fadeInUp animate__delay-1s">
              <div className="videocamptoolicon campworkicon">
                <img src={Videocamptool3} alt="" />
              </div>
              <div className="videocamptooltitle campworktitle my-3">
                <h6>
                  <b>Site Audit</b>
                </h6>
              </div>
              <div className="videocamptooltext campworktext">
                <small>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Corrupti expedita dolor quam laborum nostrum placeat!.
                </small>
              </div>
            </div>

            <div className="col-sm-3 m-3 text-center campaignwork wow animate__animated animate__fadeInUp animate__delay-1s">
              <div className="videocamptoolicon campworkicon">
                <img src={Videocamptool4} alt="videocamptool4" />
              </div>
              <div className="videocamptooltitle campworktitle my-3">
                <h6>
                  <b>Keywords</b>
                </h6>
              </div>
              <div className="videocamptooltext campworktext">
                <small>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Corrupti expedita dolor quam laborum nostrum placeat!.
                </small>
              </div>
            </div>

            <div className="col-sm-3 m-3 text-center campaignwork wow animate__animated animate__fadeInUp animate__delay-1s">
              <div className="videocamptoolicon campworkicon">
                <img src={Videocamptool5} alt="videocamptool5" />
              </div>
              <div className="videocamptooltitle campworktitle my-3">
                <h6>
                  <b>Ads</b>
                </h6>
              </div>
              <div className="videocamptooltext campworktext">
                <small>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Corrupti expedita dolor quam laborum nostrum placeat!.
                </small>
              </div>
            </div>

            <div className="col-sm-3 m-3 text-center campaignwork wow animate__animated animate__fadeInUp animate__delay-1s">
              <div className="videocamptoolicon campworkicon">
                <img src={Videocamptool6} alt="videocamptool6" />
              </div>
              <div className="videocamptooltitle campworktitle my-3">
                <h6>
                  <b>Analysis</b>
                </h6>
              </div>
              <div className="videocamptooltext campworktext">
                <small>
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Corrupti expedita dolor quam laborum nostrum placeat!.
                </small>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const Brochure = () => {
    return (
      <>
        <div className="container-fluid mystyle py-4 mt-4">
          <div className="container text-center py-4 text-white">
            <div className="mt-0">
              <h1>
                <b> {product?.title}</b>
              </h1>
            </div>
            <div className="row mt-sm-3 justify-content-center align-items-center py-3">
              <div className="col-md-3 my-2 px-0 vendorbenifits">
                <p className="text-white border-right border-xs-right-none">
                  Search Campaign
                </p>
              </div>

              <div className="col-md-3 my-2 px-0 vendorbenifits">
                <p className="text-white border-right border-xs-right-none">
                  Display Campaign
                </p>
              </div>

              <div className="col-md-3 my-2 px-0 vendorbenifits">
                <p className="text-white border-right border-xs-right-none">
                  Video Campaign
                </p>
              </div>

              <div className="col-md-3 my-2 px-0 vendorbenifits">
                <p className="text-white">Shopping</p>
              </div>
            </div>
          </div>

          <div className="text-center mb-4">
            <a
              className="whitebgbtn fontrale text-white border-white"
              href="assests/pdfdemo.pdf"
              download="Google Ads Brochure"
            >
              Download &nbsp; <img src={Downloadicon} alt="downloadicon" />
            </a>
          </div>
        </div>
      </>
    );
  };
  const SpreadingCampaign = () => {
    return (
      <>
        <div
          className="container-fluid p-3"
          style={{ backgroundColor: "#f5f5f5" }}
        >
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-sm-5 my-3 text-sm-right text-center wow animate__animated animate__fadeInUp animate__slow">
                <h1 className="font-weight-bold my-3">
                  <b>{product?.camp_one_title}</b>
                </h1>
                <p>{parse(product?.camp_one_content || "")}</p>
                {product?.camp_one_url && (
                  <div className="mt-5">
                    <a
                      className="ctabtn viewjectbtn fontpop"
                      href={product?.camp_one_url}
                      target="_blank"
                    >
                      <small>
                        <b>View Project</b>
                      </small>
                    </a>
                  </div>
                )}
              </div>

              <div className="col-sm-6 py-3 rounded my-2 text-center wow animate__animated animate__fadeInUp animate__slow">
                <img
                  className="w-100"
                  src={
                    product?.camp_one_img
                      ? ENDPOINT + "uploads/" + product?.camp_one_img
                      : Spreadcamp
                  }
                  alt="Spreadcamp"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="container-fluid p-3"
          style={{ backgroundColor: "#f5f5f5" }}
        >
          <div className="container">
            <div className="row justify-content-around align-items-center">
              <div className="col-sm-6 rounded text-center order-md-2 order-sm-1 order-1 wow animate__animated animate__fadeInUp animate__slow">
                <img
                  className="w-100"
                  src={
                    product?.camp_two_img
                      ? ENDPOINT + "uploads/" + product?.camp_two_img
                      : Spreadcamp
                  }
                  alt="Spreadcamp"
                />
              </div>

              <div className="col-sm-6 my-2 text-center text-sm-left wow animate__animated animate__fadeInUp animate__slow">
                <h2 className="font-weight-bold my-sm-4 wow animate__animated animate__fadeInUp animate__slow">
                  {product?.camp_two_title}
                </h2>
                <p className="my-sm-4 wow animate__animated animate__fadeInUp animate__slow">
                  {parse(product?.camp_two_content || "")}
                </p>
                {product?.camp_two_url && (
                  <div className="getstarteds mt-5 wow animate__animated animate__fadeInUp">
                    <a
                      className="whitebgbtn fontrale"
                      target="_blank"
                      href={product?.camp_two_url}
                    >
                      Learn More
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <YoutubecampApp />
      {/* <Flexible /> */}
      <ShopPackageCard packages={packages} />
      {product?.section_one_title && product?.section_one_content ? (
        <VestibulumPretium />
      ) : null}

      <RecentPurchase />
      <CampaignWork />
      <Brochure />
      {product?.camp_one_title &&
      product?.camp_one_content &&
      product?.camp_one_img &&
      product?.camp_two_title &&
      product?.camp_two_content &&
      product?.camp_two_img ? (
        <SpreadingCampaign />
      ) : null}

      <Faqs catId={6} />
    </>
  );
};
