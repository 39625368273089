import { useEffect, useState } from "react";
import Photo from "../../../img/th.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { BlueLinkBtn, OutlineBtn } from "../components/Btn";
import ENDPOINT from "../config/ENDPOINT";
import { useLocation } from "react-router-dom";
export const ThankYou = () => {
  const location = useLocation();
  const [payment, setPayment] = useState([]);
  const navigate = useNavigate();

  const fetchSingleAgreementPayment = async () => {
    const { data } = await axios.get(
      ENDPOINT + `agreement_payment/${location?.state?.email}`
    );
    if (data) setPayment(data);
    else navigate("/");
  };
  useEffect(() => {
    if (!location?.state?.email) navigate("/");
    else fetchSingleAgreementPayment();
  }, []);

  const handlePdfDownload = async () => {
    let payload = localStorage.getItem("agreement_form_data");
    payload = JSON.parse(payload);
    axios
      .post(ENDPOINT + "agreement_pdf", {
        data: payload,
      })
      .then((response) => {
        const filePath = response.data;
        window.open(filePath, "_blank");
      });
  };

  return (
    <>
      <section className=" container py-5">
        <div className="maintenance_provide_title_top_div">
          <div className="maintenance_provide_title_div mb-0">
            <h3>Thank You!</h3>
          </div>
        </div>
        <div className=" text-center">
          <img
            className="maintenance_Satisfaction_img"
            src={Photo}
            alt="photo"
          />
        </div>
        <div className="maintenance_provide_title_top_div">
          <div className="maintenance_provide_title_div">
            {payment?.order_id ? (
              <p className=" text-capitalize">
                Payment received, {payment?.name} <br />
                We've received your payment of{" "}
                <strong>
                  {payment?.agreement_order?.razorpay_order_id
                    ? "Rs"
                    : location?.state?.currency}{" "}
                  {payment.amount}
                </strong>{" "}
                (Order ID: {payment?.order_id}).
              </p>
            ) : (
              <p className=" text-capitalize">
                Thank you for placing your order. We would like to inform you
                that your order is currently in pending mode. <br /> To complete
                the payment process, kindly transfer the amount of{" "}
                <strong>{payment.amount}</strong> to the following bank details:{" "}
                <br />{" "}
                <ul style={{ fontWeight: "600", color: "#1a73e8" }}>
                  <li>Maxlence Consulting Pty Ltd </li>
                  <li>BSB - 063010 </li>
                  <li>Account No - 13459602 </li>
                  <li>Bank Name - Commonwealth Bank </li>
                </ul>{" "}
                <br />
                Once the transfer is done, please send us the screenshot of the
                transaction to{" "}
                <span style={{ textTransform: "lowercase" }}>
                  support@maxlence.com.au
                </span>{" "}
                for verification.
              </p>
            )}
          </div>
        </div>
        <div className=" mt-4 text-center d-flex justify-content-center align-items-center">
          <div style={{ width: "20%" }}>
            <BlueLinkBtn title="Go to home" link="/" />
          </div>
          <div style={{ width: "20%" }}>
            <OutlineBtn
              onClick={handlePdfDownload}
              className=" brn "
              title="Download Agreement"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export const EmptyUi = (props) => (
  <section className=" container py-5">
    <div className="maintenance_provide_title_top_div">
      <div className="maintenance_provide_title_div mb-0">
        <h3>{props.title}</h3>
      </div>
    </div>
    <div className=" text-center">
      <img
        className="maintenance_Satisfaction_img2"
        src={props.img}
        alt="photo"
      />
    </div>
    <div className="maintenance_provide_title_top_div">
      <div className="maintenance_provide_title_div">
        <p className=" text-capitalize">{props.text}</p>
      </div>
    </div>
    <div className=" mt-4 text-center">
      <BlueLinkBtn title={props.btnName} link={props.btnLink} />
    </div>
  </section>
);
