import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { OutlineBtn, BlueBtn } from "../components/Btn";
import HeaderLogo from "../../../img/HeaderLogo.svg";
import Modal from "react-bootstrap/Modal";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { TextAreaBox } from "../components/form";
import { LoginSocialFacebook } from "reactjs-social-login";
import { FacebookLoginButton } from "react-social-login-buttons";
import ENDPOINT from "../config/ENDPOINT";
import AccountMenu from "./AccountMenu";
import { handleExpiredUser } from "../HelperFunction/Helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowLoginModal,
  setUser,
  resetUser,
  setShowLoginModalTrue,
  setGeoInfo,
} from "../redux/modalStateSlice";
import { setShowSignUpModal } from "../redux/modalStateSlice";
import { setShowMenuModal } from "../redux/modalStateSlice";
import { setShowForgotModal } from "../redux/modalStateSlice";
import { setShowCheckYourEmailModal } from "../redux/modalStateSlice";
import { setShowPasswordResetModal } from "../redux/modalStateSlice";
import { setNewPasswordModal, setUserEmail } from "../redux/modalStateSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Header = () => {
  const navigate = useNavigate();
  const showLoginModal = useSelector((state) => state.state.showLoginModal);
  const showSignUpModal = useSelector((state) => state.state.showSignUpModal);
  const showMenuModal = useSelector((state) => state.state.showMenuModal);
  const showForgotModal = useSelector((state) => state.state.showForgotModal);
  const showCheckYourEmailModal = useSelector(
    (state) => state.state.showCheckYourEmailModal
  );
  const showPasswordResetModal = useSelector(
    (state) => state.state.showPasswordResetModal
  );
  const showNewPasswordModal = useSelector(
    (state) => state.state.showNewPasswordModal
  );
  const showToken = useSelector((state) => state.state.token.token);
  const userEmail = useSelector((state) => state.state.userEmail);

  const user = useSelector((state) => state.state.user);
  const dispatch = useDispatch();

  ////////////////////// Sign Up Modal //////////////////////////////
  let token = localStorage.getItem("token");

  React.useEffect(() => {
    if (token) {
      try {
        axios
          .get(ENDPOINT + "local", {
            headers: {
              "x-access-token": token,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              dispatch(setUser(res.data));
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
            } else if (err.response.status === 405) {
              handleExpiredUser();
              dispatch(resetUser());
            }
          });
      } catch (err) {
        console.log(err);
      }
    }
  }, [token]);

  React.useEffect(() => {
    try {
      axios
        .get("https://ipapi.co/json/")
        .then((res) => {
          let data = res.data;
          if (res.status === 200) dispatch(setGeoInfo(data));
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const SignUpmodal = () => {
    const [values, setValues] = useState({
      fullname: "",
      email: "",
      password: "",
      confirmPassword: "",
    });

    const handleCreate = (e) => {
      e.preventDefault();
      try {
        axios
          .post(ENDPOINT + "user/signup", values)
          .then((res) => {
            if (res.status === 200) {
              toast.success("User created successfully", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              dispatch(setShowLoginModal());
            }
          })
          .catch((err) => {
            if (err.response.status === 400) {
              toast.error("Password doesn't match or Email already exists!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else if (err.response.status === 401) {
              toast.success("No User created", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          });
      } catch (err) {
        toast.success("Server error!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log("Create error", err);
      }
    };

    const handleInputChange = (e) => {
      const name = e.target.name;
      const value = e.target.value;

      setValues({
        ...values,
        [name]: value,
      });
    };

    return (
      <>
        {token ? (
          <AccountMenu />
        ) : (
          <OutlineBtn
            title="Log In"
            icon=""
            onClick={() => dispatch(setShowLoginModal())}
          />
        )}

        <Modal
          animation={true}
          className="signupmodal fullwidthmodal"
          show={showSignUpModal}
          onHide={() => dispatch(setShowSignUpModal())}
        >
          <Modal.Header closeButton>
            <Link
              to="/home"
              onClick={() => {
                dispatch(setShowSignUpModal());
              }}
            >
              <div className=" ">
                <img width="100" src={HeaderLogo} alt="companylogo" />
              </div>
            </Link>
          </Modal.Header>
          <Modal.Body className="p-0">
            <div className="row h-100">
              <div className="col-md-3 signleft">
                <div className="contact-info">
                  <h1 className="text-light">
                    Let’s Craft <br /> Brilliance
                  </h1>
                </div>
              </div>

              <div className="col-md-9 signright">
                <Container>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row d-flex flex-column justify-content-center align-items-center ">
                        <div className="col-6 my-4 signformstart text-center">
                          <h1 className="signtitle">Sign Up</h1>
                        </div>
                        <div className="col-md-6">
                          <form onSubmit={handleCreate} method="post">
                            <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
                              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <TextAreaBox
                                  type="text"
                                  label="Full Name"
                                  name="fullname"
                                  value={values.fullname}
                                  onChange={handleInputChange}
                                  required
                                />
                              </div>
                              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <TextAreaBox
                                  type="email"
                                  label="Email"
                                  name="email"
                                  value={values.email}
                                  onChange={handleInputChange}
                                  required
                                />
                              </div>
                              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <TextAreaBox
                                  type="password"
                                  label="Password"
                                  name="password"
                                  value={values.password}
                                  onChange={handleInputChange}
                                  required
                                />
                              </div>
                              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <TextAreaBox
                                  type="password"
                                  label="Confirm Password"
                                  name="confirmPassword"
                                  value={values.confirmPassword}
                                  onChange={handleInputChange}
                                  required
                                />
                              </div>
                              <div className="col-xxl-4 mx-auto col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                <BlueBtn type="submit" title="Submit" />
                              </div>
                            </div>
                          </form>
                        </div>

                        <div className="text-center popuplink my-2">
                          <p>
                            Already have an account ? &nbsp;
                            <span
                              onClick={() => {
                                dispatch(setShowSignUpModal());
                                dispatch(setShowLoginModal());
                              }}
                            >
                              Login
                            </span>
                          </p>
                          <p>
                            <span>*</span> By clicking "Sign In", you agree to
                            Maxlence Consulting’s Terms of Service and Privacy
                            Policy
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Container>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  };
  ////////////////////// Login Modal //////////////////////////////

  const LoginModal = () => {
    const [values, setValues] = useState({
      email: "",
      password: "",
    });

    const handleCreate = (e) => {
      e.preventDefault();
      try {
        axios
          .post(ENDPOINT + "user/login", values)
          .then((res) => {
            if (res.status === 200) {
              toast.success("Login Successfull", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              dispatch(setShowLoginModal());
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("user", JSON.stringify(res.data.user));
            }
          })
          .catch((err) => {
            if (err.response.status === 400) {
              toast.error("Wrong Password!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else if (err.response.status === 404) {
              toast.error("No User found!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          });
      } catch (err) {
        toast.error("Server error!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log("Login error", err);
      }
    };

    const handleInputChange = (e) => {
      const name = e.target.name;
      const value = e.target.value;

      setValues({
        ...values,
        [name]: value,
      });
    };

    const handleCredentialResponse = async (response) => {
      const token = response.credential;

      var urlencoded = new URLSearchParams();
      urlencoded.append("token", token);

      let headers = new Headers();
      headers.append("Content-Type", "application/x-www-form-urlencoded");

      try {
        axios({
          url: ENDPOINT + "auth/google/login",
          method: "POST",
          headers,
          data: urlencoded,
        })
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              toast.success("Login successfull", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("user", JSON.stringify(res.data.user));
              window.location.reload();
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              toast.error("Error while creating new User!!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else if (err.response.status === 500) {
              toast.error("Server Error!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          });
      } catch (err) {
        console.log("Error logging in: " + err);
      }
    };

    useEffect(() => {
      window.google?.accounts?.id.initialize({
        client_id:
          "964224499636-4cc1a1pgi20iu1aghba4rk4435m6qfn1.apps.googleusercontent.com",
        callback: handleCredentialResponse,
      });
      window.google?.accounts?.id.renderButton(
        document.querySelector(".googleicon"),
        {
          theme: "outline",
          size: "large",
        }
      );
      if (!token) window.google?.accounts?.id.prompt();
    }, []);

    const handleFbResponse = async (res) => {
      const token = res.data.accessToken;

      var form = new URLSearchParams();
      form.append("token", token);

      let headers = new Headers();
      headers.append("Content-Type", "application/x-www-form-urlencoded");

      try {
        axios({
          url: ENDPOINT + "auth/facebook/login",
          method: "POST",
          headers,
          data: form,
        })
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              toast.success("Login Successfull", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              localStorage.setItem("token", res.data.token);
              localStorage.setItem("user", JSON.stringify(res.data.user));
              dispatch(setShowLoginModal());
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              toast.error("Server Error!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else if (err.response.status === 500) {
              toast.error("Server Error!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          });
      } catch (err) {
        console.log("Error logging in: " + err);
      }
    };

    return (
      <>
        <Modal
          className="loginmodal fullwidthmodal"
          show={showLoginModal}
          onHide={() => dispatch(setShowLoginModal())}
        >
          <Modal.Header closeButton>
            <Link to="/home">
              <div className="logo">
                <img width="100" src={HeaderLogo} alt="companylogo" />
              </div>
            </Link>
          </Modal.Header>
          <Modal.Body className="p-0">
            <div className="row h-100">
              <div className="col-md-3 loginleft">
                <div className="contact-info">
                  <h1 className="text-light text-center">
                    Let’s Craft Brilliance
                  </h1>
                </div>
              </div>

              <div className="col-md-9 loginright">
                <Container>
                  <div className="row">
                    <div className="col-md-12">
                      <form onSubmit={handleCreate} method="post">
                        <div className="row d-flex flex-column justify-content-center align-items-center ">
                          <div className="col-6 my-4 signformstart text-center">
                            <h1 className="signtitle">Log In</h1>
                          </div>
                          <div className="col-md-6">
                            <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
                              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <TextAreaBox
                                  type="email"
                                  label="Email"
                                  name="email"
                                  value={values.email}
                                  onChange={handleInputChange}
                                  required
                                />
                              </div>
                              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <TextAreaBox
                                  type="password"
                                  label="Password"
                                  name="password"
                                  value={values.password}
                                  onChange={handleInputChange}
                                  required
                                />
                              </div>

                              <div className="col-xxl-4 mx-auto col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                <BlueBtn title="Login" />
                              </div>
                            </div>
                          </div>

                          <div className="text-center popuplink my-2">
                            <p>
                              Don't have an account ? &nbsp;
                              <span
                                onClick={() => {
                                  dispatch(setShowLoginModal());
                                  dispatch(setShowSignUpModal());
                                }}
                              >
                                Sign Up
                              </span>
                            </p>
                            <div className="googleicon d-flex justify-content-center my-2" />
                            <div className="fbicon d-flex justify-content-center my-2">
                              <LoginSocialFacebook
                                isOnlyGetToken
                                appId="3789647204595344"
                                onResolve={handleFbResponse}
                                onReject={(err) => {
                                  console.log(err);
                                }}
                              >
                                <FacebookLoginButton />
                              </LoginSocialFacebook>
                            </div>
                            <p>
                              <span
                                onClick={() => dispatch(setShowForgotModal())}
                              >
                                Forget Password
                              </span>
                            </p>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </Container>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  };
  //////////////////////  Forgot Modal //////////////////////////////
  const ForgotModal = () => {
    const [email, setEmail] = useState("");

    const handleSubmit = (e) => {
      e.preventDefault();

      try {
        axios
          .post(ENDPOINT + "api/auth/forgotpassword", { email })
          .then((res) => {
            if (res.status === 200) {
              dispatch(setShowForgotModal());
              dispatch(setShowCheckYourEmailModal());
              dispatch(setUserEmail(email));
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              toast.error("Emai is not registered", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else if (err.response.status === 400) {
              toast.error("Invalid Email", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          });
      } catch (err) {
        toast.error("Server error", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    };

    return (
      <>
        <Modal
          animation={true}
          className="signupmodal fullwidthmodal"
          show={showForgotModal}
          onHide={() => {
            dispatch(setShowForgotModal());
            dispatch(setShowLoginModal(false));
          }}
        >
          <Modal.Header closeButton>
            <Link
              to="/home"
              onClick={() => {
                dispatch(setShowForgotModal());
              }}
            >
              <div className=" ">
                <img width="100" src={HeaderLogo} alt="companylogo" />
              </div>
            </Link>
          </Modal.Header>
          <Modal.Body className="p-0">
            <div className="row h-100">
              <div className="col-md-3 signleft">
                <div className="contact-info">
                  <h1 className="text-light">
                    Let’s Craft <br /> Brilliance
                  </h1>
                </div>
              </div>

              <div className="col-md-9 signright">
                <Container>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row d-flex flex-column justify-content-center align-items-center ">
                        <div className="col-6 my-4 signformstart text-center">
                          <h1 className="signtitle">Forgot Password?</h1>
                          <p>No worries, we’ll send you reset instructions.</p>
                        </div>
                        <div className="col-md-6">
                          <form onSubmit={handleSubmit} method="post">
                            <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
                              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <TextAreaBox
                                  type="email"
                                  label="Email"
                                  name="Email"
                                  required
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </div>
                              <div className="col-xxl-5 mx-auto col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <BlueBtn type="submit" title="Reset Password" />
                              </div>
                            </div>
                          </form>
                        </div>

                        <div className="text-center popuplink my-2">
                          <p>
                            &nbsp;
                            <span
                              onClick={() => {
                                dispatch(setShowForgotModal());
                                dispatch(setShowLoginModalTrue(true));
                              }}
                            >
                              Back to login
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Container>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  };
  ////////////////////// Check Your Email Modal //////////////////////////////
  const CheckYourEmailModal = () => {
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const handleSubmit = () => {
      try {
        axios.post(ENDPOINT + "api/auth/forgotpassword", { email: userEmail });
      } catch (err) {
        toast.error("Server error!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    };

    const handleClick = () => {
      handleSubmit();
      resetTimer();
    };

    useEffect(() => {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }

        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    });

    const resetTimer = function () {
      setMinutes(0);
      setSeconds(59);
    };

    return (
      <>
        <Modal
          animation={true}
          className="signupmodal fullwidthmodal"
          show={showCheckYourEmailModal}
          onHide={() => {
            dispatch(setShowCheckYourEmailModal());
            dispatch(setShowLoginModal(false));
          }}
        >
          <Modal.Header closeButton>
            <Link
              to="/home"
              onClick={() => {
                dispatch(setShowCheckYourEmailModal());
              }}
            >
              <div className=" ">
                <img width="100" src={HeaderLogo} alt="companylogo" />
              </div>
            </Link>
          </Modal.Header>
          <Modal.Body className="p-0">
            <div className="row h-100">
              <div className="col-md-3 signleft">
                <div className="contact-info">
                  <h1 className="text-light">
                    Let’s Craft <br /> Brilliance
                  </h1>
                </div>
              </div>

              <div className="col-md-9 signright">
                <Container>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row d-flex flex-column justify-content-center align-items-center ">
                        <div className="col-6 my-4 signformstart text-center">
                          <h1 className="signtitle">Check your email</h1>
                          <p>
                            We sent a password reset link to <br />
                            {userEmail}
                          </p>
                        </div>
                        <div className="col-md-6">
                          <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
                            <div className="col-xxl-5 mx-auto col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                              <a href="https://mail.google.com">
                                <BlueBtn title="Open Email" />
                              </a>
                            </div>
                          </div>
                        </div>
                        <form method="post">
                          <div className="text-center popuplink my-2">
                            <p>
                              Didn't receive the email? &nbsp;
                              {seconds > 0 || minutes > 0 ? (
                                <p>
                                  Time Remaining:
                                  {minutes < 10 ? `0${minutes}` : minutes}:
                                  {seconds < 10 ? `0${seconds}` : seconds}
                                </p>
                              ) : (
                                <span
                                  type="submit"
                                  onClick={() => handleClick()}
                                >
                                  Resend
                                </span>
                              )}
                              <br />
                              <span
                                onClick={() => {
                                  dispatch(setShowCheckYourEmailModal());
                                  dispatch(setShowLoginModalTrue(true));
                                }}
                              >
                                Back to login
                              </span>
                            </p>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Container>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  };
  ////////////////////// Password Reset Modal //////////////////////////////
  const PasswordResetModal = () => {
    return (
      <>
        <Modal
          animation={true}
          className="signupmodal fullwidthmodal"
          show={showPasswordResetModal}
          onHide={() => {
            dispatch(setShowPasswordResetModal());
            dispatch(setShowLoginModal(false));
          }}
        >
          <Modal.Header closeButton>
            <Link
              to="/home"
              onClick={() => {
                dispatch(setShowPasswordResetModal());
              }}
            >
              <div className=" ">
                <img width="100" src={HeaderLogo} alt="companylogo" />
              </div>
            </Link>
          </Modal.Header>
          <Modal.Body className="p-0">
            <div className="row h-100">
              <div className="col-md-3 signleft">
                <div className="contact-info">
                  <h1 className="text-light">
                    Let’s Craft <br /> Brilliance
                  </h1>
                </div>
              </div>

              <div className="col-md-9 signright">
                <Container>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row d-flex flex-column justify-content-center align-items-center ">
                        <div className="col-6 my-4 signformstart text-center">
                          <h1 className="signtitle">Password Reset</h1>
                          <p className="mb-0">
                            Your password has been successfully reset.
                          </p>
                          <p>Click below to login.</p>
                        </div>
                        {/* <div className="col-md-6">
                          <form method="post">
                            <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
                              <div className="col-xxl-5 mx-auto col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <BlueBtn type="submit" title="Open Email" />
                              </div>
                            </div>
                          </form>
                        </div> */}

                        <div className="text-center popuplink my-2">
                          <p>
                            <span
                              onClick={() => {
                                navigate("/");
                                dispatch(setShowPasswordResetModal());
                                dispatch(setShowLoginModalTrue(true));
                              }}
                            >
                              Back to login
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Container>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  };
  //////////////////////  Set New Password Modal //////////////////////////////
  const SetNewPasswordModal = () => {
    const [values, setValues] = useState({
      password: "",
      confirmPassword: "",
    });

    const handleInputChange = (e) => {
      const name = e.target.name;
      const value = e.target.value;
      setValues({
        ...values,
        [name]: value,
      });
    };

    const handleSubmit = (e) => {
      e.preventDefault();

      try {
        axios
          .patch(ENDPOINT + `api/auth/resetpassword/${showToken}`, values)
          .then((res) => {
            if (res.status === 200) {
              dispatch(setShowPasswordResetModal());
              dispatch(setNewPasswordModal());
            }
          })
          .catch((err) => {
            if (err.response.status === 400) {
              toast.error("Password not updated!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } else if (err.response.status === 401) {
              toast.error("Unauthorized access", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          });
      } catch (err) {
        toast.error("Server error!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    };

    return (
      <>
        <Modal
          animation={true}
          className="signupmodal fullwidthmodal"
          show={showNewPasswordModal}
          onHide={() => {
            navigate("/");
            dispatch(setNewPasswordModal());
            dispatch(setShowLoginModalTrue(false));
          }}
        >
          <Modal.Header closeButton>
            <Link
              to="/"
              onClick={() => {
                dispatch(setNewPasswordModal());
              }}
            >
              <div className=" ">
                <img width="100" src={HeaderLogo} alt="companylogo" />
              </div>
            </Link>
          </Modal.Header>
          <Modal.Body className="p-0">
            <div className="row h-100">
              <div className="col-md-3 signleft">
                <div className="contact-info">
                  <h1 className="text-light">
                    Let’s Craft <br /> Brilliance
                  </h1>
                </div>
              </div>

              <div className="col-md-9 signright">
                <Container>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row d-flex flex-column justify-content-center align-items-center ">
                        <div className="col-6 my-4 signformstart text-center">
                          <h1 className="signtitle">Set New Password</h1>
                          {/* <p>
                            We sent a password reset link to
                            akhilesh@maxlence.com.au
                          </p> */}
                        </div>
                        <div className="col-md-6">
                          <form onSubmit={handleSubmit} method="patch">
                            <div className="row g-xxl-4 g-xl-4 g-lg-4 g-md-4 g-sm-3 g-3">
                              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <TextAreaBox
                                  type="password"
                                  label="Password"
                                  name="password"
                                  onChange={handleInputChange}
                                  required
                                />
                              </div>
                              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <TextAreaBox
                                  type="password"
                                  label="Confirm Password"
                                  name="confirmPassword"
                                  onChange={handleInputChange}
                                  required
                                />
                              </div>
                              <div className="col-xxl-5 mx-auto col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <BlueBtn type="submit" title="Reset Password" />
                              </div>
                            </div>
                          </form>
                        </div>

                        <div className="text-center popuplink my-2">
                          <p>
                            &nbsp;
                            <span
                              onClick={() => {
                                navigate("/");
                                dispatch(setNewPasswordModal());
                                dispatch(setShowLoginModalTrue(true));
                              }}
                            >
                              Back to login
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Container>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  };
  ////////////////////// Menu Modal //////////////////////////////
  const MenuModal = () => {
    return (
      <>
        <HiOutlineMenuAlt2
          className="mx-2"
          style={{ width: "2rem" }}
          onClick={() => dispatch(setShowMenuModal())}
        />
        <Modal
          className="menumodal fullwidthmodal"
          show={showMenuModal}
          onHide={() => dispatch(setShowMenuModal())}
        >
          <Modal.Header closeButton className="border-0"></Modal.Header>
          <Modal.Body className="p-0">
            <div className="row h-100 align-items-center">
              <div className="col-md-6 menuleft">
                <div className="menulinks">
                  <Nav
                    className="ms-auto my-2 my-lg-0 align-items-center text-white"
                    navbarScroll
                  >
                    <Nav.Link
                      onClick={() => {
                        dispatch(setShowMenuModal());
                        navigate("/creation");
                      }}
                    >
                      Creation
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => {
                        dispatch(setShowMenuModal());
                        navigate("/growth");
                      }}
                    >
                      Growth
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => {
                        dispatch(setShowMenuModal());
                        navigate("/design");
                      }}
                    >
                      Design
                    </Nav.Link>
                    <NavDropdown title="Business" id="navbarScrollingDropdown">
                      <NavDropdown.Item
                        onClick={() => {
                          dispatch(setShowMenuModal());
                          navigate("/business/business1");
                        }}
                      >
                        Business1
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => {
                          dispatch(setShowMenuModal());
                          navigate("/business/business2");
                        }}
                      >
                        Business 2
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Resources" id="navbarScrollingDropdown">
                      <NavDropdown.Item
                        onClick={() => {
                          dispatch(setShowMenuModal());
                          navigate("/resources/design-inspiration");
                        }}
                      >
                        Design Inspiration
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => {
                          dispatch(setShowMenuModal());
                          navigate("/resources/brochure");
                        }}
                      >
                        Brochure
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => {
                          dispatch(setShowMenuModal());
                          navigate("/resources/case-study");
                        }}
                      >
                        Case Study
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => {
                          dispatch(setShowMenuModal());
                          navigate("/resources/blogs");
                        }}
                      >
                        Blogs
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown
                      title="Enterprise"
                      id="navbarScrollingDropdown"
                    >
                      <NavDropdown.Item
                        onClick={() => {
                          dispatch(setShowMenuModal());
                          navigate("/enterprise/seo-services");
                        }}
                      >
                        SEO Service
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => {
                          dispatch(setShowMenuModal());
                          navigate("/enterprise/google-ads-services");
                        }}
                      >
                        Google Ads Service
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => {
                          dispatch(setShowMenuModal());
                          navigate("/enterprise/social-media-services");
                        }}
                      >
                        Social Media Service
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => {
                          dispatch(setShowMenuModal());
                          navigate("/enterprise/mobile-app");
                        }}
                      >
                        Mobile App
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => {
                          dispatch(setShowMenuModal());
                          navigate("/enterprise/saas");
                        }}
                      >
                        Saas
                      </NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link
                      onClick={() => {
                        dispatch(setShowMenuModal());
                        navigate("/shop");
                      }}
                    >
                      Shop
                    </Nav.Link>
                    <NavDropdown title="Solutions" id="navbarScrollingDropdown">
                      <NavDropdown.Item
                        onClick={() => {
                          dispatch(setShowMenuModal());
                          navigate("/solutions/api-integration");
                        }}
                      >
                        API Integration
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => {
                          dispatch(setShowMenuModal());
                          navigate("/solutions/form-tracker");
                        }}
                      >
                        Form Tracker
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => {
                          dispatch(setShowMenuModal());
                          navigate("/solutions/review-tracker");
                        }}
                      >
                        Review Tracker
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() => {
                          dispatch(setShowMenuModal());
                          navigate("/solutions/agencies");
                        }}
                      >
                        Agencies
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </div>
              </div>

              <div className="col-md-6 menuright">
                <Container>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row d-flex flex-column justify-content-center align-items-center ">
                        <div className="col-6 my-4 signformstart text-center">
                          <h1 className="">We’d love to be your partner</h1>
                        </div>
                        <div className="col-4">
                          <OutlineBtn
                            onClick={() => {
                              dispatch(setShowMenuModal());
                              navigate("/get-in-touch");
                            }}
                            title="Get In Touch"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Container>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  };

  const navLinkStyles = ({ isActive }) => {
    return {
      color: isActive ? "#00C99C" : "rgba(33, 37, 41, 0.8)",
    };
  };
  return (
    <Container fluid bg="light" className="navbarmain px-md-5 ">
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Navbar expand="lg">
        <NavLink
          className=" col-md-2 col-5 col-sm-2 col-md-3 col-lg-2 col-xl-2 "
          to="/"
        >
          <img width="100%" src={HeaderLogo} alt="companylogo" />
        </NavLink>
        {/* <div className="d-lg-none d-sm-block">
          <HiOutlineMenuAlt1 />
        </div> */}
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="mx-3 w-100 justify-content-around my-2 my-lg-0 align-items-center"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <NavLink style={navLinkStyles} to="/creation">
              Creation
            </NavLink>
            <NavLink style={navLinkStyles} to="/growth">
              Growth
            </NavLink>
            <NavLink style={navLinkStyles} to="/design">
              Design
            </NavLink>
            <NavDropdown title="Business" id="navbarScrollingDropdown">
              <NavDropdown.Item onClick={() => navigate("/business/business1")}>
                Business1
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate("/business/business2")}>
                Business 2
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Resources" id="navbarScrollingDropdown">
              <NavDropdown.Item
                onClick={() => navigate("/resources/design-inspiration")}
              >
                Design Inspiration
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate("/resources/brochure")}>
                Brochure
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => navigate("/resources/case-study")}
              >
                Case Study
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate("/resources/blogs")}>
                Blogs
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Enterprise" id="navbarScrollingDropdown">
              <NavDropdown.Item
                onClick={() => navigate("/enterprise/seo-services")}
              >
                SEO Service
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => navigate("/enterprise/google-ads-services")}
              >
                Google Ads Service
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => navigate("/enterprise/social-media-services")}
              >
                Social Media Service
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => navigate("/enterprise/mobile-app")}
              >
                Mobile App
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate("/enterprise/saas")}>
                Saas
              </NavDropdown.Item>
            </NavDropdown>
            <NavLink style={navLinkStyles} to="/shop">
              Shop
            </NavLink>
            <NavDropdown title="Solutions" id="navbarScrollingDropdown">
              <NavDropdown.Item
                onClick={() => navigate("/solutions/api-integration")}
              >
                API Integration
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => navigate("/solutions/form-tracker")}
              >
                Form Tracker
              </NavDropdown.Item>
              <NavDropdown.Item
                onClick={() => navigate("/solutions/review-tracker")}
              >
                Review Tracker
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate("/solutions/agencies")}>
                Agencies
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
        <Nav className="d-flex flex-row align-items-center justify-content-around">
          <SignUpmodal />
          <MenuModal />
          <LoginModal />
          <ForgotModal />
          <CheckYourEmailModal />
          <PasswordResetModal />
          <SetNewPasswordModal />
        </Nav>
      </Navbar>
    </Container>
  );
};
