import * as React from "react";
import Wifi from "../../../img/wifi.png";
import Sim from "../../../img/sim.png";
import { MdOutlineModeEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlinePlus } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import ENDPOINT from "../config/ENDPOINT";
import axios from "axios";
import Master from "../../../img/master.png";
import Visa from "../../../img/visa.png";
import Amex from "../../../img/amex.png";
import Jcb from "../../../img/jcb.png";
import Diners from "../../../img/diners.png";
import UnionPay from "../../../img/union-pay.png";
import Discover from "../../../img/discover.png";
import Default from "../../../img/default-card.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { formatCreditCardNumber, formatExpirationDate } from "./utils";
import { BsFillInfoCircleFill } from "react-icons/bs";

export const Payment = () => {
  const [card, setCard] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openPopup, setOpenPopup] = React.useState(false);
  const token = localStorage.getItem("token");
  const user = useSelector((state) => state.state.user);
  const [dltId, setDltId] = React.useState(0);

  const fetchCards = async () => {
    const { data } = await axios.get(ENDPOINT + `payment-card/user_cards`, {
      headers: { "x-access-token": token },
    });
    setCard(data);
  };

  React.useEffect(() => {
    fetchCards();
  }, [open]);

  const handleCardDelete = async () => {
    try {
      let isCardDlted = await axios.delete(ENDPOINT + `payment-card/${dltId}`, {
        headers: { "x-access-token": token },
      });
      if (isCardDlted) {
        toast.success("Card has been deleted!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      setCard(card.filter((c) => c.id !== dltId));
      setOpenPopup(false);
    } catch (err) {
      toast.error("Server error!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log(err);
    }
  };

  // delete
  const DeleteModal = () => {
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 4,
    };
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        closeAfterTransition
      >
        <Fade in={openPopup}>
          <Box sx={style} className="delete_modal_my">
            <div className="delete_modal">
              <ul>
                <li>
                  <BsFillInfoCircleFill />
                </li>
                <li>
                  <h3>Delete ?</h3>
                  <p>Are you sure you want to delete?</p>
                </li>
              </ul>
              <div className="delete_modal_btn_div">
                <ul>
                  <li className="btn_one">
                    <button
                      onClick={() => setOpenPopup(false)}
                      className="outline_btn btn"
                    >
                      No
                    </button>
                  </li>
                  <li className="btn_two">
                    <button
                      onClick={handleCardDelete}
                      className="btn full_btn ms-1"
                    >
                      Yes
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    );
  };
  // delete end

  const CardApp = (props) => {
    const savedCard = props.number
      .toString()
      .match(/.{1,4}/g)
      .join(" ");
    return (
      <>
        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
          <div className="saved_cards_div">
            <div className="saved_cards_wifi">
              <img src={Wifi} alt="wifi" />
              <p>{props.type}</p>
            </div>
            <h1>{props.issuer}</h1>
            <div className="saved_cards_number">
              <p>
                <span>XXXX</span>
                <span>XXXX</span>
                <span>XXXX</span>
                <span>{savedCard.split(" ")[3]}</span>
              </p>
              <img src={Sim} alt="sim" />
            </div>
            <p className="saved_cards_name">{props.name}</p>
            <p className="saved_cards_date">{props.expiry}</p>
            <div className="saved_cards_div_over_div">
              <div className="saved_cards_div_over">
                {/* <span className="saved_cards_div_over_edit">
                  <MdOutlineModeEdit onClick={() => handleUpdate(props.id)} />
                </span> */}
                <span className="saved_cards_div_over_edit">
                  <RiDeleteBin6Line
                    onClick={() => {
                      setOpenPopup(true);
                      setDltId(props.id);
                    }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const CardForm = () => {
    const [state, setState] = React.useState({
      number: "",
      name: "",
      expiry: "",
      type: "",
    });
    const [issuer, setIssuer] = React.useState("");
    const { number, name, expiry, type } = state;
    const num = number.replaceAll(" ", "");
    const [errorMessage, setErrorMessage] = React.useState("");
    const [errorExpiryMessage, setErrorExpiryMessage] = React.useState("");

    const handleInputChange = ({ target }) => {
      if (target.name === "number") {
        const { value, issuer, validateCard } = formatCreditCardNumber(
          target.value
        );
        target.value = value === undefined ? "" : value;
        validateCard
          ? setErrorMessage("")
          : setErrorMessage("Invalid card number");
        setIssuer(issuer);
      } else if (target.name === "expiry") {
        const { value, validateExpiry } = formatExpirationDate(target.value);
        target.value = value === undefined ? "" : value;
        validateExpiry
          ? setErrorExpiryMessage("")
          : setErrorExpiryMessage("Invalid expiry date");
      }

      setState((old) => {
        return { ...old, [target.name]: target.value };
      });
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      const values = { number: num, name, expiry, type, issuer };
      if (errorMessage !== "" || errorExpiryMessage !== "") {
        return;
      } else
        try {
          axios({
            url: ENDPOINT + "payment-card",
            method: "POST",
            headers: { "x-access-token": token },
            data: values,
          })
            .then((res) => {
              if (res.status === 200) {
                toast.success("New Card Added!", {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
                setTimeout(() => {
                  setOpen(false);
                }, 1000);
              }
            })
            .catch((err) => {
              if (err.response.status === 401) {
                toast.error("Card already exists!", {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              } else if (err.response.status === 400) {
                toast.error("No New card added!", {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              }
            });
        } catch (err) {
          toast.error("Server error!", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
    };

    return (
      <div key="Payment">
        <div className="App-payment">
          <form onSubmit={handleSubmit} method="post">
            <div className="row g-3">
              <div className="col-12">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  name="type"
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Card Type</option>
                  <option value="Credit Card">Credit Card</option>
                  <option value="Debit Card">Debit Card</option>
                </select>
              </div>
              <div className="col-12">
                <div className="row g-3">
                  <div className="col-12">
                    <div className="form-group card_input_div">
                      <small>Card Number</small>
                      <div className="card_input_div_input">
                        <img
                          src={
                            issuer === "mastercard"
                              ? Master
                              : issuer === "visa"
                              ? Visa
                              : issuer === "amex"
                              ? Amex
                              : issuer === "jcb"
                              ? Jcb
                              : issuer === "dinersclub"
                              ? Diners
                              : issuer === "discover"
                              ? Discover
                              : issuer === "unionpay"
                              ? UnionPay
                              : Default
                          }
                          alt="img"
                        />
                        <input
                          type="tel"
                          name="number"
                          className="form-control"
                          placeholder="Card Number"
                          pattern="[\d| ]{16,22}"
                          maxLength="19"
                          required
                          value={state.number}
                          onChange={handleInputChange}
                        />
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {errorMessage}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group card_input_div">
                      <small>Name on card</small>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        pattern="[a-z A-Z-]+"
                        required
                        value={state.name}
                        autoComplete="off"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row g-3">
                  <div className="col-12">
                    <div className="form-group card_input_div">
                      <small>Expiration Date</small>
                      <input
                        type="tel"
                        name="expiry"
                        className="form-control"
                        placeholder="Valid Thru"
                        pattern="\d\d/\d\d"
                        required
                        value={state.expiry}
                        onChange={handleInputChange}
                      />
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        {errorExpiryMessage}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="row">
                  <div className="col-12 checkout_btn justify-content-center mt-2">
                    <div>
                      <div className="form-actions">
                        <button
                          type="submit"
                          className=" brn full_btn checkout_btn"
                        >
                          Save Card
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const TransitionsModal = () => {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
            <div className="col-12">
              <div className="payment_main_title text-center">
                <h3>Add New Card</h3>
              </div>
              <div>
                <CardForm />
              </div>
            </div>
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  };

  return (
    <>
      <section className=" container mt-5 mb-5">
        <DeleteModal />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className="row">
          <div className="col-12">
            <div>
              <div className="payment_main_title">
                <h3>Billing Address</h3>
              </div>
              <div className="payment_card">
                <Link to="/your-account">
                  <MdOutlineModeEdit />
                </Link>
                <h3>{user.companyName}</h3>
                <p>{user.workingAddress}</p>
                <p>Contact No - {user.phoneNumber}</p>
                <p>Email - {user.companyEmail}</p>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="payment_main_title mt-5">
              <h3>Saved Cards</h3>
            </div>
            <div className="row g-3">
              {card.map((val, i) => {
                return <CardApp key={i} {...val} />;
              })}
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="card_add_new">
                  <AiOutlinePlus onClick={handleOpen} />
                  <TransitionsModal />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
