import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./scss/index.scss";
import "./scss/res.scss";
import "./styles/style.scss";
import "./styles/res.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { App } from "./App";
import { Provider } from "react-redux";
import store from "./Modules/Maxlence/redux/store";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // </React.StrictMode>
);
