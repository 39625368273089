import { NavLink } from "react-router-dom";
import Trafficrevenue from "../assests/trafficrevenue.jpg";
import Seosearch from "../assests/seosearch.png";
import Seonoti from "../assests/seonoti.png";
import Seotarget from "../assests/seotarget.png";
import Whatwedo from "../assests/whatwedo.jpg";
import Seoresult from "../assests/seoresult.png";
import Grcomputericon from "../assests/grcomputericon.png";
//
import { FooterContact } from "../components/FooterContact";

import GoogleAdsIcon from "../../../img/GoogleAdsIcon.svg";
import EmailMarketingIcon from "../../../img/EmailMarketingIcon.svg";
import VideoMarketingIcon from "../../../img/VideoMarketingIcon.svg";
import GMBIcon from "../../../img/GMBIcon.svg";
import SEOIcon from "../../../img/SEOIcon.svg";
import SocialMediaIcon from "../../../img/SocialMediaIcon.svg";
import CROIcon from "../../../img/CROIcon.svg";
import { Faqs } from "../components/Faqs";

const TopLinkData = [
  {
    linkName: "Google Ads",
    linkIcon: GoogleAdsIcon,
    link: "/growth/google-ads",
    activeNav: "none",
    linkNameColor: "",
  },
  {
    linkName: "CRO",
    linkIcon: CROIcon,
    link: "/growth/cro",
    activeNav: "none",
    linkNameColor: "",
  },
  {
    linkName: "Social Media",
    linkIcon: SocialMediaIcon,
    link: "/growth/social-media-marketing",
    activeNav: "none",
    linkNameColor: "",
  },
  {
    linkName: "SEO",
    linkIcon: SEOIcon,
    link: "/growth/seo",
    activeNav: "block",
    linkNameColor: "black",
  },
  {
    linkName: "GMB",
    linkIcon: GMBIcon,
    link: "/growth/gmb",
    activeNav: "none",
    linkNameColor: "",
  },
  {
    linkName: "Video Marketing",
    linkIcon: VideoMarketingIcon,
    link: "/growth/video-marketing",
    activeNav: "none",
    linkNameColor: "",
  },
  {
    linkName: "Email Marketing",
    linkIcon: EmailMarketingIcon,
    link: "/growth/email-marketing",
    activeNav: "none",
    linkNameColor: "",
  },
];

export const Seo = () => {
  return (
    <>
    <div className=" creation_and_growth_top_div mb-2">
        <div className=" container">
          <div className="growth_top_div">
            {TopLinkData.map((val, i) => (
              <div key={i} className=" creation_and_growth_col_div">
                <NavLink to={val.link}>
                  <img src={val.linkIcon} alt="icon" />
                  <br />
                  <span style={{ color: val.linkNameColor }}>
                    {val.linkName}
                  </span>
                </NavLink>
                <div
                  style={{ display: val.activeNav }}
                  className="creation_and_growth_activ"
                ></div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="container-fluid services_modal_page_div2"
        style={{ background: "#F5F5F5" }}
      >
        <div className="container py-5">
          <div className="row  justify-content-around align-items-center">
            <div className="col-12 col-md-12 col-lg-7 my-3 text-sm-left ">
              <h1 className="my-4 all_h1_title">
                Drive <span>More Organic Traffic</span> With SEO.
              </h1>
              <p className="all_h1_title_p">
                Are you tired of being buried on the second or third page of
                Google search results? Don't let your business get lost in the
                crowd any longer! Our expert SEO team can get your business on
                the first page of search results, driving more traffic to your
                website and ultimately increasing sales. You’re not believing
                right? Get access to your personalized dashboard and track
                real-time performance- let us help you stand out and grow with
                SEO!
              </p>
              <div className="py-4">
                <NavLink
                  to="/growth/seo/questionnaire"
                  className="getstartbtn  fontrale my-5 "
                >
                  Get Started
                </NavLink>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-5 my-2 my-sm-0 my-3 rounded text-center ">
              <img className="w-100" src={Trafficrevenue} alt="dc" />
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5 my-3 secoservices">
        <div className="row justify-content-center">
          <div className="latestprojecttitle col-md-8 text-center">
            <h2 className="all_h1_title font-weight-bold my-4  wow animate__animated animate__fadeInDown animate__delay-1s">
              How do we get <span>Ranking, Traffic, Sales</span> for you!
            </h2>
            <p className="all_h1_title_p wow animate__animated animate__fadeInUp animate__delay-1s">
              Here are the shortcuts to making a big impact online – and on your
              bottom line – is to work with a specialised SEO agency to leverage
              the power of high visibility on Google. With our cutting-edge
              strategies and constant algorithm updates, we'll keep your brand
              ahead of the competition and surpass your marketing goals. Don't
              wait - join the ranks of top-ranking brands today!
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-sm-3 m-3 text-center seofeatures   wow animate__animated animate__fadeInUp animate__delay-1s">
            <div className="seofeatureicon">
              <img src={Seosearch} alt="d" />
            </div>
            <div className="domainsertitle my-3">
              <h6>
                <b>Quality link building </b>
              </h6>
            </div>
            <div className="domainsertext">
              <small>
                What is the backbone of SEO? Quality link building is the
                answer! Our team of experts specialize in acquiring high-quality
                links from reputable websites, boosting your website's authority
                and search engine rankings.
              </small>
            </div>
          </div>

          <div className="col-sm-3 m-3 text-center seofeatures  wow animate__animated animate__fadeInUp animate__delay-1s">
            <div className="domainserviceicon">
              <img src={Seonoti} alt="df" />
            </div>
            <div className="domainsertitle my-3">
              <h6>
                <b>SEO Audits </b>
              </h6>
            </div>
            <div className="domainsertext">
              <small>
                Just like a doctor examines your body to ensure it's healthy, an
                SEO audit examines your website to make sure it's functioning
                properly. Get this audit free of cost and our team will suggest
                you the best SEO strategies.
              </small>
            </div>
          </div>

          <div className="col-sm-3 m-3 text-center seofeatures  wow animate__animated animate__fadeInUp animate__delay-1s">
            <div className="domainserviceicon">
              <img src={Seotarget} alt="f" />
            </div>
            <div className="domainsertitle my-3">
              <h6>
                <b>Keyword-rich content </b>
              </h6>
            </div>
            <div className="domainsertext">
              <small>
                Speak the same language as your target audience wants to hear.
                Our team will create content like a roadmap for search engines
                to understand what your website is all about. By using specific
                words and phrases that your target audience is searching for,
                you can help search engines like Google connect your content
                with those searches.
              </small>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid my-5"
        style={{ backgroundColor: "#E2F0FF" }}
      >
        <div className="container py-3 my-2">
          <div className="row  justify-content-between align-items-center">
            <div className="col-12 col-md-12 col-lg-7 my-3 text-sm-left ">
              <h2 className="my-4 all_h1_title">Our remarkable SEO services.</h2>
              <p className="all_h1_title_p" style={{ color: "#212529B2" }}>
                Don't fall behind in the SEO game! Stay ahead of your
                competition with our expert SEO services. Our team uses proven
                techniques to boost your search engine rankings and drive more
                traffic to your website. Let us help you stay ahead of the curve
                and dominate the online landscape.
              </p>
              <br />

              <ul
                className="pl-0"
                style={{
                  listStyleType: "disc",
                  color: "#212529B2",
                  lineHeight: "28px",
                }}
              >
                <div className="wedobullets d-flex flex-column flex-md-row justify-content-start">
                  <div className="col-md-4">
                    <li>Local business SEO </li>
                    <li>Outsource SEO </li>
                    <li>Keyword research </li>
                    <li>On-page SEO </li>
                    <li>Off-page SEO </li>
                    <li>Competitor analysis </li>
                  </div>
                  <div className="col-md-5">
                    <li>SEO audits and assessment </li>
                    <li>SEO analytics and insights </li>
                    <li>Intelligent content solutions </li>
                    <li>Outreach and backlink strategy </li>
                    <li>Enterprise SEO </li>
                  </div>
                </div>
              </ul>
            </div>
            <div className="col-12 col-md-12 col-lg-5 my-2 my-sm-0 my-3 rounded text-center ">
              <img className="w-100" src={Whatwedo} alt="df" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-4">
            <img className="w-100" src={Seoresult} alt="dd" />
          </div>
          <div className="col-md-4 text-md-right text-center">
            <h2 className="all_h1_title">Your website ranking</h2>
            <h6>#1 on Google </h6>
            <p>
              Don't let your competition steal your spotlight with our SEO
              services!
            </p>
          </div>
        </div>
      </div>
      <div
        className="container-fluid my-sm-5 my-3"
        style={{ background: "#566677" }}
      >
        <div className="container py-3 my-2">
          <div className="row text-center justify-content-between">
            <div className="col-md-4">
              <div className="dataperc my-3">
                <h2 className="fontpop" style={{ color: "#FE8080" }}>
                  53%
                </h2>
                <p className="text-white">
                  of website traffic comes from organic search, making SEO a
                  crucial component of any digital marketing strategy.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="dataperc my-3">
                <h2 className="fontpop" style={{ color: "#FE8080" }}>
                  91.5%
                </h2>
                <p className="text-white">
                  of all search engine traffic goes to businesses that are
                  listed on the first page of Google's search results.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="dataperc my-3">
                <h2 className="fontpop" style={{ color: "#FE8080" }}>
                  60%
                </h2>
                <p className="text-white">
                  of all organic search engine visits come from mobile devices.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5 my-3 secoservices">
        <div className="row justify-content-center">
          <div className="latestprojecttitle col-md-8 text-center">
            <h2 className="all_h1_title font-weight-bold my-4  wow animate__animated animate__fadeInDown animate__delay-1s">
              How it works to get found first?
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 my-3">
            <div>
              <ol style={{ listStyleType: "decimal", lineHeight: "38px" }}>
                <li>Start with Discovery </li>
                <li>Keyword Research </li>
                <li>Competitive Analysis </li>
                <li>Content Review </li>
                <li>Site Structure Review </li>
                <li>Link Audit </li>
                <li>Technical Analysis </li>
                <li>End with Reporting and Analysis </li>
              </ol>
            </div>
          </div>

          <div className="col-md-5 my-3">
            <div>
              <div></div>
              <div className="text-right">
                <span className="float-left">
                  <img src={Grcomputericon} alt="fd" />
                </span>
                <h2>Website optimization</h2>
                <p className="mt-5 py-3">
                  Our team of on-site optimization specialists get to work
                  fixing the code and copy on your website to make sure it's
                  user friendly and optimized for Google's algorithm.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterContact title="We’d love to be your partner"/>
      <Faqs catId={9} />
    </>
  );
};
