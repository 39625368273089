import { createSlice } from "@reduxjs/toolkit";
const modalStateSlice = createSlice({
  name: "modal",
  initialState: {
    showLoginModal: false,
    showSignUpModal: false,
    showMenuModal: false,

    showForgotModal: false,
    showCheckYourEmailModal: false,
    showPasswordResetModal: false,
    showNewPasswordModal: false,

    user: {},
    geoInfo: {},
    token: "",
    userEmail: "",
    overallPlanId: 0,
    recentlyViewed: JSON.parse(localStorage.getItem("Recently_viewed")) || [],
  },
  reducers: {
    setShowLoginModal: (state) => {
      state.showLoginModal = !state.showLoginModal;
    },
    setShowLoginModalTrue: (state, action) => {
      state.showLoginModal = action.payload;
    },
    setShowSignUpModal: (state) => {
      state.showSignUpModal = !state.showSignUpModal;
    },
    setShowMenuModal: (state) => {
      state.showMenuModal = !state.showMenuModal;
    },

    setShowForgotModal: (state) => {
      state.showForgotModal = !state.showForgotModal;
    },
    setShowCheckYourEmailModal: (state) => {
      state.showCheckYourEmailModal = !state.showCheckYourEmailModal;
    },
    setShowPasswordResetModal: (state) => {
      state.showPasswordResetModal = !state.showPasswordResetModal;
    },
    setNewPasswordModal: (state) => {
      state.showNewPasswordModal = !state.showNewPasswordModal;
    },
    setNewPasswordModalTrue: (state, action) => {
      state.showNewPasswordModal = action.payload;
    },

    setToken: (state, action) => {
      state.token = action.payload;
    },

    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },

    setUser: (state, action) => {
      state.user = action.payload;
    },

    setGeoInfo: (state, action) => {
      state.geoInfo = action.payload;
    },

    setOverallPlanId: (state, action) => {
      state.overallPlanId = action.payload;
    },

    setRecentlyViewed: (state, action) => {
      var selectedData =
        state.recentlyViewed[
          state.recentlyViewed
            .map(function (item) {
              return item.id;
            })
            .indexOf(action.payload.id)
        ];
      if (!selectedData) {
        state.recentlyViewed = [...state.recentlyViewed, action.payload];
        localStorage.setItem(
          "Recently_viewed",
          JSON.stringify(state.recentlyViewed)
        );
      }
      if (state.recentlyViewed.length >= 8) state.recentlyViewed.splice(0, 1);
    },

    resetUser: (state) => {
      state.user = {};
    },
  },
});

export const {
  setShowLoginModal,
  setShowLoginModalTrue,
  setShowSignUpModal,
  setShowMenuModal,
  setShowForgotModal,
  setShowCheckYourEmailModal,
  setShowPasswordResetModal,
  setNewPasswordModal,
  setNewPasswordModalTrue,
  setToken,
  setUserEmail,
  setUser,
  setGeoInfo,
  setOverallPlanId,
  setRecentlyViewed,
  resetUser,
} = modalStateSlice.actions;
export default modalStateSlice.reducer;
