import React, { useEffect, useState } from "react";
import ENDPOINT from "../config/ENDPOINT";
import HTMLReactParser from "html-react-parser";

export const TermsAndConditions = () => {
  const [terms, setTerms] = useState({ page_desc: "" });

  async function fetchCmsPage() {
    let res = await fetch(ENDPOINT + `cms_page/single/${1}`);
    const data = await res.json();
    setTerms(data);
  }

  useEffect(() => {
    fetchCmsPage();
  }, []);

  return (
    <>
      <div className=" container">{HTMLReactParser(terms?.page_desc)}</div>
    </>
  );
};
