import { useEffect, useState } from "react";
import ENDPOINT from "../config/ENDPOINT";
import { VscStarFull, VscStarHalf } from "react-icons/vsc";
import HTMLReactParser from "html-react-parser";
import Slider from "react-slick";

export const SliderBottom = ({ service }) => {
  const [testimonial, setTestimonial] = useState([]);
  const [cat, setCat] = useState([]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };
  const dummyImage =
    "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg";

  async function fetchFaqCatBySlug() {
    let res = await fetch(ENDPOINT + `faq-cat/singleCat/${service}`);
    let data = await res.json();
    setCat(data);
  }

  useEffect(() => {
    fetchFaqCatBySlug();
  }, [service]);

  async function getTestimonial() {
    let res = await fetch(
      ENDPOINT + `testimonial/test_by_cat?cat_id=${cat.id}`
    );
    const data = await res.json();
    setTestimonial(data);
  }

  useEffect(() => {
    getTestimonial();
  }, [cat.id]);

  const SliderApp = (props) => {
    return (
      <>
        <div className="SlidercontentContainer">
          <div className="row justify-content-start g-1">
            <div className="col-3 testimg">
              <img
                width="100%"
                src={props.pic ? ENDPOINT + "uploads/" + props.pic : dummyImage}
                alt="demo"
              />
            </div>
            <div className="col-6 testname">
              <h5>{props.client_name}</h5>
            </div>
          </div>
          <div className="row justify-content-start g-3">
            <div className="col-12 testdescription">
              <small>{HTMLReactParser(props.desc)}</small>
            </div>
          </div>
          <div className="d-flex my-3 ratingstars justify-content-center g-1">
            {props.rating === "1" ? (
              <VscStarFull />
            ) : props.rating === "2" ? (
              <>
                <VscStarFull /> <VscStarHalf />
              </>
            ) : props.rating === "3" ? (
              <>
                <VscStarFull /> <VscStarFull /> <VscStarHalf />
              </>
            ) : props.rating === "4" ? (
              <>
                <VscStarFull /> <VscStarFull /> <VscStarFull /> <VscStarHalf />
              </>
            ) : (
              <>
                <VscStarFull /> <VscStarFull /> <VscStarFull /> <VscStarFull />
                <VscStarHalf />
              </>
            )}
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <Slider className="slidebluearrows my_slick_slider py-2" {...settings}>
        {testimonial.map((val, i) => {
          return <SliderApp key={i} {...val} />;
        })}
      </Slider>
    </>
  );
};
