import { NavLink } from "react-router-dom";
import Maxlenceintroimg from "../assests/maxlenceintroimg.png";
import Facebookcard from "../assests/facebookcard.svg";
import Linkedincard from "../assests/linkedincard.svg";
import Instagramcard from "../assests/instagramcard.svg";
import Pinterestcard from "../assests/pinterestcard.svg";
import Twittercard from "../assests/twittercard.svg";
import Youtubecard from "../assests/youtubecard.svg";
import Socialimpact from "../assests/socialimpact.jpg";
import Socialeffect from "../assests/socialeffect.jpg";
import Grsocialpng from "../assests/grsocialpng.png";
//
import { FooterContact } from "../components/FooterContact";
import Gadsclient from "../assests/gadsclient.png";
import Bottomfadestroke from "../assests/bottomfadestroke.png";

import GoogleAdsIcon from "../../../img/GoogleAdsIcon.svg";
import EmailMarketingIcon from "../../../img/EmailMarketingIcon.svg";
import VideoMarketingIcon from "../../../img/VideoMarketingIcon.svg";
import GMBIcon from "../../../img/GMBIcon.svg";
import SEOIcon from "../../../img/SEOIcon.svg";
import SocialMediaIcon from "../../../img/SocialMediaIcon.svg";
import CROIcon from "../../../img/CROIcon.svg";
import { Faqs } from "../components/Faqs";

const TopLinkData = [
  {
    linkName: "Google Ads",
    linkIcon: GoogleAdsIcon,
    link: "/growth/google-ads",
    activeNav: "none",
    linkNameColor: "",
  },
  {
    linkName: "CRO",
    linkIcon: CROIcon,
    link: "/growth/cro",
    activeNav: "none",
    linkNameColor: "",
  },
  {
    linkName: "Social Media",
    linkIcon: SocialMediaIcon,
    link: "/growth/social-media-marketing",
    activeNav: "block",
    linkNameColor: "black",
  },
  {
    linkName: "SEO",
    linkIcon: SEOIcon,
    link: "/growth/seo",
    activeNav: "none",
    linkNameColor: "",
  },
  {
    linkName: "GMB",
    linkIcon: GMBIcon,
    link: "/growth/gmb",
    activeNav: "none",
    linkNameColor: "",
  },
  {
    linkName: "Video Marketing",
    linkIcon: VideoMarketingIcon,
    link: "/growth/video-marketing",
    activeNav: "none",
    linkNameColor: "",
  },
  {
    linkName: "Email Marketing",
    linkIcon: EmailMarketingIcon,
    link: "/growth/email-marketing",
    activeNav: "none",
    linkNameColor: "",
  },
];

export const SocialMediaMarketing = () => {
  return (
    <>
      <div className=" creation_and_growth_top_div mb-2">
        <div className=" container">
          <div className="growth_top_div">
            {TopLinkData.map((val, i) => (
              <div key={i} className=" creation_and_growth_col_div">
                <NavLink to={val.link}>
                  <img src={val.linkIcon} alt="icon" />
                  <br />
                  <span style={{ color: val.linkNameColor }}>
                    {val.linkName}
                  </span>
                </NavLink>
                <div
                  style={{ display: val.activeNav }}
                  className="creation_and_growth_activ"
                ></div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className="container-fluid services_modal_page_div2"
        style={{ background: "#F5F5F5" }}
      >
        <div className="container py-5">
          <div className="row  justify-content-around align-items-center">
            <div className="col-12 col-md-12 col-lg-6 my-3 text-sm-left ">
              <h1 className="my-4 all_h1_title">
                Dynamic duo of social media: <span>Paid</span> and
                <span> Organic </span> <br /> Working together for you!
              </h1>
              <p className="all_h1_title_p">
                Paid or organic social? Why not both? Our team combines the best
                social media management strategies with the proven success of
                paid ads to ensure that you’re growing your business right where
                your customers are, at the right time.
              </p>
              {/* <div className="py-4">
                <NavLink
                  to="/growth/social-media-marketing/questionnaire"
                  className="getstartbtn  fontrale my-5 "
                >
                  SMM
                </NavLink>
                <div className="btn_div2">
                  <NavLink
                    to="/growth/social-media-optimisation/questionnaire"
                    className="getstartbtn  fontrale my-5 "
                  >
                    SMO
                  </NavLink>
                </div>
              </div> */}

              <div className="py-4 smo_btn_div">
                <div className="btn_div">
                  <NavLink
                    to="/growth/social-media-optimisation/questionnaire"
                    className="getstartbtn  fontrale my-5 "
                  >
                    SMO
                  </NavLink>
                </div>
                <div className="btn_div2">
                  <NavLink
                    to="/growth/social-media-marketing/questionnaire"
                    className="getstartbtn  fontrale my-5 "
                  >
                    SMM
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-6 my-2 my-sm-0 my-3 rounded text-center ">
              <img className="w-100" src={Maxlenceintroimg} alt="i" />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5" style={{ background: "#fff" }}>
        <div className="container seoservices">
          <div
            className="text-center my-3"
            style={{ visibility: "visible", animationName: "fadeInDown" }}
          >
            <h2 className="font-weight-bold my-sm-4 all_h1_title">
              We create business.
              <span> Choose any of these platforms </span>
            </h2>
          </div>

          <div className="row g-4">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 d-flex justify-content-center">
              <div>
                <img src={Facebookcard} alt="d" />
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 d-flex justify-content-center">
              <div>
                <img src={Instagramcard} alt="d" />
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 d-flex justify-content-center">
              <div>
                <img src={Linkedincard} alt="d" />
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 d-flex justify-content-center">
              <div>
                <img src={Pinterestcard} alt="d" />
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 d-flex justify-content-center">
              <div>
                <img src={Twittercard} alt="d" />
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 d-flex justify-content-center">
              <div>
                <img src={Youtubecard} alt="d" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-4">
        <div className="row justify-content-around align-items-center">
          <div className="col-12 col-md-12 col-lg-6 my-3  text-sm-left">
            <h2 className="my-4 all_h1_title">Our customer centric approach</h2>
            <p className="all_h1_title_p">
              Are you tired of feeling like your brand is out of your hands? Say
              hello to our customer-centric approach, where YOU are in the
              driver's seat! We believe in giving you the ultimate control over
              your brand, which is why we keep you in the loop at all stages
              simply with our client dashboard.
            </p>
          </div>
          <div className="col-12 col-md-12 col-lg-6 my-2 my-sm-0 my-3 rounded text-center">
            <img className="w-100" src={Socialimpact} alt="i" />
          </div>
        </div>
      </div>
      <div
        className="container-fluid py-3 latestprojects"
        style={{ backgroundColor: "#0a76e5" }}
      >
        <div className="container py-4">
          <div className="row justify-content-around align-items-center">
            <div className="col-md-6">
              <img className="w-100" src={Socialeffect} alt="cd" />
            </div>
            <div className="col-md-6 text-white text-md-right text-center">
              <h2 className="mb-5 all_h1_title">
                Is Social Media Marketing Effective?
              </h2>
              <p
                className="all_h1_title_p"
                style={{ color: "rgba(255, 255, 255, 0.7)" }}
              >
                Social media marketing is essential to every company’s growth
                and maturity. As a business, you must always be present where
                your customers are to inform them of deals and news, but also to
                be there when they have questions or concerns. Being present and
                accounted for is critical for customer relations and having a
                social media presence enables this. Here are some key
                considerations: Social media; enables collaboration with other
                industry experts, fosters a sense of community for current
                customers and drives new and reoccurring business to your
                doorstep, funnel, or website.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row justify-content-around align-items-center">
          <div className="col-12 my-3 text-center">
            <h2 className="my-4 all_h1_title">
              Maximize your impact with ads that captivate - let our team bring
              your brand to life!
            </h2>
            <p className="all_h1_title_p" style={{ color: "#212529b2" }}>
              Are you tired of struggling to make an impact on social media? Let
              our team of experts handle it for you! We specialize in organic
              social media marketing services that will get your brand noticed
              without the need for costly social ads. Don't let your competitors
              steal the spotlight - partner with us and let's take your social
              media game to the next level!
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid bg grsocialmedia bgproperty">
        <div className="container p-sm-5">
          <h2 className="text-center">How it works</h2>
          <div className="row my-4 justify-content-between align-items-center">
            <div className="col-sm-6 order-2 order-sm-0 text-center">
              <img className="w-100" src={Grsocialpng} alt="df" />
            </div>
            <div className="col-sm-6 col-12 my-3">
              <div className="maxpoint mb-4 wow animate__animated animate__fadeInLeft animate__slow">
                <h4 className="fontrobo" style={{ color: "#0a76e5" }}>
                  Research
                </h4>
                <p>
                  Precisely what’s going to give you the greatest possible
                  engagement and return on investment.
                </p>
              </div>

              <div className="maxpoint mb-4 wow animate__animated animate__fadeInLeft animate__slow">
                <h4 className="fontrobo" style={{ color: "#0a76e5" }}>
                  Create
                </h4>
                <p>
                  Phenomenal content that’s uniquely designed to spark
                  conversation and drive engagement.
                </p>
              </div>

              <div className="maxpoint mb-4 wow animate__animated animate__fadeInLeft animate__slow">
                <h4 className="fontrobo" style={{ color: "#0a76e5" }}>
                  Advertise
                </h4>
                <p>
                  In a way that optimises social ad platforms to maximise reach
                  while giving you the best bang for buck.
                </p>
              </div>

              <div className="maxpoint mb-4 wow animate__animated animate__fadeInLeft animate__slow">
                <h4 className="fontrobo" style={{ color: "#0a76e5" }}>
                  Manage
                </h4>
                <p>
                  Everything for you along the way to save you the headache and
                  keep it all humming along.
                </p>
              </div>

              <div className="maxpoint mb-4 wow animate__animated animate__fadeInLeft animate__slow">
                <h4 className="fontrobo" style={{ color: "#0a76e5" }}>
                  Measure & Optimize
                </h4>
                <p>
                  Ongoing content and campaign performance and fine tune and
                  optimise where we need to.
                </p>
              </div>

              <div className="py-4 smo_btn_div">
                <div className="btn_div">
                  <NavLink
                    to="/growth/social-media-optimisation/questionnaire"
                    className="getstartbtn  fontrale my-5 "
                  >
                    SMO
                  </NavLink>
                </div>
                <div className="btn_div2">
                  <NavLink
                    to="/growth/social-media-marketing/questionnaire"
                    className="getstartbtn  fontrale my-5 "
                  >
                    SMM
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-3 py-3 gads_clientquote">
        <div
          className="row justify-content-around my-4 p-5"
          style={{ backgroundColor: "#000" }}
        >
          <div className="row">
            <div className="text-center">
              <div>
                <h2 className="text-white">Our Clients Love The Results</h2>
              </div>
              <div className="my-3">
                <img src={Gadsclient} alt="i" />
              </div>
              <div className="client_compname">
                <p className="text-white mb-0">John Doe, Lorem Pvt Ltd</p>
                <img src={Bottomfadestroke} alt="i" />
              </div>
              <div>
                <h3 className="my-4" style={{ color: "#0a76e5" }}>
                  217% Increase in Targeted Traffic
                </h3>
              </div>
              <div>
                <p
                  className="all_h1_title_p"
                  style={{ color: "rgba(255, 255, 255, 0.7) " }}
                >
                  It is very clear the impact your team is making. You guys are
                  doing a great job on targeted leads. In fact, we closed a lead
                  directly through your marketing efforts last year that has
                  resulted in over half a million dollars in revenue!”
                </p>
                {/* <div className=" text-center py-4">
                  <NavLink
                    to="/growth/social-media-marketing/questionnaire"
                    className="getstartbtn  fontrale my-5 "
                  >
                    SMM
                  </NavLink>
                </div>
                <div className="btn_div2">
                  <NavLink
                    to="/growth/social-media-optimisation/questionnaire"
                    className="getstartbtn  fontrale my-5 "
                  >
                    SMO
                  </NavLink>
                </div> */}
                <div className="py-4 smo_btn_div2">
                  <div className="btn_div">
                    <NavLink
                      to="/growth/social-media-optimisation/questionnaire"
                      className="getstartbtn  fontrale my-5 "
                    >
                      SMO
                    </NavLink>
                  </div>
                  <div className="btn_div2">
                    <NavLink
                      to="/growth/social-media-marketing/questionnaire"
                      className="getstartbtn  fontrale my-5 "
                    >
                      SMM
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterContact title="We’d love to be your partner"/>
      <Faqs catId={8} />
    </>
  );
};
