import { NavLink, useNavigate } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
import * as React from "react";
import { MdDone } from "react-icons/md";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  AiOutlineQuestionCircle,
  AiTwotoneStar,
  AiOutlineCheckCircle,
} from "react-icons/ai";
import LikeIcon from "../../../img/like.svg";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HTMLReactParser from "html-react-parser";
import ENDPOINT from "../config/ENDPOINT";
import axios from "axios";

export const PackageCard = (props) => {
  const [expanded, setExpanded] = React.useState(false);
  const [packageFeatures, setPackageFeatures] = React.useState([]);
  const token = localStorage.getItem("token");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const fetchPackageFeaturesByQuesAns = async () => {
    let res = await fetch(ENDPOINT + `package_features/features_by_que_ans`, {
      headers: { "x-access-token": token },
    });
    if (res.ok) {
      let data = await res.json();
      setPackageFeatures(data);
    }
  };

  React.useEffect(() => {
    fetchPackageFeaturesByQuesAns();
  }, []);

  return (
    <>
      <div className="package_card_top_main">
        <div className="row">
          <div className="col-xxl-9 col-xl-9 col-lg-12 col-md-12 col-sm-12 col-12">
            <div>
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div className="mobile_tab_div">
                  <Nav
                    variant="pills"
                    className="d-flex justify-content-center"
                  >
                    <Nav.Item>
                      <Nav.Link eventKey="first">low</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">medium</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="mobile">high</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <Tab.Content className="mt-3">
                  <Tab.Pane eventKey="first">
                    <div className=" d-none d-xxl-block d-xl-block d-lg-block d-md-block">
                      <DesktopTab
                        PackageOneData={props.packages[0]}
                        PackagetwoData={props.packages[1]}
                        PackagethreeData={props.packages[2]}
                        packageFeatures={packageFeatures}
                        totalPackPrice={props.totalPackPrice}
                      />
                    </div>
                    <div className="d-block d-xxl-none d-xl-none d-lg-none d-md-none">
                      <PackageMobileUi
                        PackageOneData={props.packages[0]}
                        packageFeatures={packageFeatures}
                        totalPackPrice={props.totalPackPrice}
                        type="low"
                      />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <div className="d-none d-xxl-block d-xl-block d-lg-block d-md-block">
                      <DesktopTab
                        PackageOneData={props.packages[0]}
                        PackagetwoData={props.packages[1]}
                        PackagethreeData={props.packages[2]}
                        packageFeatures={packageFeatures}
                        totalPackPrice={props.totalPackPrice}
                      />
                    </div>
                    <div className="d-block d-xxl-none d-xl-none d-lg-none d-md-none">
                      <PackageMobileUi
                        PackageOneData={props.packages[1]}
                        packageFeatures={packageFeatures}
                        totalPackPrice={props.totalPackPrice}
                        type="mid"
                      />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="mobile">
                    <div className=" d-none d-xxl-block d-xl-block d-lg-block d-md-block">
                      <DesktopTab
                        PackageOneData={props.packages[0]}
                        PackagetwoData={props.packages[1]}
                        PackagethreeData={props.packages[2]}
                        packageFeatures={packageFeatures}
                        totalPackPrice={props.totalPackPrice}
                      />
                    </div>
                    <div className="d-block d-xxl-none d-xl-none d-lg-none d-md-none">
                      <PackageMobileUi
                        PackageOneData={props.packages[2]}
                        packageFeatures={packageFeatures}
                        totalPackPrice={props.totalPackPrice}
                        type="high"
                      />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
          <div className="col-xxl-3 col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className=" d-block d-xxl-none d-xl-none mt-3">
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: "80%", flexShrink: 0 }}>
                    More Information
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="package_card_top_right_inner_scroll">
                      <div className="package_card_top_right_inner_data">
                        <PackageRightCardUi
                          PackageRightCardUiData={props.details}
                        />
                      </div>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className=" d-none d-xxl-block d-xl-block ">
              <div className="package_card_top_right">
                <div className="package_card_top_right_inner_scroll">
                  <div className="package_card_top_right_inner_data">
                    <PackageRightCardUi
                      PackageRightCardUiData={props.details}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const DesktopTab = (props) => {
  return (
    <>
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <div className="desktop_tab_div">
          <div className="desktop_tab_div_title">
            <h3>
              Pick the perfect <span>pricing plan</span>
            </h3>
            <p>We have the right plans for you</p>
          </div>
          <Nav variant="pills" className="d-flex justify-content-center">
            <Nav.Item className="nav_start">
              <Nav.Link eventKey="first">Monthly</Nav.Link>
            </Nav.Item>
            {/* <Nav.Item className="nav_middle">
              <Nav.Link eventKey="second">Quarterly</Nav.Link>
            </Nav.Item> */}
            <Nav.Item className="nav_last">
              <Nav.Link eventKey="Desktop">Yearly</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <Tab.Content className="mt-5">
          <Tab.Pane eventKey="first">
            <PackageCardUi
              PackageOneData={props?.PackageOneData}
              PackagetwoData={props?.PackagetwoData}
              PackagethreeData={props?.PackagethreeData}
              packageFeatures={props?.packageFeatures}
              totalPackPrice={props?.totalPackPrice}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="Desktop">
            <PackageCardUiOne
              PackageOneData={props?.PackageOneData}
              PackagetwoData={props?.PackagetwoData}
              PackagethreeData={props?.PackagethreeData}
              packageFeatures={props?.packageFeatures}
              totalPackPrice={props?.totalPackPrice}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

const PackageCardUi = (props) => {
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const handleBuy = (id) => {
    try {
      axios({
        url: ENDPOINT + "cart",
        method: "POST",
        headers: { "x-access-token": token },
        data: { plan_price_id: id },
      }).then((res) => {
        if (res.status === 200) {
          navigate("/cart");
        } else if (res.status === 400) {
          console.log("Error while adding product to cart!");
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  let package1value = props?.PackageOneData?.package_plans[0]?.plan_prices;

  let package2value = props?.PackagetwoData?.package_plans[0]?.plan_prices;

  let package3value = props?.PackagethreeData?.package_plans[0]?.plan_prices;

  return (
    <div className="package_card_top_1">
      <div className="package_card_top_2">
        <div className="row m-0">
          <div className="col-3 p-0 package_card_top_4"></div>
          {/* Package One up  */}
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 package_card_col">
            {/* Package One up  */}
            <div className="package_card package_card_up">
              <div
                className="package_card_recommended_div"
                style={{ display: props?.PackageOneData?.most_popular }}
              >
                <p>Recommended For You</p>
              </div>
              <div className="package_card_header">
                <h3 style={{ color: props?.PackageOneData?.color }}>
                  {props?.PackageOneData?.title}
                </h3>
                <h4>
                  {package1value?.[0]?.itf_country?.currency}
                  {package1value?.[0]?.price +
                    Number(props?.totalPackPrice[0]?.totalPrice)}
                </h4>
                <div>
                  <p>Overview:</p>
                  {HTMLReactParser(props?.PackageOneData?.overview || "")}
                </div>
                <NavLink
                  onClick={() =>
                    // handleBuy(props?.PackageOneData?.package_plans[0].id)
                    handleBuy(package1value?.[0]?.id)
                  }
                >
                  Buy now
                </NavLink>
              </div>
            </div>
            {/* Package One end up */}
          </div>
          {/* Package One end up */}
          {/* Package two up  */}
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 package_card_col">
            {/* {props.PackagetwoData.map((packageTwo, i) => ( */}
            <div className="package_card package_card_up">
              <div
                className="package_card_recommended_div"
                style={{ display: props?.PackagetwoData?.most_popular }}
              >
                <p>Recommended For You</p>
              </div>
              <div className="package_card_header">
                <h3 style={{ color: props?.PackagetwoData?.color }}>
                  {props?.PackagetwoData?.title}
                </h3>
                <h4>
                  {package2value?.[0]?.itf_country?.currency}
                  {package2value?.[0]?.price +
                    Number(props?.totalPackPrice[0]?.totalPrice)}
                </h4>
                <div>
                  <p>Overview:</p>
                  {HTMLReactParser(props?.PackagetwoData?.overview || "")}
                </div>
                <NavLink
                  onClick={() =>
                    // handleBuy(props?.PackagetwoData?.package_plans[0].id)
                    handleBuy(package2value?.[0]?.id)
                  }
                >
                  Buy now
                </NavLink>
              </div>
            </div>
            {/* ))} */}
          </div>
          {/* Package two end up */}
          {/* Package three up  */}
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 package_card_col">
            {/* {props.PackagethreeData.map((packageThree, i) => ( */}
            <div className="package_card package_card_up">
              <div
                className="package_card_recommended_div"
                style={{ display: props?.PackagethreeData?.most_popular }}
              >
                <p>Recommended For You</p>
              </div>
              <div className="package_card_header">
                <h3 style={{ color: props?.PackagethreeData?.color }}>
                  {props?.PackagethreeData?.title}
                </h3>
                <h4>
                  {package3value?.[0]?.itf_country?.currency}
                  {package3value?.[0]?.price +
                    Number(props?.totalPackPrice[0]?.totalPrice)}
                </h4>
                <div>
                  <p>Overview:</p>
                  {HTMLReactParser(props?.PackagethreeData?.overview || "")}
                </div>
                <NavLink
                  onClick={() =>
                    // handleBuy(props?.PackagethreeData?.package_plans[0].id)
                    handleBuy(package3value?.[0]?.id)
                  }
                >
                  Buy now
                </NavLink>
              </div>
            </div>
            {/* ))} */}
          </div>
          {/* Package three end up */}
        </div>
      </div>
      <div className="package_card_top_3">
        <div className="row m-0">
          <div className="col-3 p-0 package_card_top_4"></div>
          {/* Package One dowm */}
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 package_card_col">
            <div className="package_card package_card_bottom">
              <div className="package_card_recommended_div2">
                <div
                  className="package_card_recommended_div"
                  style={{ display: props?.PackageOneData?.most_popular }}
                >
                  <p>Recommended For You</p>
                </div>
              </div>
              <div className="package_card_header">
                <h3 style={{ color: props?.PackageOneData?.color }}>
                  {props?.PackageOneData?.title}
                </h3>
                <h4>
                  {package1value?.[0]?.itf_country?.currency}
                  {package1value?.[0]?.price +
                    Number(props?.totalPackPrice[0]?.totalPrice)}
                </h4>
                <div>
                  <p>Overview:</p>
                  {HTMLReactParser(props?.PackageOneData?.overview || "")}
                </div>
                <NavLink
                  onClick={() =>
                    // handleBuy(props?.PackageOneData?.package_plans[0].id)
                    handleBuy(package1value?.[0]?.id)
                  }
                >
                  Buy now
                </NavLink>
              </div>
              {props?.packageFeatures?.map((val, i) =>
                i === 0 ? (
                  <div>
                    <div
                      key={i}
                      className="package_card_box"
                      style={{
                        backgroundColor: val.background_color,
                        height:
                          props?.PackageOneData?.feature_height || "30rem",
                      }}
                    >
                      <h3>{val.title}</h3>
                      {val.package_features.map((val, i) => (
                        <div key={i}>
                          {val.package_type.includes(
                            props?.PackageOneData.id
                          ) ? (
                            <ul>
                              <li>
                                <MdDone />
                              </li>
                              <li>
                                {val.title}
                                <BootstrapTooltip
                                  title={val.desc}
                                  placement={val.boxPointTooltipPlacement}
                                >
                                  <span
                                    style={{
                                      display: val.desc ? "inline" : "none",
                                    }}
                                  >
                                    <AiOutlineQuestionCircle />
                                  </span>
                                </BootstrapTooltip>
                              </li>
                            </ul>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : i === 1 ? (
                  <div>
                    <div
                      key={i}
                      className="package_card_box"
                      style={{
                        backgroundColor: val.background_color,
                        height: props?.PackageOneData?.result_height || "30rem",
                      }}
                    >
                      <h3>{val.title}</h3>
                      {val.package_features.map((val, i) => (
                        <div key={i}>
                          {val.package_type.includes(
                            props?.PackageOneData.id
                          ) ? (
                            <ul>
                              <li>
                                <MdDone />
                              </li>
                              <li>
                                {val.title}
                                <BootstrapTooltip
                                  title={val.desc}
                                  placement={val.boxPointTooltipPlacement}
                                >
                                  <span
                                    style={{
                                      display: val.desc ? "inline" : "none",
                                    }}
                                  >
                                    <AiOutlineQuestionCircle />
                                  </span>
                                </BootstrapTooltip>
                              </li>
                            </ul>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : i === 2 ? (
                  <div>
                    <div
                      key={i}
                      className="package_card_box"
                      style={{
                        backgroundColor: val.background_color,
                        height:
                          props?.PackageOneData?.isitforyou_height || "30rem",
                      }}
                    >
                      <h3>{val.title}</h3>
                      {val.package_features.map((val, i) => (
                        <div key={i}>
                          {val.package_type.includes(
                            props?.PackageOneData.id
                          ) ? (
                            <ul>
                              <li>
                                <MdDone />
                              </li>
                              <li>
                                {val.title}
                                <BootstrapTooltip
                                  title={val.desc}
                                  placement={val.boxPointTooltipPlacement}
                                >
                                  <span
                                    style={{
                                      display: val.desc ? "inline" : "none",
                                    }}
                                  >
                                    <AiOutlineQuestionCircle />
                                  </span>
                                </BootstrapTooltip>
                              </li>
                            </ul>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null
              )}
            </div>
          </div>
          {/* Package One dowm end */}
          {/* Package two dowm */}
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 package_card_col">
            <div className="package_card package_card_bottom">
              <div className="package_card_recommended_div2">
                <div
                  className="package_card_recommended_div"
                  style={{ display: props?.PackagetwoData?.most_popular }}
                >
                  <p>Recommended For You</p>
                </div>
              </div>
              <div className="package_card_header">
                <h3 style={{ color: props?.PackagetwoData?.color }}>
                  {props?.PackagetwoData?.title}
                </h3>
                <h4>
                  {package2value?.[0]?.itf_country?.currency}
                  {package2value?.[0]?.price +
                    Number(props?.totalPackPrice[0]?.totalPrice)}
                </h4>
                <div>
                  <p>Overview:</p>
                  {HTMLReactParser(props?.PackagetwoData?.overview || "")}
                </div>
                <NavLink
                  onClick={() =>
                    // handleBuy(props?.PackagetwoData?.package_plans[0].id)
                    handleBuy(package2value?.[0]?.id)
                  }
                >
                  Buy now
                </NavLink>
              </div>
              {props?.packageFeatures?.map((val, i) =>
                i === 0 ? (
                  <div>
                    <div
                      key={i}
                      className="package_card_box"
                      style={{
                        backgroundColor: val.background_color,
                        height:
                          props?.PackagetwoData?.feature_height || "30rem",
                      }}
                    >
                      {val.package_features.map((val, i) => (
                        <div key={i}>
                          {val.package_type.includes(
                            props?.PackagetwoData.id
                          ) ? (
                            <ul>
                              <li>
                                <MdDone />
                              </li>
                              <li>
                                {val.title}
                                <BootstrapTooltip
                                  title={val.desc}
                                  placement={val.boxPointTooltipPlacement}
                                >
                                  <span
                                    style={{
                                      display: val.desc ? "inline" : "none",
                                    }}
                                  >
                                    <AiOutlineQuestionCircle />
                                  </span>
                                </BootstrapTooltip>
                              </li>
                            </ul>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : i === 1 ? (
                  <div>
                    <div
                      key={i}
                      className="package_card_box"
                      style={{
                        backgroundColor: val.background_color,
                        height: props?.PackagetwoData?.result_height || "30rem",
                      }}
                    >
                      {val.package_features.map((val, i) => (
                        <div key={i}>
                          {val.package_type.includes(
                            props?.PackagetwoData.id
                          ) ? (
                            <ul>
                              <li>
                                <MdDone />
                              </li>
                              <li>
                                {val.title}
                                <BootstrapTooltip
                                  title={val.desc}
                                  placement={val.boxPointTooltipPlacement}
                                >
                                  <span
                                    style={{
                                      display: val.desc ? "inline" : "none",
                                    }}
                                  >
                                    <AiOutlineQuestionCircle />
                                  </span>
                                </BootstrapTooltip>
                              </li>
                            </ul>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : i === 2 ? (
                  <div>
                    <div
                      key={i}
                      className="package_card_box"
                      style={{
                        backgroundColor: val.background_color,
                        height:
                          props?.PackagetwoData?.isitforyou_height || "30rem",
                      }}
                    >
                      {val.package_features.map((val, i) => (
                        <div key={i}>
                          {val.package_type.includes(
                            props?.PackagetwoData.id
                          ) ? (
                            <ul>
                              <li>
                                <MdDone />
                              </li>
                              <li>
                                {val.title}
                                <BootstrapTooltip
                                  title={val.desc}
                                  placement={val.boxPointTooltipPlacement}
                                >
                                  <span
                                    style={{
                                      display: val.desc ? "inline" : "none",
                                    }}
                                  >
                                    <AiOutlineQuestionCircle />
                                  </span>
                                </BootstrapTooltip>
                              </li>
                            </ul>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null
              )}
            </div>
          </div>
          {/* Package two dowm end */}
          {/* Package three dowm */}
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 package_card_col">
            {/* {props.PackagethreeData.map((packageThree, i) => ( */}
            <div className="package_card package_card_bottom">
              <div className="package_card_recommended_div2">
                <div
                  className="package_card_recommended_div"
                  style={{ display: props?.PackagethreeData?.most_popular }}
                >
                  <p>Recommended For You</p>
                </div>
              </div>
              <div className="package_card_header">
                <h3 style={{ color: props?.PackagethreeData?.color }}>
                  {props?.PackagethreeData?.title}
                </h3>
                <h4>
                  {package3value?.[0]?.itf_country?.currency}
                  {package3value?.[0]?.price +
                    Number(props?.totalPackPrice[0]?.totalPrice)}
                </h4>
                <div>
                  <p>Overview:</p>
                  {HTMLReactParser(props?.PackagethreeData?.overview || "")}
                </div>
                <NavLink
                  onClick={() =>
                    // handleBuy(props?.PackagethreeData?.package_plans[0].id)
                    handleBuy(package3value?.[0]?.id)
                  }
                >
                  Buy now
                </NavLink>
              </div>
              {props?.packageFeatures?.map((val, i) =>
                i === 0 ? (
                  <div>
                    <div
                      key={i}
                      className="package_card_box"
                      style={{
                        backgroundColor: val.background_color,
                        height:
                          props?.PackagethreeData?.feature_height || "30rem",
                      }}
                    >
                      {val.package_features.map((val, i) => (
                        <div key={i}>
                          {val.package_type.includes(
                            props?.PackagethreeData.id
                          ) ? (
                            <ul>
                              <li>
                                <MdDone />
                              </li>
                              <li>
                                {val.title}
                                <BootstrapTooltip
                                  title={val.desc}
                                  placement={val.boxPointTooltipPlacement}
                                >
                                  <span
                                    style={{
                                      display: val.desc ? "inline" : "none",
                                    }}
                                  >
                                    <AiOutlineQuestionCircle />
                                  </span>
                                </BootstrapTooltip>
                              </li>
                            </ul>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : i === 1 ? (
                  <div>
                    <div
                      key={i}
                      className="package_card_box"
                      style={{
                        backgroundColor: val.background_color,
                        height:
                          props?.PackagethreeData?.result_height || "30rem",
                      }}
                    >
                      {val.package_features.map((val, i) => (
                        <div key={i}>
                          {val.package_type.includes(
                            props?.PackagethreeData.id
                          ) ? (
                            <ul>
                              <li>
                                <MdDone />
                              </li>
                              <li>
                                {val.title}
                                <BootstrapTooltip
                                  title={val.desc}
                                  placement={val.boxPointTooltipPlacement}
                                >
                                  <span
                                    style={{
                                      display: val.desc ? "inline" : "none",
                                    }}
                                  >
                                    <AiOutlineQuestionCircle />
                                  </span>
                                </BootstrapTooltip>
                              </li>
                            </ul>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : i === 2 ? (
                  <div>
                    <div
                      key={i}
                      className="package_card_box"
                      style={{
                        backgroundColor: val.background_color,
                        height:
                          props?.PackagethreeData?.isitforyou_height || "30rem",
                      }}
                    >
                      {val.package_features.map((val, i) => (
                        <div key={i}>
                          {val.package_type.includes(
                            props?.PackagethreeData.id
                          ) ? (
                            <ul>
                              <li>
                                <MdDone />
                              </li>
                              <li>
                                {val.title}
                                <BootstrapTooltip
                                  title={val.desc}
                                  placement={val.boxPointTooltipPlacement}
                                >
                                  <span
                                    style={{
                                      display: val.desc ? "inline" : "none",
                                    }}
                                  >
                                    <AiOutlineQuestionCircle />
                                  </span>
                                </BootstrapTooltip>
                              </li>
                            </ul>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null
              )}
            </div>
            {/* ))} */}
          </div>
          {/* Package three dowm end */}
        </div>
      </div>
    </div>
  );
};

const PackageCardUiOne = (props) => {
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const handleBuy = (id) => {
    try {
      axios({
        url: ENDPOINT + "cart",
        method: "POST",
        headers: { "x-access-token": token },
        data: { package_plan_id: id },
      }).then((res) => {
        if (res.status === 200) {
          navigate("/cart");
        } else if (res.status === 400) {
          console.log("Error while adding product to cart!");
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  let package1value = props?.PackageOneData?.package_plans[1]?.plan_prices;

  let package2value = props?.PackagetwoData?.package_plans[1]?.plan_prices;

  let package3value = props?.PackagethreeData?.package_plans[1]?.plan_prices;

  return (
    <div className="package_card_top_1">
      <div className="package_card_top_2">
        <div className="row m-0">
          <div className="col-3 p-0 package_card_top_4"></div>
          {/* Package One up  */}
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 package_card_col">
            {/* Package One up  */}
            {/* {props.PackageOneData.map((packageOne, i) => ( */}
            <div className="package_card package_card_up">
              <div
                className="package_card_recommended_div"
                style={{ display: props?.PackageOneData?.most_popular }}
              >
                <p>Recommended For You</p>
              </div>
              <div className="package_card_header">
                <h3 style={{ color: props?.PackageOneData?.color }}>
                  {props?.PackageOneData?.title}
                </h3>
                <h4>
                  {package1value?.[0]?.itf_country?.currency}
                  {package1value?.[0]?.price +
                    Number(props?.totalPackPrice[0]?.totalPrice)}
                </h4>
                <div>
                  <p>Overview:</p>
                  {HTMLReactParser(props?.PackageOneData?.overview || "")}
                </div>
                <NavLink
                  onClick={() =>
                    // handleBuy(props?.PackageOneData?.package_plans[1].id)
                    handleBuy(package1value?.[0]?.id)
                  }
                >
                  Buy now
                </NavLink>
              </div>
            </div>
            {/* ))} */}
            {/* Package One end up */}
          </div>
          {/* Package One end up */}
          {/* Package two up  */}
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 package_card_col">
            {/* {props.PackagetwoData.map((packageTwo, i) => ( */}
            <div className="package_card package_card_up">
              <div
                className="package_card_recommended_div"
                style={{ display: props?.PackagetwoData?.most_popular }}
              >
                <p>Recommended For You</p>
              </div>
              <div className="package_card_header">
                <h3 style={{ color: props?.PackagetwoData?.color }}>
                  {props?.PackagetwoData?.title}
                </h3>
                <h4>
                  {package2value?.[0]?.itf_country?.currency}
                  {package2value?.[0]?.price +
                    Number(props?.totalPackPrice[0]?.totalPrice)}
                </h4>
                <div>
                  <p>Overview:</p>
                  {HTMLReactParser(props?.PackagetwoData?.overview || "")}
                </div>
                <NavLink
                  onClick={() =>
                    // handleBuy(props?.PackagetwoData?.package_plans[1].id)
                    handleBuy(package2value?.[0]?.id)
                  }
                >
                  Buy now
                </NavLink>
              </div>
            </div>
            {/* ))} */}
          </div>
          {/* Package two end up */}
          {/* Package three up  */}
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 package_card_col">
            {/* {props.PackagethreeData.map((packageThree, i) => ( */}
            <div className="package_card package_card_up">
              <div
                className="package_card_recommended_div"
                style={{ display: props?.PackagethreeData?.most_popular }}
              >
                <p>Recommended For You</p>
              </div>
              <div className="package_card_header">
                <h3 style={{ color: props?.PackagethreeData?.color }}>
                  {props?.PackagethreeData?.title}
                </h3>
                <h4>
                  {package3value?.[0]?.itf_country?.currency}
                  {package3value?.[0]?.price +
                    Number(props?.totalPackPrice[0]?.totalPrice)}
                </h4>
                <div>
                  <p>Overview:</p>
                  {HTMLReactParser(props?.PackagethreeData?.overview || "")}
                </div>
                <NavLink
                  onClick={() =>
                    // handleBuy(props?.PackagethreeData?.package_plans[1].id)
                    handleBuy(package3value?.[0]?.id)
                  }
                >
                  Buy now
                </NavLink>
              </div>
            </div>
            {/* ))} */}
          </div>
          {/* Package three end up */}
        </div>
      </div>
      <div className="package_card_top_3">
        <div className="row m-0">
          <div className="col-3 p-0 package_card_top_4"></div>
          {/* Package One dowm */}
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 package_card_col">
            <div className="package_card package_card_bottom">
              <div className="package_card_recommended_div2">
                <div
                  className="package_card_recommended_div"
                  style={{ display: props?.PackageOneData?.most_popular }}
                >
                  <p>Recommended For You</p>
                </div>
              </div>
              <div className="package_card_header">
                <h3 style={{ color: props?.PackageOneData?.color }}>
                  {props?.PackageOneData?.title}
                </h3>
                <h4>
                  {package1value?.[0]?.itf_country?.currency}
                  {package1value?.[0]?.price +
                    Number(props?.totalPackPrice[0]?.totalPrice)}
                </h4>
                <div>
                  <p>Overview:</p>
                  {HTMLReactParser(props?.PackageOneData?.overview || "")}
                </div>
                <NavLink
                  onClick={() =>
                    // handleBuy(props?.PackageOneData?.package_plans[1].id)
                    handleBuy(package1value?.[0]?.id)
                  }
                >
                  Buy now
                </NavLink>
              </div>
              {props?.packageFeatures?.map((val, i) =>
                i === 0 ? (
                  <div>
                    <div
                      key={i}
                      className="package_card_box"
                      style={{
                        backgroundColor: val.background_color,
                        height:
                          props?.PackageOneData?.feature_height || "30rem",
                      }}
                    >
                      <h3>{val.title}</h3>
                      {val.package_features.map((val, i) => (
                        <div key={i}>
                          {val.package_type.includes(
                            props?.PackageOneData.id
                          ) ? (
                            <ul>
                              <li>
                                <MdDone />
                              </li>
                              <li>
                                {val.title}
                                <BootstrapTooltip
                                  title={val.desc}
                                  placement={val.boxPointTooltipPlacement}
                                >
                                  <span
                                    style={{
                                      display: val.desc ? "inline" : "none",
                                    }}
                                  >
                                    <AiOutlineQuestionCircle />
                                  </span>
                                </BootstrapTooltip>
                              </li>
                            </ul>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : i === 1 ? (
                  <div>
                    <div
                      key={i}
                      className="package_card_box"
                      style={{
                        backgroundColor: val.background_color,
                        height: props?.PackageOneData?.result_height || "30rem",
                      }}
                    >
                      <h3>{val.title}</h3>
                      {val.package_features.map((val, i) => (
                        <div key={i}>
                          {val.package_type.includes(
                            props?.PackageOneData.id
                          ) ? (
                            <ul>
                              <li>
                                <MdDone />
                              </li>
                              <li>
                                {val.title}
                                <BootstrapTooltip
                                  title={val.desc}
                                  placement={val.boxPointTooltipPlacement}
                                >
                                  <span
                                    style={{
                                      display: val.desc ? "inline" : "none",
                                    }}
                                  >
                                    <AiOutlineQuestionCircle />
                                  </span>
                                </BootstrapTooltip>
                              </li>
                            </ul>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : i === 2 ? (
                  <div>
                    <div
                      key={i}
                      className="package_card_box"
                      style={{
                        backgroundColor: val.background_color,
                        height:
                          props?.PackageOneData?.isitforyou_height || "30rem",
                      }}
                    >
                      <h3>{val.title}</h3>
                      {val.package_features.map((val, i) => (
                        <div key={i}>
                          {val.package_type.includes(
                            props?.PackageOneData.id
                          ) ? (
                            <ul>
                              <li>
                                <MdDone />
                              </li>
                              <li>
                                {val.title}
                                <BootstrapTooltip
                                  title={val.desc}
                                  placement={val.boxPointTooltipPlacement}
                                >
                                  <span
                                    style={{
                                      display: val.desc ? "inline" : "none",
                                    }}
                                  >
                                    <AiOutlineQuestionCircle />
                                  </span>
                                </BootstrapTooltip>
                              </li>
                            </ul>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null
              )}
            </div>
          </div>
          {/* Package One dowm end */}
          {/* Package two dowm */}
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 package_card_col">
            <div className="package_card package_card_bottom">
              <div className="package_card_recommended_div2">
                <div
                  className="package_card_recommended_div"
                  style={{ display: props?.PackagetwoData?.most_popular }}
                >
                  <p>Recommended For You</p>
                </div>
              </div>
              <div className="package_card_header">
                <h3 style={{ color: props?.PackagetwoData?.color }}>
                  {props?.PackagetwoData?.title}
                </h3>
                <h4>
                  {package2value?.[0]?.itf_country?.currency}
                  {package2value?.[0]?.price +
                    Number(props?.totalPackPrice[0]?.totalPrice)}
                </h4>
                <div>
                  <p>Overview:</p>
                  {HTMLReactParser(props?.PackagetwoData?.overview || "")}
                </div>
                <NavLink
                  onClick={() =>
                    // handleBuy(props?.PackagetwoData?.package_plans[1].id)
                    handleBuy(package2value?.[0]?.id)
                  }
                >
                  Buy now
                </NavLink>
              </div>
              {props?.packageFeatures?.map((val, i) =>
                i === 0 ? (
                  <div>
                    <div
                      key={i}
                      className="package_card_box"
                      style={{
                        backgroundColor: val.background_color,
                        height:
                          props?.PackagetwoData?.feature_height || "30rem",
                      }}
                    >
                      {val.package_features.map((val, i) => (
                        <div key={i}>
                          {val.package_type.includes(
                            props?.PackagetwoData.id
                          ) ? (
                            <ul>
                              <li>
                                <MdDone />
                              </li>
                              <li>
                                {val.title}
                                <BootstrapTooltip
                                  title={val.desc}
                                  placement={val.boxPointTooltipPlacement}
                                >
                                  <span
                                    style={{
                                      display: val.desc ? "inline" : "none",
                                    }}
                                  >
                                    <AiOutlineQuestionCircle />
                                  </span>
                                </BootstrapTooltip>
                              </li>
                            </ul>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : i === 1 ? (
                  <div>
                    <div
                      key={i}
                      className="package_card_box"
                      style={{
                        backgroundColor: val.background_color,
                        height: props?.PackagetwoData?.result_height || "30rem",
                      }}
                    >
                      {val.package_features.map((val, i) => (
                        <div key={i}>
                          {val.package_type.includes(
                            props?.PackagetwoData.id
                          ) ? (
                            <ul>
                              <li>
                                <MdDone />
                              </li>
                              <li>
                                {val.title}
                                <BootstrapTooltip
                                  title={val.desc}
                                  placement={val.boxPointTooltipPlacement}
                                >
                                  <span
                                    style={{
                                      display: val.desc ? "inline" : "none",
                                    }}
                                  >
                                    <AiOutlineQuestionCircle />
                                  </span>
                                </BootstrapTooltip>
                              </li>
                            </ul>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : i === 2 ? (
                  <div>
                    <div
                      key={i}
                      className="package_card_box"
                      style={{
                        backgroundColor: val.background_color,
                        height:
                          props?.PackagetwoData?.isitforyou_height || "30rem",
                      }}
                    >
                      {val.package_features.map((val, i) => (
                        <div key={i}>
                          {val.package_type.includes(
                            props?.PackagetwoData.id
                          ) ? (
                            <ul>
                              <li>
                                <MdDone />
                              </li>
                              <li>
                                {val.title}
                                <BootstrapTooltip
                                  title={val.desc}
                                  placement={val.boxPointTooltipPlacement}
                                >
                                  <span
                                    style={{
                                      display: val.desc ? "inline" : "none",
                                    }}
                                  >
                                    <AiOutlineQuestionCircle />
                                  </span>
                                </BootstrapTooltip>
                              </li>
                            </ul>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null
              )}
            </div>
          </div>
          {/* Package two dowm end */}
          {/* Package three dowm */}
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 package_card_col">
            <div className="package_card package_card_bottom">
              <div className="package_card_recommended_div2">
                <div
                  className="package_card_recommended_div"
                  style={{ display: props?.PackagethreeData?.most_popular }}
                >
                  <p>Recommended For You</p>
                </div>
              </div>
              <div className="package_card_header">
                <h3 style={{ color: props?.PackagethreeData?.color }}>
                  {props?.PackagethreeData?.title}
                </h3>
                <h4>
                  {package3value?.[0]?.itf_country?.currency}
                  {package3value?.[0]?.price +
                    Number(props?.totalPackPrice[0]?.totalPrice)}
                </h4>
                <div>
                  <p>Overview:</p>
                  {HTMLReactParser(props?.PackagethreeData?.overview || "")}
                </div>
                <NavLink
                  onClick={() =>
                    // handleBuy(props?.PackagethreeData?.package_plans[1].id)
                    handleBuy(package3value?.[0]?.id)
                  }
                >
                  Buy now
                </NavLink>
              </div>
              {props?.packageFeatures?.map((val, i) =>
                i === 0 ? (
                  <div>
                    <div
                      key={i}
                      className="package_card_box"
                      style={{
                        backgroundColor: val.background_color,
                        height:
                          props?.PackagethreeData?.feature_height || "30rem",
                      }}
                    >
                      {val.package_features.map((val, i) => (
                        <div key={i}>
                          {val.package_type.includes(
                            props?.PackagethreeData.id
                          ) ? (
                            <ul>
                              <li>
                                <MdDone />
                              </li>
                              <li>
                                {val.title}
                                <BootstrapTooltip
                                  title={val.desc}
                                  placement={val.boxPointTooltipPlacement}
                                >
                                  <span
                                    style={{
                                      display: val.desc ? "inline" : "none",
                                    }}
                                  >
                                    <AiOutlineQuestionCircle />
                                  </span>
                                </BootstrapTooltip>
                              </li>
                            </ul>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : i === 1 ? (
                  <div>
                    <div
                      key={i}
                      className="package_card_box"
                      style={{
                        backgroundColor: val.background_color,
                        height:
                          props?.PackagethreeData?.result_height || "30rem",
                      }}
                    >
                      {val.package_features.map((val, i) => (
                        <div key={i}>
                          {val.package_type.includes(
                            props?.PackagethreeData.id
                          ) ? (
                            <ul>
                              <li>
                                <MdDone />
                              </li>
                              <li>
                                {val.title}
                                <BootstrapTooltip
                                  title={val.desc}
                                  placement={val.boxPointTooltipPlacement}
                                >
                                  <span
                                    style={{
                                      display: val.desc ? "inline" : "none",
                                    }}
                                  >
                                    <AiOutlineQuestionCircle />
                                  </span>
                                </BootstrapTooltip>
                              </li>
                            </ul>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : i === 2 ? (
                  <div>
                    <div
                      key={i}
                      className="package_card_box"
                      style={{
                        backgroundColor: val.background_color,
                        height:
                          props?.PackagethreeData?.isitforyou_height || "30rem",
                      }}
                    >
                      {val.package_features.map((val, i) => (
                        <div key={i}>
                          {val.package_type.includes(
                            props?.PackagethreeData.id
                          ) ? (
                            <ul>
                              <li>
                                <MdDone />
                              </li>
                              <li>
                                {val.title}
                                <BootstrapTooltip
                                  title={val.desc}
                                  placement={val.boxPointTooltipPlacement}
                                >
                                  <span
                                    style={{
                                      display: val.desc ? "inline" : "none",
                                    }}
                                  >
                                    <AiOutlineQuestionCircle />
                                  </span>
                                </BootstrapTooltip>
                              </li>
                            </ul>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null
              )}
            </div>
          </div>
          {/* Package three dowm end */}
        </div>
      </div>
    </div>
  );
};

const PackageRightCardUi = (props) => {
  return props?.PackageRightCardUiData?.map((val, i) => {
    let results = JSON.parse(val.results);
    let highlights = JSON.parse(val.highlights);

    return (
      <div key={i}>
        <div className="package_card_top_right_top_title">
          <p>Highly Rated By Clients For</p>
          <span>
            {val.rating} <AiTwotoneStar />
          </span>
        </div>
        <div className="row g-2 mt-1">
          {results.map((val, i) => (
            <div
              key={i}
              className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
            >
              <div className="package_card_top_right_like_div">
                <img src={LikeIcon} alt="like" />
                <p>{val} </p>
              </div>
            </div>
          ))}
          <div className="col-12">
            <hr />
          </div>
        </div>
        <div className="package_card_top_right_highlights_div">
          <h3>Highlights</h3>
          <div className="package_card_box p-0">
            {highlights.map((val, i) => (
              <ul key={i}>
                <li>
                  <AiOutlineCheckCircle />
                </li>
                <li>{val}</li>
              </ul>
            ))}
          </div>
          <hr />
        </div>
        <div className="package_card_top_right_highlights_div">
          <h3>Definitions</h3>
          <div className="row g-3">
            {/* {val.DefinitionsData.map((val, i) => (
            <div key={i} className="col-12">
              <p>
                <b>{val.title} :</b> {val.text}
              </p>
            </div>
          ))} */}
            {HTMLReactParser(val.definitions || "")}
          </div>
        </div>
      </div>
    );
  });
};
const PackageMobileUi = (props) => {
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const handleBuy = (id) => {
    try {
      axios({
        url: ENDPOINT + "cart",
        method: "POST",
        headers: { "x-access-token": token },
        data: { plan_price_id: id },
      }).then((res) => {
        if (res.status === 200) {
          navigate("/cart");
        } else if (res.status === 400) {
          console.log("Error while adding product to cart!");
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  let packageMonthlyValue =
    props?.PackageOneData?.package_plans[0]?.plan_prices;

  let packageYearlyValue = props?.PackageOneData?.package_plans[1]?.plan_prices;

  return (
    <>
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <div className="desktop_tab_div">
          <div className="desktop_tab_div_title">
            <h3>
              Pick the perfect <span>pricing plan</span>
            </h3>
            <p>We have the right plans for you</p>
          </div>
          <Nav variant="pills" className="d-flex justify-content-center">
            <Nav.Item className="nav_start">
              <Nav.Link eventKey="first">Monthly</Nav.Link>
            </Nav.Item>
            <Nav.Item className="nav_last">
              <Nav.Link eventKey="Desktop">Yearly</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <Tab.Content className="mt-5">
          <Tab.Pane eventKey="first">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 package_card_col">
              <div className="package_card package_card_bottom">
                <div className="package_card_recommended_div2">
                  <div
                    className="package_card_recommended_div"
                    style={{ display: props?.PackageOneData?.most_popular }}
                  >
                    <p>Recommended For You</p>
                  </div>
                </div>
                <div className="package_card_header">
                  <h3 style={{ color: props?.PackageOneData?.color }}>
                    {props?.PackageOneData?.title}
                  </h3>
                  {/* <h4>
                    ₹
                    {props?.PackageOneData?.package_plans[0].price +
                      Number(props?.totalPackPrice[0]?.totalPrice)}
                    
                  </h4> */}
                  <h4>
                    {packageMonthlyValue?.[0]?.itf_country?.currency}
                    {packageMonthlyValue?.[0]?.price +
                      Number(props?.totalPackPrice[0]?.totalPrice)}
                  </h4>
                  <div>
                    <p>Overview:</p>
                    {HTMLReactParser(props?.PackageOneData?.overview || "")}
                  </div>
                  <NavLink
                    onClick={() =>
                      // handleBuy(props?.PackageOneData?.package_plans[0].id)
                      handleBuy(packageMonthlyValue?.[0]?.id)
                    }
                  >
                    Buy now
                  </NavLink>
                </div>
                {props?.packageFeatures?.map((val, i) => (
                  <div
                    key={i}
                    className="package_card_box"
                    style={{
                      backgroundColor: val.background_color,
                    }}
                  >
                    <h3>{val.title}</h3>
                    {val.package_features.map((val, i) =>
                      (props.type == "low" &&
                        val.package_type.includes(props?.PackageOneData.id)) ||
                      (props.type == "mid" &&
                        val.package_type.includes(props?.PackageOneData.id)) ||
                      (props.type == "high" &&
                        val.package_type.includes(props?.PackageOneData.id)) ? (
                        <div key={i}>
                          <ul>
                            <li>
                              <MdDone />
                            </li>
                            <li>
                              {val.title}
                              <BootstrapTooltip
                                title={val.desc}
                                placement={val.boxPointTooltipPlacement}
                              >
                                <span
                                  style={{
                                    display: val.desc ? "inline" : "none",
                                  }}
                                >
                                  <AiOutlineQuestionCircle />
                                </span>
                              </BootstrapTooltip>
                            </li>
                          </ul>
                        </div>
                      ) : null
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="Desktop">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 package_card_col">
              <div className="package_card package_card_bottom">
                <div className="package_card_recommended_div2">
                  <div
                    className="package_card_recommended_div"
                    style={{ display: props?.PackageOneData?.most_popular }}
                  >
                    <p>Recommended For You</p>
                  </div>
                </div>
                <div className="package_card_header">
                  <h3 style={{ color: props?.PackageOneData?.color }}>
                    {props?.PackageOneData?.title}
                  </h3>
                  {/* <h4>
                    ₹
                    {props?.PackageOneData?.package_plans[1].price +
                      Number(props?.totalPackPrice[0]?.totalPrice)}
                    
                  </h4> */}
                  <h4>
                    {packageYearlyValue?.[0]?.itf_country?.currency}
                    {packageYearlyValue?.[0]?.price +
                      Number(props?.totalPackPrice[0]?.totalPrice)}
                  </h4>
                  <div>
                    <p>Overview:</p>
                    {HTMLReactParser(props?.PackageOneData?.overview || "")}
                  </div>
                  <NavLink
                    onClick={() =>
                      // handleBuy(props?.PackageOneData?.package_plans[1].id)
                      handleBuy(packageYearlyValue?.[0]?.id)
                    }
                  >
                    Buy now
                  </NavLink>
                </div>
                {props?.packageFeatures?.map((val, i) => (
                  <div
                    key={i}
                    className="package_card_box"
                    style={{
                      backgroundColor: val.background_color,
                    }}
                  >
                    <h3>{val.title}</h3>
                    {val.package_features.map((val, i) =>
                      (props.type == "low" &&
                        val.package_type.includes(props?.PackageOneData.id)) ||
                      (props.type == "mid" &&
                        val.package_type.includes(props?.PackageOneData.id)) ||
                      (props.type == "high" &&
                        val.package_type.includes(props?.PackageOneData.id)) ? (
                        <div key={i}>
                          <ul>
                            <li>
                              <MdDone />
                            </li>
                            <li>
                              {val.title}
                              <BootstrapTooltip
                                title={val.desc}
                                placement={val.boxPointTooltipPlacement}
                              >
                                <span
                                  style={{
                                    display: val.desc ? "inline" : "none",
                                  }}
                                >
                                  <AiOutlineQuestionCircle />
                                </span>
                              </BootstrapTooltip>
                            </li>
                          </ul>
                        </div>
                      ) : null
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};
