import React, { useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Swal from "sweetalert2";
import ENDPOINT from "../config/ENDPOINT";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Getintouch = () => {
  const inputElement = useRef(null);
  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    other: "",
  });
  const [file, setFile] = useState(null);

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleCreate = (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append("img", file);
      Object.keys(values).forEach((key) => data.append(key, values[key]));

      axios
        .post(ENDPOINT + "contact", data)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Form submitted successfully!", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setValues({
              name: "",
              email: "",
              phone: "",
              other: "",
            });
            setFile(null);
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            toast.error("Error while submitting form!", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        });
    } catch (err) {
      toast.error("Server error!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("create contact error", err);
    }
  };

  const UploadDocument = () => {
    return (
      <>
        <input
          ref={inputElement}
          onChange={(e) => setFile(e.target.files[0])}
          type="file"
          name="img"
          style={{ display: "none" }}
        />
        <div className="getintouch_upload_div">
          <div
            getintouch_title_top_div
            onClick={() => inputElement.current?.click()}
            className="getintouch_upload_btn"
          >
            Attach Your Document
          </div>
          <p>File size not more than 2 MB</p>
        </div>
      </>
    );
  };
  return (
    <>
      <section className=" container mt-5 mb-5 getintouch_title_main_top">
        <div className="getintouch_title_top_div">
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <form
            onSubmit={handleCreate}
            method="post"
            encType="multipart/form-data"
          >
            <p>Let’s Talk</p>
            <h3>Project</h3>
            <h1 className="getintouch_title_big_title">
              Lets craft <br /> brilliance together
            </h1>
            <h2>Got something in mind? We'd love to help.</h2>
            <h4>
              What are you looking for?
              <br />
              <span>
                Want to be partners in design? Looking for UX/UI research or
                product branding? Post your query now, and we'll get in touch
                with you soon!
              </span>
            </h4>
            <div className="getintouch_TextField_div">
              <TextField
                id="outlined-multiline-flexible"
                label="How can we help?"
                multiline
                minRows={6}
                maxRows={20}
                inputProps={{
                  minLength: 3,
                  maxLength: 20,
                  pattern: "[a-z A-Z-]+",
                }}
                name="other"
                value={values.other}
                onChange={handleInputChange}
                required
                className="getintouch_TextField_main"
              />
              <small>
                If you have a requirement brief or document, share it with us
                here.
              </small>
              <h4>
                Your Details?
                <br />
                <span>Project related discussions only, please!</span>
              </h4>
              <div>
                <UploadDocument />
              </div>
              <div className="row g-4 mt-1">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                  <TextField
                    id="outlined-basic"
                    label="Your Name"
                    variant="outlined"
                    type="text"
                    size="small"
                    name="name"
                    inputProps={{
                      minLength: 3,
                      maxLength: 20,
                      pattern: "[a-z A-Z-]+",
                    }}
                    value={values.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-12">
                  <div className="row g-4">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <TextField
                        id="outlined-basic"
                        label="Your Email"
                        variant="outlined"
                        type="email"
                        size="small"
                        name="email"
                        value={values.email}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <TextField
                        id="outlined-basic"
                        label="Your Phone"
                        variant="outlined"
                        // type="number"
                        size="small"
                        name="phone"
                        inputProps={{
                          inputMode: "numeric",
                          maxLength: 10,
                          pattern: "[0-9]{10}",
                        }}
                        value={values.phone}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="getintouch_bottom_btn_div">
                    <button type="submit" className="btn outline_btn">
                      Send Enquiry
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};
