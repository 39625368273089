import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  formatCVV,
  formatCreditCardNumber,
  formatExpirationDate,
} from "./utils";
import { ToastContainer, toast } from "react-toastify";
import ENDPOINT from "../config/ENDPOINT";
import axios from "axios";
import Master from "../../../img/master.png";
import Visa from "../../../img/visa.png";
import Amex from "../../../img/amex.png";
import Jcb from "../../../img/jcb.png";
import Diners from "../../../img/diners.png";
import UnionPay from "../../../img/union-pay.png";
import Discover from "../../../img/discover.png";
import Default from "../../../img/default-card.png";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { BlueBtn, OutlineBtn } from "../components/Btn";
import { NavLink, useNavigate } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useSelector } from "react-redux";

export const AgreementForm = () => {
  const [expanded, setExpanded] = React.useState("panel1");
  const [values, setValues] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    address: "",
    amount: "",
  });
  const [state, setState] = React.useState({
    number: "",
    card_holder_name: "",
    expiry: "",
    cvv: "",
    type: "",
  });
  const [issuer, setIssuer] = React.useState("");
  const { number, card_holder_name, expiry, cvv, type } = state;
  const num = number.replaceAll(" ", "");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [errorExpiryMessage, setErrorExpiryMessage] = React.useState("");
  const navigate = useNavigate();
  const geoInfo = useSelector((state) => state.state.geoInfo);

  // const handleInputChange = (e) => {
  //   const name = e.target.name;
  //   const value = e.target.value;
  //   setValues({
  //     ...values,
  //     [name]: value,
  //   });
  // };

  const handleInputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name == "amount" && value > 1000000) return;
    // if (name == "phoneNumber" && value > 9999999999) return;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const CardForm = () => {
    // const [paymentToken, setPaymentToken] = React.useState(null);

    const handleInputChange = ({ target }) => {
      if (target.name === "number") {
        const { value, issuer, validateCard } = formatCreditCardNumber(
          target.value
        );
        target.value = value === undefined ? "" : value;
        validateCard
          ? setErrorMessage("")
          : setErrorMessage("Invalid card number");
        setIssuer(issuer);
      } else if (target.name === "expiry") {
        const { value, validateExpiry } = formatExpirationDate(target.value);
        target.value = value === undefined ? "" : value;
        validateExpiry
          ? setErrorExpiryMessage("")
          : setErrorExpiryMessage("Invalid expiry date");
      } else if (target.name === "cvv") {
        target.value = formatCVV(target.value);
      }

      setState((old) => {
        return { ...old, [target.name]: target.value };
      });
    };

    // const getToken = async () => {
    //   const { data } = await axios.get(
    //     ENDPOINT + "agreement_payment/get_token"
    //   );
    //   if (data) setPaymentToken(data);
    // };

    // useEffect(() => {
    //   getToken();
    // },[]);

    const handleSubmit = (e) => {
      e.preventDefault();
      const cardValues = {
        number: num,
        card_holder_name,
        expiry,
        cvv,
        type,
        ...values,
      };
      if (errorMessage !== "" || errorExpiryMessage !== "") {
        return;
      }

      if (
        values.name === "" ||
        values.email === "" ||
        values.phoneNumber === "" ||
        values.address === "" ||
        values.amount === ""
      ) {
        return toast.info("Please fill all the required fields!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      const payload = {
        ...cardValues,
        number: String(cardValues.number),
      };

      try {
        axios({
          url: ENDPOINT + "agreement_payment/eway-order",
          method: "POST",
          data: payload,
        })
          .then((res) => {
            if (res.status === 200) {
              // toast.success("Payment successfull!", {
              //   position: "bottom-right",
              //   autoClose: 5000,
              //   hideProgressBar: false,
              //   closeOnClick: true,
              //   pauseOnHover: true,
              //   draggable: true,
              //   progress: undefined,
              //   theme: "colored",
              // });
              setValues({
                name: "",
                email: "",
                phoneNumber: "",
                address: "",
                amount: "",
              });
              navigate("/thankyou", {
                state: { email: values.email },
              });
            }
          })
          .catch((err) => {
            if (err.response.status === 403) {
              toast.error("Error while payment!", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            }
          });
      } catch (err) {
        toast.error("Server error!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    };

    return (
      <div key="Payment">
        <div className="App-payment">
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <form id="my-form" onSubmit={handleSubmit} method="post">
            <div className="row g-3">
              <div className="col-12">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  name="type"
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Card Type</option>
                  <option value="Credit Card">Credit Card</option>
                  <option value="Debit Card">Debit Card</option>
                </select>
              </div>
              <div className="col-12">
                <div className="row g-3">
                  <div className="col-6">
                    <div className="form-group card_input_div">
                      <small>Card Number</small>
                      <div className="card_input_div_input">
                        <img
                          src={
                            issuer === "mastercard"
                              ? Master
                              : issuer === "visa"
                              ? Visa
                              : issuer === "amex"
                              ? Amex
                              : issuer === "jcb"
                              ? Jcb
                              : issuer === "dinersclub"
                              ? Diners
                              : issuer === "discover"
                              ? Discover
                              : issuer === "unionpay"
                              ? UnionPay
                              : Default
                          }
                          alt="img"
                        />
                        <input
                          type="tel"
                          id="card-number"
                          name="number"
                          className="form-control"
                          placeholder="Card Number"
                          pattern="[\d| ]{16,22}"
                          maxLength="19"
                          required
                          value={state.number}
                          onChange={handleInputChange}
                        />
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {errorMessage}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group card_input_div">
                      <small>Name on card</small>
                      <input
                        type="text"
                        name="card_holder_name"
                        className="form-control"
                        placeholder="Name"
                        pattern="[a-z A-Z-]+"
                        required
                        value={state.card_holder_name}
                        autoComplete="off"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row g-3">
                  <div className="col-6">
                    <div className="form-group card_input_div">
                      <small>Expiration Date</small>
                      <input
                        type="tel"
                        name="expiry"
                        className="form-control"
                        placeholder="Valid Thru"
                        pattern="\d\d/\d\d"
                        required
                        value={state.expiry}
                        onChange={handleInputChange}
                      />
                      <span
                        style={{
                          fontWeight: "bold",
                          color: "red",
                        }}
                      >
                        {errorExpiryMessage}
                      </span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group card_input_div">
                      <small>CVV</small>
                      <input
                        type="password"
                        name="cvv"
                        className="form-control"
                        placeholder="CVV"
                        pattern="\d{3}"
                        required
                        value={state.cvv}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-12">
                <div className="row">
                  <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 checkout_btn">
                    <div>
                      <input type="hidden" name="issuer" value={issuer} />
                      <div className="form-actions">
                        <button
                          type="submit"
                          className=" brn full_btn checkout_btn"
                        >
                          Make Payment
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </form>
        </div>
      </div>
    );
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    if (
      values.name === "" ||
      values.email === "" ||
      values.phoneNumber === "" ||
      values.address === "" ||
      values.amount === ""
    )
      return toast.info("Please fill all the required fields!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    axios({
      url: ENDPOINT + `agreement_payment/razorpay-order`,
      method: "POST",
      data: values,
    }).then((res) => {
      let data = res.data;
      const options = {
        key: data.key,
        name: "Maxlence Digital Pvt Ltd",
        description: "Client payment Transaction",
        // image: { logo },
        order_id: data.order_id,
        handler: async function (response) {
          // toast.success("Payment successfull!", {
          //   position: "bottom-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "colored",
          // });
          setValues({
            name: "",
            email: "",
            phoneNumber: "",
            address: "",
            amount: "",
          });
          navigate("/thankyou", {
            state: { email: values.email },
          });
        },
        theme: {
          color: "#61dafb",
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    });
  }

  const handleCreateOrder = async () => {
    try {
      const { data } = await axios({
        url:
          ENDPOINT +
          `agreement_payment/paypal-order?currency=${geoInfo?.currency}`,
        method: "POST",
        data: values,
      });
      if (data) {
        return data.order_id;
      }
    } catch (err) {
      toast.error("Server error!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log(err);
    }
  };

  const MyAccordion = () => {
    // const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    const SupportedCurrencies = [
      "AUD",
      "BRL",
      "CAD",
      "CNY",
      "CZK",
      "DKK",
      "EUR",
      "HKD",
      "HUF",
      "ILS",
      "JPY",
      "MYR",
      "MXN",
      "TWD",
      "NZD",
      "NOK",
      "PHP",
      "PLN",
      "GBP",
      "SGD",
      "SEK",
      "CHF",
      "THB",
      "USD",
    ];

    return (
      <>
        <div className="checkout_div">
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            className={
              expanded === "panel1" ? "checkout_inner_bg" : "checkout_inner_bg1"
            }
          >
            <AccordionSummary
              expandIcon={
                expanded === "panel1" ? (
                  <RadioButtonCheckedIcon className="checkout_inner_icon" />
                ) : (
                  <RadioButtonUncheckedIcon />
                )
              }
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ pt: 1, pb: 1 }}
            >
              <Typography
                component={"span"}
                sx={{ width: "100%", flexShrink: 3, pl: 3 }}
              >
                Credit / Debit Card
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component={"span"}>{CardForm()}</Typography>
            </AccordionDetails>
          </Accordion>
          {geoInfo?.country_code === "IN" ? (
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              className={
                expanded === "panel2"
                  ? "checkout_inner_bg"
                  : "checkout_inner_bg1"
              }
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel2" ? (
                    <RadioButtonCheckedIcon className="checkout_inner_icon" />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )
                }
                aria-controls="panel2bh-content"
                id="panel2bh-header"
                sx={{ pt: 1, pb: 1 }}
              >
                <Typography
                  component={"span"}
                  sx={{ width: "100%", flexShrink: 3, pl: 3 }}
                >
                  Pay with Razorpay
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  component={"span"}
                  sx={{
                    width: "40%",
                    textAlign: "center",
                    flexShrink: 3,
                    pl: 3,
                  }}
                >
                  <BlueBtn
                    onClick={displayRazorpay}
                    title="Buy with Razorpay"
                  />
                </Typography>
              </AccordionDetails>
            </Accordion>
          ) : null}

          {SupportedCurrencies.includes(geoInfo?.currency) ? (
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              className={
                expanded === "panel3"
                  ? "checkout_inner_bg"
                  : "checkout_inner_bg1"
              }
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel3" ? (
                    <RadioButtonCheckedIcon className="checkout_inner_icon" />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )
                }
                aria-controls="panel3bh-content"
                id="panel3bh-header"
                sx={{ pt: 1, pb: 1 }}
              >
                <Typography
                  component={"span"}
                  sx={{ width: "100%", flexShrink: 3, pl: 3 }}
                >
                  Pay with Paypal
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  component={"span"}
                  sx={{
                    width: "40%",
                    textAlign: "center",
                    flexShrink: 3,
                    pl: 3,
                  }}
                >
                  <PayPalScriptProvider
                    options={{
                      "client-id":
                        "AVubD8-ZKW--xJNd1_DEqjNEUN9DOFVwIByo_PIb3At4NH-MX8J3d4zqIqsMYzbYzWhPjuIiSJK6rbCH",
                      currency: geoInfo?.currency,
                    }}
                  >
                    <PayPalButtons
                      style={{ layout: "vertical" }}
                      disabled={
                        values.name === "" ||
                        values.email === "" ||
                        values.phoneNumber === "" ||
                        values.address === "" ||
                        values.amount === ""
                      }
                      forceReRender={[values]}
                      createOrder={() => {
                        return handleCreateOrder();
                      }}
                      onApprove={(data, actions) => {
                        return actions.order.capture().then((details) => {
                          navigate("/thankyou", {
                            state: {
                              email: values.email,
                              currency: geoInfo?.currency,
                            },
                          });
                        });
                      }}
                    />
                  </PayPalScriptProvider>
                </Typography>
              </AccordionDetails>
            </Accordion>
          ) : null}

          {geoInfo?.country_code === "IN" ? null : (
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
              className={
                expanded === "panel4"
                  ? "checkout_inner_bg"
                  : "checkout_inner_bg1"
              }
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel4" ? (
                    <RadioButtonCheckedIcon className="checkout_inner_icon" />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )
                }
                aria-controls="panel4bh-content"
                id="panel4bh-header"
                sx={{ pt: 1, pb: 1 }}
              >
                <Typography
                  component={"span"}
                  sx={{ width: "100%", flexShrink: 3, pl: 3 }}
                >
                  Pay through Bank transfer
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  component={"span"}
                  sx={{
                    width: "40%",
                    textAlign: "center",
                    flexShrink: 3,
                    pl: 3,
                  }}
                >
                  <ul>
                    <li>Maxlence Consulting Pty Ltd </li>
                    <li>BSB - 063010 </li>
                    <li>Account No - 13459602 </li>
                    <li>Bank Name - Commonwealth Bank </li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>
          )}
        </div>
      </>
    );
  };

  const handleBankTransferSubmit = (e) => {
    e.preventDefault();
    try {
      if (
        values.name === "" ||
        values.email === "" ||
        values.phoneNumber === "" ||
        values.address === "" ||
        values.amount === ""
      )
        return toast.info("Please fill all the required fields!", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

      axios({
        url: ENDPOINT + "agreement_payment/bank-transfer",
        method: "POST",
        data: values,
      })
        .then((res) => {
          if (res.status === 200) {
            navigate("/thankyou", {
              state: { email: values.email },
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            toast.error("Error while submitting!", {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        });
    } catch (err) {
      toast.error("Server error!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log(err);
    }
  };

  return (
    <div className="container mt-5 mb-5">
      <h4>
        Your Details? <br />
        <h6>Project related discussions only, please!</h6>
      </h4>
      <Box
        component="form"
        id="bank-transfer"
        // onSubmit={handleBankTransferSubmit}
        method="post"
        sx={{
          "& .MuiTextField-root": { mt: 3, ml: 5, width: "40%" },
        }}
        autoComplete="off"
      >
        <TextField
          required
          id="outlined-required"
          label="Your Name"
          size="small"
          name="name"
          inputProps={{
            inputMode: "text",
            minLength: 3,
            maxLength: 20,
            pattern: "[a-z A-Z-]+",
          }}
          defaultValue={values.name}
          onBlur={handleInputChange}
        />
        <TextField
          type="number"
          required
          id="outlined-required"
          label="Your Phone"
          size="small"
          name="phoneNumber"
          // inputProps={{
          //   inputMode: "numeric",
          //   maxLength: 10,
          //   pattern: "[0-9]{10}",
          // }}
          defaultValue={values.phoneNumber}
          onBlur={handleInputChange}
        />
        <TextField
          required
          id="outlined-required"
          label="Your Email"
          size="small"
          name="email"
          defaultValue={values.email}
          onBlur={handleInputChange}
        />
        <TextField
          required
          id="outlined-required"
          label="Your Address"
          size="small"
          name="address"
          defaultValue={values.address}
          onBlur={handleInputChange}
        />
        <TextField
          type="number"
          required
          id="outlined-required"
          label="Enter amount"
          size="small"
          name="amount"
          // inputProps={{
          //   inputMode: "numeric",
          //   // maxLength: 10,
          //   // pattern: "[0-9]{10}",
          // }}
          defaultValue={values.amount}
          onBlur={handleInputChange}
        />
      </Box>

      <div className="col-12">
        <div className="payment_main_title mt-5">
          <h3>Payment Modes</h3>
        </div>
        <div>{MyAccordion()}</div>
      </div>

      <div className="col-12 mt-5">
        <div className="row">
          <div className="col-12 agreement_payment_btn">
            {expanded === "panel2" ||
            expanded === "panel3" ||
            expanded === "panel4" ? null : (
              <button
                form="my-form"
                type="submit"
                className="brn full_btn agreement_payment_btn"
              >
                Make Payment
              </button>
            )}

            {expanded === "panel4" ? (
              <button
                form="bank-transfer"
                // type="submit"
                onClick={(e) => handleBankTransferSubmit(e)}
                className="brn full_btn agreement_payment_btn"
              >
                Submit
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
