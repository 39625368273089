import { useEffect, useState } from "react";
import ENDPOINT from "../config/ENDPOINT";
import { Helmet } from "react-helmet";

export default ({ children, page = "" }) => {
  const [seoData, setSeoData] = useState({});
  useEffect(() => {
    if (page.length) fetchSeoData();
    return () => {};
  }, [children]);

  const fetchSeoData = () => {
    fetch(ENDPOINT + "seo_data/" + page)
      .then((res) => res.json())
      .then((data) => {
        setSeoData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Helmet>
        <title>{seoData?.title}</title>
        <meta name="description" content={seoData?.desc} />
        <meta name="keywords" content={seoData?.keywords} />
      </Helmet>
      {children}
    </>
  );
};
