import React, { useEffect, useState } from "react";
import ENDPOINT from "../config/ENDPOINT.jsx";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRecentlyViewed } from "../redux/modalStateSlice.js";

export const ProductLists = ({ catId, itemsLimit }) => {
  const [products, setProducts] = useState([]);
  const geoInfo = useSelector((state) => state.state.geoInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function getProductsByCatId() {
    let res = await fetch(
      ENDPOINT +
        `product?limit=${itemsLimit}&cat_id=${catId}&country=${geoInfo?.country_code}`
    );
    const data = await res.json();
    setProducts(data);
  }

  useEffect(() => {
    getProductsByCatId();
  }, [itemsLimit, geoInfo]);

  const handleProductClick = (product) => {
    dispatch(
      setRecentlyViewed({
        img: product.img,
        slug: product.slug,
        title: product.title,
        price:
          product.shop_packages?.[0]?.shop_package_plans?.[0]?.shop_plan_prices?.[0]
            ?.price,
        currency:
          product.shop_packages?.[0]?.shop_package_plans?.[0]?.shop_plan_prices?.[0]
            ?.itf_country?.currency,
      })
    );
    navigate(`/shop/${product.slug}`);
  };

  const ProductCard = (props) => {
    return (
      <div
        onClick={() => handleProductClick(props)}
        className="productCards col-6 col-md-3"
      >
        {/* <NavLink to={`/shop/${props.slug}`}> */}

        <div className="productImageContainer">
          <img
            className="productimg w-100"
            alt={props.alt}
            src={ENDPOINT + "uploads/" + props.img}
          />
        </div>
        {/* </NavLink> */}
        <div className="productDetailsContainer my-3">
          <p className="productTitle mb-1">{props.title}</p>
          <h5 className="productPrice">
            {
              props.shop_packages?.[0]?.shop_package_plans?.[0]?.shop_plan_prices?.[0]
                ?.itf_country?.currency
            }{" "}
            &nbsp;
            {
              props.shop_packages?.[0]?.shop_package_plans?.[0]?.shop_plan_prices?.[0]
                ?.price
            }
          </h5>
        </div>
      </div>
    );
  };

  return (
    <>
      {products.map((val, i) => {
        return <ProductCard key={i} {...val} />;
      })}
    </>
  );
};
