import Payment from "payment";

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }
  // console.log(Payment)
  const issuer = Payment.fns.cardType(value);
  const validateCard = Payment.fns.validateCardNumber(value);

  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return {
    value: nextValue.trim(),
    issuer,
    validateCard,
  };
}

export function formatCVV(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 3;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);
  // console.log(clearValue);
  if (clearValue.length >= 3) {
    const validateExpiry = Payment.fns.validateCardExpiry(
      clearValue.slice(0, 2),
      clearValue.slice(2, 4)
    );
    return {
      value: `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`,
      validateExpiry,
    };
  }

  return { value: clearValue };
}
