import { useLocation } from "react-router-dom";
import YourServicesicon from "../../../img/serv.svg";
import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import ENDPOINT from "../config/ENDPOINT";
export const YourServicesDetails = () => {
  const [service, setService] = useState({});
  const location = useLocation();

  const fetchSingleOrder = async () => {
    let res = await fetch(ENDPOINT + `order/${location.state.id}`);
    let data = await res.json();
    setService(data);
  };

  useEffect(() => {
    fetchSingleOrder();
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const CardData = [
    {
      ServiceDate: "19/02/2023",
      BillingName: "Luno Electrical",
      OrderNumber: "122546659261",
      title: "Creation - Website",
      icon: YourServicesicon,
      price: "$143",
      typeService: "Premium",
      OrderSummary: [
        {
          title: "Sub Total",
          price: "$122",
        },
        {
          title: "GST 12%",
          price: "$19",
        },
      ],
    },
  ];
  const CardApp = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const MyModal = () => {
      return (
        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={open}>
              <Box sx={style}>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Text in a modal
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  Duis mollis, est non commodo luctus, nisi erat porttitor
                  ligula.
                </Typography>
              </Box>
            </Fade>
          </Modal>
        </div>
      );
    };
    const dummyImage =
      "https://wewakedev.github.io/Maxlence-Official-Website/assests/pageseo.jpg";

    return (
      <>
        <div className="col-12">
          <div className="YourServices_new_top">
            <div className="YourServices_new_inner">
              <p>Service Purchased</p>
              <p className="YourServices_new_inner_inner">
                {service?.createdAt?.split("T")[0]}
              </p>
            </div>
            <div className="YourServices_new_inner YourServices_new_inner2">
              <p>Billing Address</p>
              <p className="YourServices_new_inner_inner">
                {service?.user?.companyName}
              </p>
            </div>
            <div className="YourServices_new_inner YourServices_new_inner3">
              <p>Order # {service?.id}</p>
            </div>
          </div>
          <div className="YourServices_new_img_div">
            {service?.subscriptions?.map((subs, i) => {
              return (
                <div key={i} className="YourServices_new_img_div">
                  <ul>
                    <li>
                      <img
                        src={
                          subs.package?.question_cat_master?.img
                            ? ENDPOINT +
                              "uploads/" +
                              subs.package?.question_cat_master?.img
                            : subs.overall_plan?.category_master?.img
                            ? ENDPOINT +
                              "uploads/" +
                              subs.overall_plan?.category_master?.img
                            : dummyImage
                        }
                        alt="icon"
                      />
                    </li>
                    <li>
                      <p className="YourServices_new_img_text">
                        {subs.package?.question_cat_master?.name ||
                          subs.overall_plan.category_master.name}
                      </p>
                      <p className="YourServices_new_img_text2">
                        ${subs.amount}
                      </p>
                      <p className="YourServices_new_img_text3">
                        {subs.package?.title || subs.overall_plan.title}
                      </p>
                    </li>
                  </ul>
                  <button
                    onClick={handleOpen}
                    className="btn full_btn YourServices_new_img_text_btn"
                  >
                    Track Service
                  </button>
                </div>
              );
            })}
            <div className="YourServices_new_address_div">
              <ul>
                <li className="YourServices_new_address_div_inner_li">
                  <h3>Billing Address</h3>
                  <p className="address_div">{service?.user?.workingAddress}</p>
                  <p className="coltact_div">
                    Contact No - {service?.user?.phoneNumber}
                  </p>
                  <p className="coltact_div">
                    Email - {service?.user?.companyEmail}
                  </p>
                </li>
                <li className="YourServices_new_address_inner">
                  <h3>Order Summary</h3>
                  <div className="YourServices_new_address_price_div">
                    <p>Sub Total</p>
                    <p className="price_inner">${service?.amount}</p>
                  </div>
                  <div className="YourServices_new_address_price_div">
                    <p>GST 12%</p>
                    <p className="price_inner">$0</p>
                  </div>

                  <div className="YourServices_new_address_price_div">
                    <p>Total</p>{" "}
                    <p className="price_inner">${service?.amount}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <MyModal />
      </>
    );
  };
  return (
    <>
      <section className="container mb-4 mt-4">
        <p>Service Order Details</p>
        <div className="row g-4">
          <CardApp />
        </div>
      </section>
    </>
  );
};
