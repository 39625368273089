import { CreationPage } from "./Modules/Maxlence/pages/CreationPage";
import { Growth } from "../src/Modules/Maxlence/pages/growth";
import { Home } from "../src/Modules/Maxlence/pages/home";
import { Design } from "../src/Modules/Maxlence/pages/design";
import { Shop } from "../src/Modules/Maxlence/pages/shop";
import { Getintouch } from "../src/Modules/Maxlence/pages/getintouch";
import { Careersjd } from "../src/Modules/Maxlence/pages/careersjd";
import { Jobform } from "../src/Modules/Maxlence/pages/jobform";
import { Businessone } from "./Modules/Maxlence/pages/Business1";
import { Business2 } from "./Modules/Maxlence/pages/Business2";
import { Website } from "./Modules/Maxlence/pages/website";
import { MobileApp } from "./Modules/Maxlence/pages/MobileApp";
import { Saas } from "./Modules/Maxlence/pages/Saas";
import { CreationWebhost } from "./Modules/Maxlence/pages/CreationWebhost";
import { GoogleAds } from "./Modules/Maxlence/pages/GoogleAds";
import { SocialMediaMarketing } from "./Modules/Maxlence/pages/SocialMediaMarketing";
import { Seo } from "./Modules/Maxlence/pages/Seo";
import { EmailMarketing } from "./Modules/Maxlence/pages/EmailMarketing";
import { YourAccount } from "./Modules/Maxlence/pages/YourAccount";
import { YourServices } from "./Modules/Maxlence/pages/YourServices";
import { Payment } from "./Modules/Maxlence/pages/Payment";
import { SubscriptionPayment } from "./Modules/Maxlence/pages/SubscriptionPayment";
import { Cart } from "./Modules/Maxlence/pages/cart";
import PassResetPopup from "./Modules/Maxlence/components/PassResetPopup";
import { Checkout } from "./Modules/Maxlence/pages/Checkout";
import { VideoMarketing } from "./Modules/Maxlence/pages/VideoMarketing";
import { ProductDetails } from "./Modules/Maxlence/pages/ProductDetails";
import { QuestionarePage } from "./Modules/Maxlence/pages/QuestionarePage";
import { Careers } from "./Modules/Maxlence/pages/Careers";
import { BuyPlans } from "./Modules/Maxlence/pages/BuyPlans";
import { YourServicesDetails } from "./Modules/Maxlence/pages/YourServicesDetails";
import { AboutUs } from "./Modules/Maxlence/pages/AboutUs";
import { DesignInspiration } from "./Modules/Maxlence/pages/DesignInspiration";
import { Brochure } from "./Modules/Maxlence/pages/Brochure";
import { CaseStudy } from "./Modules/Maxlence/pages/CaseStudy";
import { Blogs } from "./Modules/Maxlence/pages/Blogs";
import { SeoService } from "./Modules/Maxlence/pages/SeoService";
import { GoogleAdsService } from "./Modules/Maxlence/pages/GoogleAdsService";
import { SocialMediaService } from "./Modules/Maxlence/pages/SocialMediaService";
import { APIIntegration } from "./Modules/Maxlence/pages/APIIntegration";
import { FormTracker } from "./Modules/Maxlence/pages/FormTracker";
import { ReviewTracker } from "./Modules/Maxlence/pages/ReviewTracker";
import { Agencies } from "./Modules/Maxlence/pages/Agencies";
import { Support } from "./Modules/Maxlence/pages/Support";
import { Tpruiux } from "./Modules/Maxlence/pages/Tpruiux";
import { Bepeseo } from "./Modules/Maxlence/pages/Bepeseo";
import { TermsAndConditions } from "./Modules/Maxlence/pages/TermsAndConditions";
import { PrivacyPolicy } from "./Modules/Maxlence/pages/PrivacyPolicy";
import { RefundPolicy } from "./Modules/Maxlence/pages/RefundPolicy";
import { ShopForm } from "./Modules/Maxlence/pages/ShopForm";
import ProtectedRoute from "./ProtectedRoute";
import { Testimonial } from "./Modules/Maxlence/pages/Testimonial";
import { DesignInspirationDetail } from "./Modules/Maxlence/pages/DesignInspirationDetail";
import { Luno } from "./Modules/Maxlence/pages/DesignInspirationDetail";
import { Agreement } from "./Modules/Maxlence/pages/Agreement";
import { WebsiteRedesign } from "./Modules/Maxlence/pages/WebsiteRedesign";
import { AgreementForm } from "./Modules/Maxlence/pages/AgreementForm";
import SeoHandler from "./Modules/Maxlence/components/SeoHandler";
import { CRO } from "./Modules/Maxlence/pages/CRO";
import { GMB } from "./Modules/Maxlence/pages/GMB";
import { HostingNew } from "./Modules/Maxlence/pages/Hosting";
import { Maintenance } from "./Modules/Maxlence/pages/Maintenance";
import { ThankYou } from "./Modules/Maxlence/components/ThankYou";
import { PackageThankYou } from "./Modules/Maxlence/components/PackageThankYou";
import { RepaymentThankYou } from "./Modules/Maxlence/components/RepaymentThankYou";

export default [
  {
    path: "/",
    element: (
      <SeoHandler page="Home">
        <Home />
      </SeoHandler>
    ),
    exact: true,
  },
  {
    path: "/creation",
    element: (
      <SeoHandler page="Creation">
        <CreationPage />
      </SeoHandler>
    ),
  },
  {
    path: "/creation/website",
    element: (
      <SeoHandler page="Website">
        {" "}
        <Website />
      </SeoHandler>
    ),
  },
  {
    path: "/creation/website-redesign",
    element: (
      <SeoHandler page="Website Redesign">
        {" "}
        <WebsiteRedesign />
      </SeoHandler>
    ),
  },
  {
    path: "/enterprise/mobile-app",
    element: (
      <SeoHandler page="Mobile App">
        {" "}
        <MobileApp />
      </SeoHandler>
    ),
  },
  {
    path: "/enterprise/saas",
    element: (
      <SeoHandler page="Saas">
        {" "}
        <Saas />
      </SeoHandler>
    ),
  },
  // {
  //   path: "/hosting_maintenance_support",
  //   element: (
  //     <SeoHandler page="Hosting & Maintenance">
  //       {" "}
  //       <CreationWebhost />
  //     </SeoHandler>
  //   ),
  // },
  {
    path: "/growth/google-ads",
    element: (
      <SeoHandler page="Google Ads">
        {" "}
        <GoogleAds />
      </SeoHandler>
    ),
  },
  {
    path: "/growth/social-media-marketing",
    element: (
      <SeoHandler page="Social Media Marketing">
        {" "}
        <SocialMediaMarketing />
      </SeoHandler>
    ),
  },
  {
    path: "/growth/seo",
    element: (
      <SeoHandler page="Seo">
        {" "}
        <Seo />
      </SeoHandler>
    ),
  },
  {
    path: "/growth/email-marketing",
    element: (
      <SeoHandler page="Email Marketing">
        {" "}
        <EmailMarketing />
      </SeoHandler>
    ),
  },
  {
    path: "/growth/video-marketing",
    element: (
      <SeoHandler page="Video Marketing">
        {" "}
        <VideoMarketing />
      </SeoHandler>
    ),
  },
  {
    path: "/:page/:service/questionnaire",
    element: (
      <SeoHandler page="Questionnaire">
        {" "}
        <QuestionarePage />
      </SeoHandler>
    ),
  },
  {
    path: "/growth",
    element: (
      <SeoHandler page="Growth">
        {" "}
        <Growth />
      </SeoHandler>
    ),
  },
  {
    path: "/design",
    element: (
      <SeoHandler page="Design">
        {" "}
        <Design />
      </SeoHandler>
    ),
  },
  {
    path: "/shop",
    element: (
      <SeoHandler page="Shop">
        {" "}
        <Shop />
      </SeoHandler>
    ),
  },
  {
    path: "/shop-form",
    element: (
      <SeoHandler page="Shop Form">
        {" "}
        <ShopForm />
      </SeoHandler>
    ),
  },
  {
    path: "/about-us",
    element: (
      <SeoHandler page="About us">
        {" "}
        <AboutUs />
      </SeoHandler>
    ),
  },
  {
    path: "/get-in-touch",
    element: (
      <SeoHandler page="Get in touch">
        {" "}
        <Getintouch />
      </SeoHandler>
    ),
  },
  {
    path: "/careers/:slug",
    element: (
      <SeoHandler page="Career JD">
        {" "}
        <Careersjd />
      </SeoHandler>
    ),
  },
  {
    path: "/job-application-form",
    element: (
      <SeoHandler page="Job Application">
        {" "}
        <Jobform />
      </SeoHandler>
    ),
  },
  {
    path: "/business/business1",
    element: (
      <SeoHandler page="Business1">
        {" "}
        <Businessone />
      </SeoHandler>
    ),
  },
  {
    path: "/business/business2",
    element: (
      <SeoHandler page="Business2">
        {" "}
        <Business2 />
      </SeoHandler>
    ),
  },
  {
    path: "/your-account",
    element: (
      <SeoHandler page="Your Account">
        {" "}
        <ProtectedRoute>
          <YourAccount />
        </ProtectedRoute>
      </SeoHandler>
    ),
  },
  {
    path: "/your-services",
    element: (
      <SeoHandler page="Your Services">
        {" "}
        <ProtectedRoute>
          <YourServices />
        </ProtectedRoute>
      </SeoHandler>
    ),
  },
  {
    path: "/your-services/your-services-details",
    element: (
      <SeoHandler page="Your Services Details">
        {" "}
        <ProtectedRoute>
          <YourServicesDetails />
        </ProtectedRoute>
      </SeoHandler>
    ),
  },
  {
    path: "/payment",
    element: (
      <SeoHandler page="Payment">
        {" "}
        <ProtectedRoute>
          <Payment />
        </ProtectedRoute>
      </SeoHandler>
    ),
  },
  {
    path: "/r/:payment_token",
    element: (
      <SeoHandler page="repayment">
        <SubscriptionPayment />
      </SeoHandler>
    ),
  },
  {
    path: "/cart",
    element: (
      <SeoHandler page="Cart">
        {" "}
        <Cart />
      </SeoHandler>
    ),
  },
  {
    path: "/checkout",
    element: (
      <SeoHandler page="Checkout">
        {" "}
        <ProtectedRoute>
          <Checkout />
        </ProtectedRoute>
      </SeoHandler>
    ),
  },
  {
    path: "/resetpassword/:token",
    element: (
      <SeoHandler page="Password Reset">
        {" "}
        <PassResetPopup />
      </SeoHandler>
    ),
  },
  {
    path: "/shop/:slug",
    element: (
      <SeoHandler page="Product Details">
        {" "}
        <ProductDetails />
      </SeoHandler>
    ),
  },
  {
    path: "/careers",
    element: (
      <SeoHandler page="Careers">
        {" "}
        <Careers />
      </SeoHandler>
    ),
  },
  // {
  //   path: "/website-luno",
  //   element: (
  //     <SeoHandler page="Luno">
  //       {" "}
  //       <Luno />
  //     </SeoHandler>
  //   ),
  // },
  {
    path: "/:page/:service/buy-plans",
    element: (
      <SeoHandler page="Buy Plans">
        {" "}
        <ProtectedRoute>
          <BuyPlans />
        </ProtectedRoute>
      </SeoHandler>
    ),
  },
  {
    path: "/resources/design-inspiration",
    element: (
      <SeoHandler page="Design Inspiration">
        {" "}
        <DesignInspiration />
      </SeoHandler>
    ),
  },
  {
    path: "/resources/design-inspiration/:slug",
    element: (
      <SeoHandler page="Design Inspiration Details">
        {" "}
        <DesignInspirationDetail />
      </SeoHandler>
    ),
  },
  {
    path: "/resources/brochure",
    element: (
      <SeoHandler page="Brochure">
        {" "}
        <Brochure />
      </SeoHandler>
    ),
  },
  {
    path: "/resources/case-study",
    element: (
      <SeoHandler page="Case Study">
        {" "}
        <CaseStudy />
      </SeoHandler>
    ),
  },
  {
    path: "/resources/blogs",
    element: (
      <SeoHandler page="Blogs">
        {" "}
        <Blogs />
      </SeoHandler>
    ),
  },
  {
    path: "/enterprise/seo-services",
    element: (
      <SeoHandler page="Seo Service">
        {" "}
        <SeoService />
      </SeoHandler>
    ),
  },
  {
    path: "/resources/case-study/bepe_seo",
    element: (
      <SeoHandler page="Bepe">
        {" "}
        <Bepeseo />
      </SeoHandler>
    ),
  },
  {
    path: "/resources/case-study/tpr_uiux",
    element: (
      <SeoHandler page="TPR">
        {" "}
        <Tpruiux />
      </SeoHandler>
    ),
  },
  {
    path: "/enterprise/google-ads-services",
    element: (
      <SeoHandler page="Google Ads Service">
        {" "}
        <GoogleAdsService />
      </SeoHandler>
    ),
  },
  {
    path: "/enterprise/social-media-services",
    element: (
      <SeoHandler page="Social Media Service">
        {" "}
        <SocialMediaService />
      </SeoHandler>
    ),
  },
  {
    path: "/solutions/api-integration",
    element: (
      <SeoHandler page="API Integration">
        {" "}
        <APIIntegration />
      </SeoHandler>
    ),
  },
  {
    path: "/solutions/form-tracker",
    element: (
      <SeoHandler page="Form Tracker">
        {" "}
        <FormTracker />
      </SeoHandler>
    ),
  },
  {
    path: "/solutions/review-tracker",
    element: (
      <SeoHandler page="Review Tracker">
        {" "}
        <ReviewTracker />
      </SeoHandler>
    ),
  },
  {
    path: "/solutions/agencies",
    element: (
      <SeoHandler page="Agencies">
        {" "}
        <Agencies />
      </SeoHandler>
    ),
  },
  {
    path: "/support",
    element: (
      <SeoHandler page="Support">
        {" "}
        <Support />
      </SeoHandler>
    ),
  },
  {
    path: "/testimonials",
    element: (
      <SeoHandler page="Testimonial">
        {" "}
        <Testimonial />
      </SeoHandler>
    ),
  },
  {
    path: "/terms-&-conditions",
    element: (
      <SeoHandler page="Terms & Conditions">
        {" "}
        <TermsAndConditions />
      </SeoHandler>
    ),
  },
  {
    path: "/privacy-policy",
    element: (
      <SeoHandler page="Privacy Policy">
        {" "}
        <PrivacyPolicy />
      </SeoHandler>
    ),
  },
  {
    path: "/refund-policy",
    element: (
      <SeoHandler page="Refund Policy">
        {" "}
        <RefundPolicy />
      </SeoHandler>
    ),
  },
  {
    path: "/agreement",
    element: (
      <SeoHandler page="Agreement">
        {" "}
        <Agreement />
      </SeoHandler>
    ),
  },
  {
    path: "/agreement-form",
    element: (
      <SeoHandler page="Agreement Form">
        {" "}
        <AgreementForm />
      </SeoHandler>
    ),
  },
  {
    path: "/creation/hosting",
    element: (
      <SeoHandler page="Hosting">
        <HostingNew />
      </SeoHandler>
    ),
  },
  {
    path: "/creation/maintenance",
    element: (
      <SeoHandler page="Maintenance">
        <Maintenance />
      </SeoHandler>
    ),
  },
  {
    path: "/growth/gmb",
    element: (
      <SeoHandler page="GMB">
        <GMB />
      </SeoHandler>
    ),
  },
  {
    path: "/growth/cro",
    element: (
      <SeoHandler page="CRO">
        <CRO />
      </SeoHandler>
    ),
  },
  {
    path: "/thankyou",
    element: (
      <SeoHandler page="Thank you page">
        <ThankYou />
      </SeoHandler>
    ),
  },
  {
    path: "/package-thankyou",
    element: (
      <SeoHandler page="Thank you page">
        <PackageThankYou />
      </SeoHandler>
    ),
  },
  {
    path: "/stripe_payment",
    element: (
      <SeoHandler page="Thank you page">
        <RepaymentThankYou />
      </SeoHandler>
    ),
  },
];
